import React, { useEffect  } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import ObjectifCard from '../cards/newCampainCardsObjective';

function cardNameClient(props) {
  return(
    <React.Fragment>
      <CardContent>
        <span className="cardText">
          Nom de la marque
        </span>
        <Box component="form" noValidate autoComplete="off" style={{marginTop: "1em"}}>
          <FormControl className="cardTextField">
            <OutlinedInput placeholder="Ex : Peugeot" value={props.commercialName} onChange={(e) => props.onCommercialOrProductNameChange(e.target.value, "commercialName")}/>
          </FormControl>
        </Box>
      </CardContent>
    </React.Fragment>
  );
}

function cardNameProduct(props) {
  return(
    <React.Fragment>
      <CardContent>
        <span className="cardText">
          Nom du produit
        </span>
        <Box component="form" noValidate autoComplete="off" style={{marginTop: "1em"}}>
          <FormControl className="cardTextField">
            <OutlinedInput placeholder="Ex : Peugeot 3008" value={props.productName} onChange={(e) => props.onCommercialOrProductNameChange(e.target.value, "productName")}/>
          </FormControl>
        </Box>
      </CardContent>
    </React.Fragment>
  );
}

function cardObjectif(props) {
  return(
    <React.Fragment>
      <CardContent>
        <span className="cardText">
          Principal objectif de communication
        </span>
        <Box component="form" noValidate autoComplete="off" style={{marginTop: "1em"}}>
          <div style={{display: "flex", flexWrap: "wrap"}}>
            <div className="grid-item-objectif" style={{width: "20%", minWidth: "10em"}}>
              <ObjectifCard {...props} id={1} onChange={props.handleObjectifCheckboxChange} value={props.objectifValue1} item="objective"
                cardTitle='Visibilité et Notoriété'
                cardText="Soyez visible auprès de votre cible et gagnez en notoriété"
                tooltipText="Démarquez-vous de vos concurrents, et augmentez la notoriété de votre marque en touchant les personnes les plus susceptibles d'être intéressées"
                tooltipExample="Exemples : Lancement, nouveaux produits, évènement"
              />
            </div>
            <div className="grid-item-objectif" style={{width: "20%", minWidth: "10em"}}>
              <ObjectifCard {...props} id={2} onChange={props.handleObjectifCheckboxChange} value={props.objectifValue2} item="objective"
                cardTitle='Trafic digital'
                cardText="Boostez votre trafic drive to web"
                tooltipText="Boostez votre trafic et envoyez le plus de personnes vers votre site web, vos pages de vente, vos réseaux sociaux ou encore vers le téléchargement de votre application"
                tooltipExample=""
              />
            </div>
            <div className="grid-item-objectif" style={{width: "20%", minWidth: "10em"}}>
              <ObjectifCard {...props} id={3} onChange={props.handleObjectifCheckboxChange} value={props.objectifValue3} item="objective"
                cardTitle='Trafic physique'
                cardText='Augmentez votre trafic drive to store'
                tooltipText="Soutenir vos points de vente et augmentez les visites vers votre établissement (boutique(s), lieu(x)) en montrant des publicités aux personnes se trouvant à proximité"
                tooltipExample="Exemples : Nouveaux services, click & collect, portes ouvertes"
              />
            </div>
            <div className="grid-item-objectif" style={{width: "20%", minWidth: "10em"}}>
              <ObjectifCard {...props} id={4} onChange={props.handleObjectifCheckboxChange} value={props.objectifValue4} item="objective"
                cardTitle='Conversion'
                cardText='Recrutez de nouveaux clients'
                tooltipText="Optimisez votre couverture utile tout en diffusant votre publicité à un maximum de monde"
                tooltipExample="Exemples : Promotions, Recrutements"
              />
            </div>
          </div>
        </Box>
      </CardContent>
    </React.Fragment>
  );
}




function Step0(props) {

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div>
      <Box sx={{ minWidth: 275 }} style={{marginBottom: "1em"}}>
        <Card id="step0CommercialName" {...props} variant="outlined">{cardNameClient(props)}</Card>
      </Box>
      <Box sx={{ minWidth: 275 }} style={{marginBottom: "1em"}}>
        <Card id="step0ProductName" {...props} variant="outlined">{cardNameProduct(props)}</Card>
      </Box>
      <Box sx={{ minWidth: 275 }} style={{marginBottom: "1em"}}>
        <Card id="step0Objective" {...props} variant="outlined">{cardObjectif(props)}</Card>
      </Box>
      <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>
        <Button variant="white" onClick={() => props.goNextStep()}>Étape suivante</Button>
      </div>
    </div>
  );
}

export default Step0;
