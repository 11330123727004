import Radio from '@mui/material/Radio';

function GenericCheckbox(props) {
  return (
    <div id="" className="loginCheckboxDiv">
      <Radio
        checked={props.checked}
        onChange={e => {props.handleChange(props.checkboxId, !props.checked)}}
        name="radio-buttons"
        inputProps={{ 'aria-label': 'A' }}
      />
      <p style={props.checked ? {color: "#00c2cb"} : {}}>{props.checkboxLabel}</p>
    </div>
  );
}

export default GenericCheckbox;
