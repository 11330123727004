import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';

function Alerts(props) {
  return (
    <div className="animate__animated animate__fadeInDown" style={{width: "auto", minWidth: "35em", position: "absolute", top: '100px', zIndex: 1, right: '20px'}}>
    {
      <Alert severity={props.severity} color={props.color}
        action={
          <Button color="inherit" size="small" onClick={props.onClose}>
            <span style={{fontWeight: "bold"}}>X</span>
          </Button>
        }
      >
      {props.text}
      </Alert>
    }
    </div>
  );
}

export default Alerts;
