import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import Button from '@mui/material/Button';
import WidgetsIcon from '@mui/icons-material/Widgets';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import UpdateForm from '../forms/UpdateProfileForm';
import * as Api from '../../services/userApi.js';
import * as LocalStorage from '../../services/localStorage.js';
import * as ApiAddr from '../../services/apiAdress.js';
import * as SessionStorage from '../../services/sessionStorage';

import logo from '../../assets/videoclub.png';
const drawerWidth = 240;
let timer = null;

export default function ProfileContent(props) {
  const [isUserPro, setIsUserPro] = useState(false);
  const [isUserAnnonceur, setIsUserAnnonceur] = useState(false);
  const [company, setCompany] = useState('');
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [city, setCity] = useState('');
  const [fullAddr, setFullAddr] = useState('');
  const [fullAddrAutocomplete, setFullAddrAutocomplete] = useState([]);
  const [displayAddr, setDisplayAddr] = useState(false);
  const [addrNumber, setAddrNumber] = useState('');
  const [addrAutocomplete, setAddrAutocomplete] = useState([]);
  const [postalCodeAutocomplete, setPostalCodeAutocomplete] = useState([]);
  const [isSelectAddrOpen, setIsSelectAddrOpen] = useState(false);
  const [isSelectPostalCodeOpen, setIsSelectPostalCodeOpen] = useState(false);
  const [selectedAddrAutocomplete, setSelectedAddrAutocomplete] = React.useState('');
  const [selectedPostalCodeAutocomplete, setSelectedPostalCodeAutocomplete] = React.useState('');
  const [alertText, setAlertText] = useState('');
  const [alertColor, setAlertColor] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('');
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  //used to set the current profile values
  useEffect(() => {
    if (props.user !== null) {
      setCompany(props.user.company);
      setEmail(props.user.email);
      setFirstName(props.user.firstName);
      setLastName(props.user.lastName);
      setPhone(props.user.phoneNumber);
      if (props.user.isAgency) {
        setIsUserAnnonceur(true);
        setIsUserPro(false);
      }
      else {
        setIsUserAnnonceur(false);
        setIsUserPro(true);
      }
      setAddrNumber(props.user.addrNumber);
      setAddress(props.user.addrStreet);
      setCity(props.user.addrCity);
      setPostalCode(props.user.addrPostalCode);
      if (props.user.addrNumber !== "undefined" && props.user.addrNumber !== "" && props.user.addrNumber !== null) {
        setDisplayAddr(true);
      }
    }
  }, [props]);

  function handleCheckboxChange(checkboxName, value) {
    if (checkboxName === "isUserAnnonceur") {
      setIsUserAnnonceur(value);
      setIsUserPro(!value);
    }
    else {
      setIsUserAnnonceur(!value);
      setIsUserPro(value);
    }
  }

  function checkIfItemIsAlreadyInList(tmp, item) {
    for (let i = 0; i < tmp.length; i++) {
      if (tmp[i].label === item.label) {
        return true;
      }
    }
    return false;
  }

  //called when user stop writing address
  async function handleAddrChange(value) {
    if (value === null) {
      setAddrAutocomplete([]);
      setPostalCodeAutocomplete([]);
      return null;
    }
    if (value.length < 4) {
      setAddrAutocomplete([]);
      setPostalCodeAutocomplete([]);
      return null;
    }
    const ret = await ApiAddr.getAddressFromPublicApi(value);
    if (ret === false || typeof ret.data === "undefined") {
      return null;
    }
    if (typeof ret.data.features !== "undefined") {
      let tmp = [];
      //console.log(ret.data.features);
      for (let i = 0; i < ret.data.features.length; i++) {
        const item = {
          label: ret.data.features[i].properties.name,
        };
        if (checkIfItemIsAlreadyInList(tmp, item) === false) {
          tmp[i] = item;
        }
      }
      return tmp;
    }
    else {
      return null;
    }
  }

  //called when user stop writing postal code
  async function handlePostalCodeChange(value) {
    if (value === null) {
      setAddrAutocomplete([]);
      setPostalCodeAutocomplete([]);
      return null;
    }
    if (value.length < 4) {
      setAddrAutocomplete([]);
      setPostalCodeAutocomplete([]);
      return null;
    }
    const ret = await ApiAddr.getAddressFromPublicApi(value);
    if (ret === false || typeof ret.data === "undefined") {
      return null;
    }
    if (typeof ret.data.features !== "undefined") {
      let tmp = [];
      //console.log(ret.data.features);
      for (let i = 0; i < ret.data.features.length; i++) {
        const item = {
          label: ret.data.features[i].properties.city,
        };
        if (checkIfItemIsAlreadyInList(tmp, item) === false) {
          tmp[i] = item;
        }
      }
      return tmp;
    }
    else {
      return null;
    }
  }

  async function handleFullAddrChange(value) {
    const ret = await ApiAddr.getAddressFromPublicApi(value);
    if (ret === false || typeof ret.data === "undefined") {
      return null;
    }
    if (typeof ret.data.features !== "undefined") {
      let tmp = [];
      for (let i = 0; i < ret.data.features.length; i++) {
        tmp[i] = ret.data.features[i].properties.label;
      }
      return tmp;
    }
    else {
      return null;
    }
  }

  function selectAddr(value) {
    const array = value.split(' ');
    const last = array.length - 1;
    let street = "";
    for (let i = 1; i < last - 1; i++) {
      street += array[i] + " ";
    }
    setAddress(street);
    setAddrNumber(array[0]);
    setPostalCode(array[last - 1]);
    setCity(array[last]);
    setDisplayAddr(true);
  }

  async function updateState(stateName, value) {
    if (stateName === "fullAddr") {
      setFullAddr(value);
      timer = setTimeout(async function() {
        const tmp = await handleFullAddrChange(value);
        if (tmp !== null) {
          //console.log(tmp)
          setFullAddrAutocomplete(tmp);
        }
      }, 500);
    }
    if (stateName === "company") {
      setCompany(value);
    }
    if (stateName === "lastname") {
      setLastName(value);
    }
    if (stateName === "firstname") {
      setFirstName(value);
    }
    if (stateName === "phone") {
      setPhone(value);
    }
    if (stateName === "city") {
      setCity(value);
    }
    if (stateName === "postalCode") {
      setPostalCode(value);
      clearTimeout(timer);
      timer = setTimeout(async function() {
        const tmp = await handlePostalCodeChange(value);
        if (tmp !== null) {
          setPostalCodeAutocomplete(tmp);
          setIsSelectPostalCodeOpen(true);
        }
      }, 500);
    }
    if (stateName === "addrNumber") {
      setAddrNumber(value);
    }
    //the timer is used to call the public address api only when user stop writing
    if (stateName === "address") {
      setAddress(value);
      clearTimeout(timer);
      timer = setTimeout(async function() {
        const tmp = await handleAddrChange(value);
        if (tmp !== null) {
          setAddrAutocomplete(tmp);
          setIsSelectAddrOpen(true);
        }
      }, 500);
    }
  }

  //called when user select a specific address into the list
  const onAddrAutocompleteSelectedChanged = (value) => {
    if (value) {
      setAddress(value);
      //the 2 next lines update the address input value with the select item into the list
      let addrTextfield = document.getElementById(":r6:");
      addrTextfield.value = value;
      setSelectedAddrAutocomplete(value);
      setIsSelectAddrOpen(false);
    }
  };

  //called when user select a specific postal code into the list
  const onPostalCodeAutocompleteSelectedChanged = (value) => {
    if (value) {
      //setPostalCode(event.target.value);
      //the 2 next lines update the postal code input value with the select item into the list
      let cityTextfield = document.getElementById(":r9:");
      cityTextfield.value = value;
      setCity(value);
      setSelectedPostalCodeAutocomplete(value);
      setIsSelectPostalCodeOpen(false);
    }
  };

  async function handleProfileChange() {
    const token = LocalStorage.getToken();
    const id = LocalStorage.getId();
    const data = {
      token: token,
      userId: id,
      firstName: firstName,
      lastName: lastName,
      company: company,
      phoneNumber: phone,
      isAgency: isUserPro,
      addrNumber: addrNumber,
      addrStreet: address,
      addrPostalCode: postalCode,
      addrCity: city
    };
    const ret = await Api.updateUserProfile(data);
    if (ret !== false) {
      SessionStorage.setSessionField('company', company);
      SessionStorage.setSessionField('firstName', firstName);
      SessionStorage.setSessionField('lastName', lastName);
      SessionStorage.setSessionField('phoneNumber', phone);
      SessionStorage.setSessionField('isAgency', isUserAnnonceur);
      SessionStorage.setSessionField('addrNumber', addrNumber);
      SessionStorage.setSessionField('addrStreet', address);
      SessionStorage.setSessionField('addrPostalCode', postalCode);
      SessionStorage.setSessionField('addrCity', city);
      setAlertColor("info");
      setAlertSeverity("success");
      setAlertText("Vos informations personnelles ont été mises à jour");
    }
    else {
      setAlertColor("error");
      setAlertSeverity("error");
      setAlertText("Une erreur est survenue, merci de réésayer plus tard");
    }
    updateAlertState();
  }

  function updateAlertState() {
    setIsAlertVisible(!isAlertVisible);
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
        <div>
          <img src={logo} alt="logo" className="appBarLogo"/>
          <Button onClick={() => window.open('https://www.videoclub-pub.fr/contact')} style={{position: "absolute", right: "350px", marginTop: "30px"}} color="inherit">Besoin d'aide ?</Button>
          <Button onClick={() => window.open('https://www.videoclub-pub.fr/')} style={{position: "absolute", right: "180px", marginTop: "30px"}} color="inherit">Démarrer en TV</Button>
          <Button onClick={() => props.disconnectUser()} style={{position: "absolute", right: "30px", marginTop: "30px"}} color="inherit">Déconnexion</Button>
        </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }} style={{marginTop: "2em"}}>
          <List>
            {['Accueil', 'Mes campagnes', 'Nouvelle campagne', 'Mon Profil'].map((text, index) => (
              <div button key={text} className="homeMenuItemWrapper" onClick={() => props.displayPage(index)}>
                <ListItemIcon style={{justifyContent: "center"}}>
                  {
                    index  === 0 ? <WidgetsIcon className="homeMenuIcon" /> :
                    index  === 1 ? <DashboardIcon className="homeMenuIcon" /> :
                    index  === 2 ? <DashboardCustomizeIcon className="homeMenuIcon" /> :
                    <CoPresentIcon className="homeMenuIconSmall"/>
                  }
                </ListItemIcon>
                <span className="menuIconText">{text}</span>
              </div>
            ))}
          </List>
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <div style={{marginTop: "5em"}}>
          <h1 className="updateProfileTitle">Mes informations personnelles</h1>
          <UpdateForm
            isUserAnnonceur={isUserAnnonceur}
            isUserPro={isUserPro}
            handleCheckboxChange={handleCheckboxChange}
            company={company}
            lastName={lastName}
            firstName={firstName}
            updateState={updateState}
            email={email}
            phone={phone}
            address={address}
            postalCode={postalCode}
            city={city}
            addrNumber={addrNumber}
            addrAutocomplete={addrAutocomplete}
            postalCodeAutocomplete={postalCodeAutocomplete}
            selectedAddrAutocomplete={selectedAddrAutocomplete}
            isSelectAddrOpen={isSelectAddrOpen}
            isSelectPostalCodeOpen={isSelectPostalCodeOpen}
            onAddrAutocompleteSelectedChanged={onAddrAutocompleteSelectedChanged}
            onPostalCodeAutocompleteSelectedChanged={onPostalCodeAutocompleteSelectedChanged}
            buttonClicked={handleProfileChange}
            alertSeverity={alertSeverity}
            alertColor={alertColor}
            alertText={alertText}
            isAlertVisible={isAlertVisible}
            updateAlertState={updateAlertState}
            selectedPostalCodeAutocomplete={selectedPostalCodeAutocomplete}
            goToUpdatePassword={props.goToUpdatePassword}
            displayAddr={displayAddr}
            fullAddrAutocomplete={fullAddrAutocomplete}
            fullAddr={fullAddr}
            selectAddr={selectAddr}
          />
        </div>
      </Box>
    </Box>
  );
}
