import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "30vw",
  minWidth: '45em',
  bgcolor: 'background.paper',
  border: '2px solid black',
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
  maxHeight: "90% !important",
};

export default function TransitionsModal(props) {
  const geoAreaOptionsLocal = props.geoAreaOptions.map((item) =>
    <Typography id="transition-modal-description" style={{textAlign: "end", display: "flex", justifyContent: 'flex-end'}}>
      {item}
    </Typography>
  );

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
            {
              <span className="bold" style={{color: "#00c2cb"}}>Commande {props.cmdId}</span>
            }
            </Typography>
            <div style={{marginTop: "1.5em", marginBottom: "1.5em"}}>

              <span style={props.commercialName !== '0' && props.commercialName !== 0 ? {display: "flex", flexDirection: "row", justifyContent: "space-between"} : {display: 'none'}}>
                <Typography id="transition-modal-description" sx={{ mt: 2 }} style={{width: '40%'}}>
                  <span className="">Nom de la marque : </span>
                </Typography>
                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                  <span className='bold'>{props.commercialName}</span>
                </Typography>
              </span>

              <span style={props.productName !== '0' && props.productName !== 0 ? {display: "flex", flexDirection: "row", justifyContent: "space-between"} : {display: 'none'}}>
                <Typography id="transition-modal-description" sx={{ mt: 2 }} style={{width: '40%'}}>
                  <span className="">Nom du produit : </span>
                </Typography>
                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                  <span className='bold'>{props.productName}</span>
                </Typography>
              </span>

              <span style={props.communitationObjective !== '0' && props.communitationObjective !== 0 ? {display: "flex", flexDirection: "row", justifyContent: "space-between"} : {display: 'none'}}>
                <Typography id="transition-modal-description" sx={{ mt: 2 }} style={{width: '40%'}}>
                  <span className="">Objectif de communication : </span>
                </Typography>
                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                  <span className='bold'>{props.communitationObjective}</span>
                </Typography>
              </span>

              <span style={props.geoArea !== '' ? {display: "flex", flexDirection: "row", justifyContent: "space-between"} : {display: 'none'}}>
                <Typography id="transition-modal-description" sx={{ mt: 2 }} style={{width: '40%'}}>
                  <span className="">Zone géographique : </span>
                </Typography>
                <div style={{display: "flex", flexDirection: "column"}}>
                  <Typography id="transition-modal-description" sx={{ mt: 2 }} style={{textAlign: "end", display: "flex", justifyContent: 'flex-end'}}>
                    <span className='bold'>{props.geoArea}</span>
                  </Typography>
                  {
                    geoAreaOptionsLocal
                  }
                </div>
              </span>

              <span style={props.targetSex !== '0' && props.targetSex !== 0 ? {display: "flex", flexDirection: "row", justifyContent: "space-between"} : {display: 'none'}}>
                <Typography id="transition-modal-description" sx={{ mt: 2 }} style={{width: '40%'}}>
                  <span className="">Ciblage Socio Démo : </span>
                </Typography>
                <div style={{display: "flex", flexDirection: "column"}}>
                  <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                    <span className='bold' style={{textAlign: "end", display: "flex", justifyContent: 'flex-end'}}>{props.targetSex}</span>
                  </Typography>
                  {
                    props.targetSexAge !== '0' && props.targetSexAge !== 0
                    ?
                    <Typography id="transition-modal-description">
                      <span className='' style={{textAlign: "end", display: "flex", justifyContent: 'flex-end'}}>{props.targetSexAge}</span>
                    </Typography>
                    :
                    null
                  }
                </div>
              </span>

              <span style={props.targetHouseholdComposition !== '0' && props.targetHouseholdComposition !== 0 ? {display: "flex", flexDirection: "row", justifyContent: "space-between"} : {display: 'none'}}>
                <Typography id="transition-modal-description" sx={{ mt: 2 }} style={{width: '40%'}}>
                  <span className="">Ciblage composition du foyer : </span>
                </Typography>
                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                  <span className='bold'>{props.targetHouseholdComposition}</span>
                </Typography>
              </span>

              <span style={props.targetCSP !== '0' && props.targetCSP !== 0 ? {display: "flex", flexDirection: "row", justifyContent: "space-between"} : {display: 'none'}}>
                <Typography id="transition-modal-description" sx={{ mt: 2 }} style={{width: '40%'}}>
                  <span className="">Ciblage CSP : </span>
                </Typography>
                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                  <span className='bold'>{props.targetCSP}</span>
                </Typography>
              </span>

              <span style={props.targetConsoTV !== '0' && props.targetConsoTV !== 0 ? {display: "flex", flexDirection: "row", justifyContent: "space-between"} : {display: 'none'}}>
                <Typography id="transition-modal-description" sx={{ mt: 2 }} style={{width: '40%'}}>
                  <span className="">Ciblage Conso TV : </span>
                </Typography>
                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                  <span className='bold'>{props.targetConsoTV}</span>
                </Typography>
              </span>

              <span style={props.targetData !== '0' && props.targetData !== 0 ? {display: "flex", flexDirection: "row", justifyContent: "space-between"} : {display: 'none'}}>
                <Typography id="transition-modal-description" sx={{ mt: 2 }} style={{width: '40%'}}>
                  <span className="">Ciblage Data : </span>
                </Typography>
                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                  <span className='bold'>{props.targetData}</span>
                </Typography>
              </span>

              <span style={props.targetGeolife !== '0' && props.targetGeolife !== 0 ? {display: "flex", flexDirection: "row", justifyContent: "space-between"} : {display: 'none'}}>
                <Typography id="transition-modal-description" sx={{ mt: 2 }} style={{width: '40%'}}>
                  <span className="">Ciblage Geolife : </span>
                </Typography>
                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                  <span className='bold'>{props.targetGeolife}</span>
                </Typography>
              </span>

              <span style={props.targetHousingType !== '0' && props.targetHousingType !== 0 ? {display: "flex", flexDirection: "row", justifyContent: "space-between"} : {display: 'none'}}>
                <Typography id="transition-modal-description" sx={{ mt: 2 }} style={{width: '40%'}}>
                  <span className="">Ciblage Type d'habitat : </span>
                </Typography>
                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                  <span className='bold'>{props.targetHousingType}</span>
                </Typography>
              </span>

              <span style={props.targetInterest !== '0' && props.targetInterest !== 0 ? {display: "flex", flexDirection: "row", justifyContent: "space-between"} : {display: 'none'}}>
                <Typography id="transition-modal-description" sx={{ mt: 2 }} style={{width: '40%'}}>
                  <span className="">Ciblage intérêts : </span>
                </Typography>
                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                  <span className='bold'>{props.targetInterest}</span>
                </Typography>
              </span>

              <span style={props.targetMomentMarketing !== '0' && props.targetMomentMarketing !== 0 ? {display: "flex", flexDirection: "row", justifyContent: "space-between"} : {display: 'none'}}>
                <Typography id="transition-modal-description" sx={{ mt: 2 }} style={{width: '40%'}}>
                  <span className="">Ciblage moment marketing : </span>
                </Typography>
                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                  <span className='bold'>{props.targetMomentMarketing}</span>
                </Typography>
              </span>

              <span style={props.campaignType !== 0 && props.campaignType !== 0 ? {display: "flex", flexDirection: "row", justifyContent: "space-between"} : {display: "none"}}>
                <Typography id="transition-modal-description" sx={{ mt: 2 }} style={{width: '40%'}}>
                  <span className="">Type de campagne : </span>
                </Typography>
                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                  <span className='bold'>{props.campaignType}</span>
                </Typography>
              </span>

              <span style={props.startDate !== '0' && props.startDate !== 0 ? {display: "flex", flexDirection: "row", justifyContent: "space-between"} : {display: 'none'}}>
                <Typography id="transition-modal-description" sx={{ mt: 2 }} style={{width: '40%'}}>
                  <span className="">Période de communication : </span>
                </Typography>
                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                  <span className='bold'>{props.startDate + ' au ' + props.stopDate}</span>
                </Typography>
              </span>

              <span style={props.budget !== '0' && props.budget !== 0 ? {display: "flex", flexDirection: "row", justifyContent: "space-between"} : {display: 'none'}}>
                <Typography id="transition-modal-description" sx={{ mt: 2 }} style={{width: '40%'}}>
                  <span className="">Budget de la campagne : </span>
                </Typography>
                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                  <span className='bold'>{props.budget}</span>
                </Typography>
              </span>

              <span style={props.spotTvAlreadyAvailable !== -1 ? {display: "flex", flexDirection: "row", justifyContent: "space-between"} : {display: 'none'}}>
                <Typography id="transition-modal-description" sx={{ mt: 2 }} style={{width: '40%'}}>
                  <span className="">Mon spot TV : </span>
                </Typography>
                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                  <span className='bold'>{props.spotTvAlreadyAvailable}</span>
                </Typography>
              </span>

              <span style={props.spotDuration !== 0 ? {display: "flex", flexDirection: "row", justifyContent: "space-between"} : {display: 'none'}}>
                <Typography id="transition-modal-description" sx={{ mt: 2 }} style={{width: '40%'}}>
                  <span className="">{props.spotTvAlreadyAvailable === "Je n'ai pas de spot TV" ? 'Durée envisagée pour le spot :' : 'Durée du spot :'}</span>
                </Typography>
                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                  <span className='bold'>{props.spotDuration + ' secondes'}</span>
                </Typography>
              </span>

              <span style={props.spotId !== '0' ? {display: "flex", flexDirection: "row", justifyContent: "space-between"} : {display: 'none'}}>
                <Typography id="transition-modal-description" sx={{ mt: 2 }} style={{width: '40%'}}>
                  <span className="">PUB ID</span>
                </Typography>
                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                  <span className='bold'>{props.spotId}</span>
                </Typography>
              </span>

              <span style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <Typography id="transition-modal-description" sx={{ mt: 2 }} style={{width: '40%'}}>
                  <span className="">Statut : </span>
                </Typography>
                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                  <span className='bold'>{props.status}</span>
                </Typography>
              </span>

              <div style={props.status === 'Brouillon' ? {position: "relative", marginTop: "2em", marginBottom: "-1em"} : {display: "none"}}>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                  <Button onClick={() => props.handleFinishCmd(props)} style={{marginTop: "1.5em", border: "1px solid lightgray", color: '#00c2cb'}}>Reprendre le plan média</Button>
                  <Button className="pinkButton" onClick={() => props.openModalValidate()} style={{marginTop: "1.5em", border: "1px solid lightgray", color: '#ff66c4'}}>Supprimer le plan média</Button>
                </div>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
