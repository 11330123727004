import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function TargetAge(props) {
  return (
    <Box sx={{ minWidth: 120 }} style={{marginTop: "1em"}}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Tranche d’âge</InputLabel>
        <Select
          style={{width: "94%"}}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={props.selectedAge}
          label="Tranche d’âge"
          onChange={(event) => props.handleSelectTargetAgeChange(event.target.value)}
        >
          <MenuItem value={0}>Non défini</MenuItem>
          <MenuItem value={1}>15 ans et plus</MenuItem>
          <MenuItem value={2}>15 à 24 ans</MenuItem>
          <MenuItem value={3}>15 à 34 ans</MenuItem>
          <MenuItem value={4}>15 à 49 ans</MenuItem>
          <MenuItem value={5}>15 à 59 ans</MenuItem>
          <MenuItem value={6}>25 à 34 ans</MenuItem>
          <MenuItem value={7}>25 à 49 ans</MenuItem>
          <MenuItem value={8}>25 à 59 ans</MenuItem>
          <MenuItem value={9}>35 à 49 ans</MenuItem>
          <MenuItem value={10}>35 à 59 ans</MenuItem>
          <MenuItem value={11}>50 ans et plus</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
