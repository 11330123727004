import React from 'react';
import "../css/app.css";

export default class TypeWriter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayedText: "",
      index: 0,
    };
  }

  componentDidMount() {
    this.displayGreetingMsg(this.props.content ? this.props.content : "");
    document.documentElement.style.setProperty('--cursor', ' ');
  }

  //remove cursor after welcome text is fully displayed
  removeBlinkCursor = () => {
    document.documentElement.style.setProperty('--cursor', ' ');
  }

  //remove cursor after welcome text is fully displayed
  addBlinkCursor = () => {
    document.documentElement.style.setProperty('--cursor', '|');
  }

  displayGreetingMsg = (content) => {
    this.setState({index: 0});
    const intervalId = setInterval(async () => {
      //read all welcome text
      if (this.state.index < content.length) {
        //get next letter in welcome te
        const current = content[this.state.index];
        //update displayed text in this component
        this.setState({displayedText: this.state.displayedText + current}, () => {
          //update displayed text in parent component to refresh state and so page
          this.props.updateWelcomeText(this.state.displayedText);
        });
        //update index
        this.setState(prevState => {
          return {
            index: this.state.index + 1,
          };
        });
      }
      else {
        this.removeInterval(intervalId);
        this.props.returnFunction();
      }
    }, 150);
  }

  removeGreetingMsg = () => {
    this.setState({index: this.state.displayedText.length});
    const intervalId = setInterval(async () => {
      //read all welcome text
      if (this.state.index >= this.props.content.length) {
        if (this.state.displayedText.length === 1) {
          //alert('fo')
          this.clearInterval(intervalId);
        }
        //get next letter in welcome te
        //update displayed text in this component
        this.setState({displayedText: this.state.displayedText.slice(0, -1) }, () => {
          //update displayed text in parent component to refresh state and so page
          this.props.updateWelcomeText(this.state.displayedText);
        });
      }
      else {
        this.removeInterval(intervalId);
      }
    }, 150);
  }

  removeInterval(intervalId) {
    //remove interval
    clearInterval(intervalId);
    //set timeout before removing cursor
    setTimeout(this.removeBlinkCursor, 4000);
    return;
  }


  render() {
    return(
      <div className="typeWriter">
        <span id="typeWriterText" className="colorBlue">
          {this.state.displayedText}
        </span>
      </div>
    );
  }
}
