import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';

function Alerts(props) {
  return (
    <div style={{width: "100%", marginLeft: "5px"}}>
    {
      props.isAlertVisible ?
      <Alert severity={props.alertSeverity} color={props.alertColor}
        action={
          <Button color="inherit" size="small" onClick={props.onClose}>
            <span style={{fontWeight: "bold"}}>X</span>
          </Button>
        }
      >
      {props.alertText}
      </Alert>
      :
      null
    }
    </div>
  );
}

export default Alerts;
