import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "25vw",
  bgcolor: 'background.paper',
  border: '2px solid black',
  boxShadow: 24,
  p: 4,
};

export default function TransitionsModal(props) {
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
            {
              <span className="bold">Confirmation</span>
            }
            </Typography>
            <div style={{marginTop: "1.5em", marginBottom: "1.5em"}}>
              <span style={{display: "flex", flexDirection: "column"}}>
                <Typography sx={{ mt: 2 }} style={{marginBottom: "1em"}}>
                  <span>Êtes-vous sûr de vouloir supprimer ce plan média ?</span>
                </Typography>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                  <Button onClick={() => props.closeAllModals()} className="pinkButton" style={{marginTop: "1.5em", border: "1px solid lightgray", color: '#ff66c4', width: '49%'}}>Annuler</Button>
                  <Button onClick={() => props.deleteUserCmd(props.cmdId)} style={{marginTop: "1.5em", border: "1px solid lightgray", color: '#00c2cb', width: '49%'}}>Valider</Button>
                </div>
              </span>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
