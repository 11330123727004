import React from 'react';
import {withRouter} from '../services/withRouter.js';
import Content from '../components/pageContent/profileContent';
import * as Api from '../services/userApi.js';
import * as LocalStorage from '../services/localStorage';
import * as SessionStorage from '../services/sessionStorage';
import "../css/app.css";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPageLoading: true,
      user: null,
    };
  }

  async componentDidMount() {
    const ret = await this.userInfoAvailable();
    if (ret === true) {
      const tmp = {
        firstName: SessionStorage.getSessionField('firstName'),
        lastName: SessionStorage.getSessionField('lastName'),
        company: SessionStorage.getSessionField('company'),
        phoneNumber: SessionStorage.getSessionField('phoneNumber'),
        isAgency: SessionStorage.getSessionField('isAgency'),
        addrNumber: SessionStorage.getSessionField('addrNumber'),
        addrStreet: SessionStorage.getSessionField('addrStreet'),
        addrPostalCode: SessionStorage.getSessionField('addrPostalCode'),
        addrCity: SessionStorage.getSessionField('addrCity'),
        email: LocalStorage.getId()
      };
      this.setState({user: tmp});
    }
    else {
      const tmp = await this.getUserInfo();
      this.setState({user: tmp});
    }
    this.setState({isPageLoading: false});
  }

  //function used to check in user personal info are already stored into the session storage
  async userInfoAvailable() {
    let ret = SessionStorage.getSessionField('firstName');
    if (ret === -1) {
      return false;
    }
    ret = SessionStorage.getSessionField('lastName');
    if (ret === -1) {
      return false;
    }
    ret = SessionStorage.getSessionField('company');
    if (ret === -1) {
      return false;
    }
    ret = SessionStorage.getSessionField('phoneNumber');
    if (ret === -1) {
      return false;
    }
    ret = SessionStorage.getSessionField('isAgency');
    if (ret === -1) {
      return false;
    }
    return true;
  }

  //function used to get user personal info from DB and store them into sessionStorage
  async getUserInfo() {
    const token = LocalStorage.getToken();
    const id = LocalStorage.getId();
    const data = {
      token: token,
      userId: id,
    };
    const ret = await Api.getUserInfo(data);
    if (ret !== false) {
      SessionStorage.setSessionField('email', ret.data.user.email);
      SessionStorage.setSessionField('company', ret.data.user.company);
      SessionStorage.setSessionField('firstName', ret.data.user.firstName);
      SessionStorage.setSessionField('lastName', ret.data.user.lastName);
      SessionStorage.setSessionField('phoneNumber', ret.data.user.phoneNumber);
      SessionStorage.setSessionField('isAgency', ret.data.user.isAgency);
      //address not required on register so check if address exist
      if (typeof ret.data.user.addrNumber !== 'undefined') {
        SessionStorage.setSessionField('addrNumber', ret.data.user.addrNumber);
      }
      if (typeof ret.data.user.addrStreet !== 'undefined') {
        SessionStorage.setSessionField('addrStreet', ret.data.user.addrStreet);
      }
      if (typeof ret.data.user.addrPostalCode !== 'undefined') {
        SessionStorage.setSessionField('addrPostalCode', ret.data.user.addrPostalCode);
      }
      if (typeof ret.data.user.addrCity !== 'undefined') {
        SessionStorage.setSessionField('addrCity', ret.data.user.addrCity);
      }
      return ret.data.user;
    }
    return null;
  }

  disconnectUser = () => {
    this.props.disconnectUser();
  }

  displayPage = (index) => {
    switch(index) {
      case 0:
        this.props.navigate('/accueil');
        break;
      case 1:
        this.props.navigate('/mes-campages');
        break;
      case 2:
        this.props.navigate('/nouvelle-campagne');
        break;
      case 3:
        this.props.navigate('/profil');
        break;
      default:
        return;
    }
  }

  goToUpdatePassword = () => {
    this.props.disconnectUser();
    this.props.navigate('/recuperation-mot-de-passe');
  }

  render() {
    return(
        this.state.isPageLoading ? null :
        <div className="container">
          <Content user={this.state.user} displayPage={this.displayPage} disconnectUser={this.disconnectUser} goToUpdatePassword={this.goToUpdatePassword}/>
        </div>
    );
  }
}

export default withRouter(Profile);
