import axios from 'axios';
import{ UserApiAddr } from './constant.js';

const apiAddr = UserApiAddr;

//User registration api call
export async function registerUser(data) {
  try {
    await axios.post(apiAddr+'/signup', data);
    return true;
  }
  catch(error) {
    console.log('error', error);
    return false;
  }
}

//User login api call
export async function logUserIn(data) {
  try {
     const res = await axios.post(apiAddr+'/login', data);
     return {
       status: 200,
       token: res.data.token,
     };
   }
   catch(error) {
     console.log(error);
     if (error.toString().includes('401')) {
       return {
         error: error.response.data.error,
         status: 401,
       };
     }
     else if (error.toString().includes('500')) {
       //Unexpected error
       return { status: 500 };
     }
   }
}

//api call to confirm user email
export async function confirmEmail(data) {
  try {
    const res = await axios.post(apiAddr+'/confirmUserEmail', data);
    //handle user api errors
    if (res.data.error) {
      return false;
    }
    return true;
  }
  catch(error) {
    console.log('Error with function confirmEmail : ', error);
    return false;
  }
}

//api call to reset user password
export async function resetPassword(data) {
  try {
    const res = await axios.post(apiAddr+'/resetPassword', data);
    //handle user api errors
    if (res.data.error) {
      return false;
    }
    return true;
  }
  catch(error) {
    console.log('Error with function resetPassword : ', error);
    return false;
  }
}

//api call to reset user password
export async function newPassword(data) {
  try {
    await axios.post(apiAddr+'/newPassword', data);
    return true;
  }
  catch(error) {
    console.log('Error with function newPassword : ', error);
    return false;
  }
}


//api call to confirm user email
export async function sendNewEmailConfirmation(data) {
  try {
    const res = await axios.post(apiAddr+'/sendNewEmailConfirmation', data);
    //handle user api errors
    if (res.data.error) {
      return false;
    }
    return true;
  }
  catch(error) {
    console.log('Error with function sendNewEmailConfirmation : ', error);
    return false;
  }
}

//api call to check and update user session token
export async function handleSessionToken(data) {
  try {
    const res = await axios.post(apiAddr+'/handleTokenVerification', data);
    //handle user api errors
    if (res.data.error) {
        return false;
    }
    if (res.data.message !== 'KO') {
      return res.data.message;
    }
    return false;
  }
  catch(error) {
    if (error.message.includes('601')) {
      return false;
    }
    console.log('Error with function handleSessionToken : ', error);
    return false;
  }
}

//api call to check and update user profile information
export async function getUserInfo(data) {
  try {
    const res = await axios.post(apiAddr+'/userInfo', data);
    //handle user api errors
    if (res.data.error) {
        return false;
      }
    return res;
  }
  catch(error) {
    if (error.message.includes('601')) {
      return false;
    }
    console.log('Error with function getUserInfo : ', error);
    return false;
  }
}

//api call to update user profile
export async function updateUserProfile(data) {
  try {
    const res = await axios.post(apiAddr+'/updateUserProfile', data);
    //handle user api errors
    if (res.data.error) {
        return false;
      }
    return res;
  }
  catch(error) {
    if (error.message.includes('601')) {
      return false;
    }
    console.log('Error with function updateUserProfile : ', error);
    return false;
  }
}
