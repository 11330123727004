import * as React from 'react';
import TextField from '../textfields/updateProfileTextfield.js';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function AddrAutocompleteTextfield(props) {

  return (
    <div>
      <TextField className="updatePersoInfoTextfield" textFieldType="text" placeholder={props.placeholder} stateName={props.stateName} textfieldValue={props.value} updateState={props.updateState}/>
      <FormControl fullWidth style={props.isOpen === true ? {} : {display: "none"}}>
        <Select
          sytle={{paddingRight: "0px !important"}}
          multiple
          native
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
        >
          {
            props.autocomplete.map((item, i) => (
              <option class="selectAddressItem" key={i} value={item.label}>
                {item.label}
              </option>
            ))
          }
        </Select>
      </FormControl>
    </div>
  );
}
