import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

export default function MediaCard(props) {
  return (
    <Card className="" sx={{ maxWidth: 345, width: props.cardWidth }} style={{cursor: "pointer"}} onClick={() => props.onCardClick(props.id)}>
      <CardMedia
        component="img"
        height="30%"
        image={props.cardImg}
        alt=""
      />
      <CardContent style={{minHeight: "13em"}}>
        <Typography gutterBottom variant="h5" component="div" className="bold">
          <span className="bold" style={{fontSize: "0.9em", color: "#00c2cb"}}>{props.cardTitle}</span>
        </Typography>
        <Typography variant="body2" color="text.secondary">
          <span style={{fontSize: "0.79em"}}>{props.cardContent1}</span>
        </Typography>
        {
          props.cardContent2 === '' ? null :
          <Typography variant="body2" color="text.secondary" style={{marginTop: "0.5em", marginBottom: "0.5em"}}>
            <span style={{fontSize: "0.79em"}}>{props.cardContent2}</span>
          </Typography>
        }
        {

          props.priceContent === '' ? null :
          <Typography variant="body2" color="text.secondary">
            <span style={{fontSize: "0.79em"}}>{props.priceContent}</span>
          </Typography>
        }
      </CardContent>
    </Card>
  );
}
