import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "30vw",
  bgcolor: 'background.paper',
  border: '2px solid black',
  boxShadow: 24,
  p: 4,
};

export default function TransitionsModal(props) {
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
            {
              <span className="bold">En savoir plus</span>
            }
            </Typography>
            <div style={{marginTop: "1.5em", marginBottom: "1.5em"}}>
              <span style={{display: "flex", flexDirection: "column"}}>
                <Typography sx={{ mt: 2 }}>
                  <span style={{display: "flex"}}><ArrowRightIcon /> Votre campagne pub en télévision ! Sur le téléviseur des foyers que vous souhaitez toucher, et uniquement ceux-ci, votre spot pub viendra remplacer une publicité prévue initialement pour l&#39;ensemble des français durant le direct.</span>
                </Typography>
                <Typography sx={{ mt: 2 }}>
                  <span style={{display: "flex"}}><ArrowRightIcon /> Si vous optez pour le Replay, votre spot publicitaire peut aussi être diffusé quand les foyers ciblés lanceront le Replay de leur émission préférée depuis leur téléviseur.</span>
                </Typography>
                <Typography sx={{ mt: 2 }}>
                  <span className='bold'>Seules les impressions délivrées et diffusées seront facturées.</span>
                </Typography>
              </span>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
