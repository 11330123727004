import * as React from 'react';
import Radio from '@mui/material/Radio';
import MovingIcon from '@mui/icons-material/Moving';
import StorefrontIcon from '@mui/icons-material/Storefront';
import StarIcon from '@mui/icons-material/Star';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import Tooltip from '@mui/material/Tooltip';

function getIcon(id) {
  switch (id) {
    case 1:
      return <StarIcon />
      break;
    case 2:
      return <MovingIcon />
      break;
    case 3:
      return <StorefrontIcon />
      break;
    case 4:
      return <HowToRegIcon />
      break;
    default:
      return null;
  }
}

function getHeight(position) {
  if (position === 1) {
    return "35%";
  }
  else {
    return "33%";
  }
}

function getCardStyle(props) {
  if (props.value === true) {
    return {border: "1px solid #00c2cb"};
  }
  else {
    return {border: "1px solid grey"};
  }
}

export default function card(props) {
  return (
    <Tooltip title={<span><p>{props.tooltipText}</p><p>{props.tooltipExample}</p></span>} placement="top">
      <div className='step0Card' onClick={() => props.onChange(props.id, !props.value)} style={getCardStyle(props)}>
        <div style={{display: "flex", flexDirection: "row", width: "100%", height: getHeight(1)}}>
          <div style={{width: "85%", display: "flex", flexDirection: "column"}}>
            <span style={{marginBottom: "0.1em"}}>{getIcon(props.id)}</span>
            <span className="bold">{props.cardTitle}</span>
          </div>
          <div style={{width: "15%", display: "flex", justifyContent: "center"}}>
            <Radio value="" checked={props.value}/>
          </div>
        </div>
        <div style={{display: "flex", flexDirection: "row", height: getHeight(2)}}>
          <span style={{position: "absolute", fontSize: "0.8em", bottom: "0px", paddingTop: "10px", paddingBottom: "10px", paddingRight: "10px"}}>{props.cardText}</span>
        </div>
      </div>
    </Tooltip>
  );
}
