import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function AutoCompleteCustom(props) {
  return (
    <div>
      <Autocomplete
        className="updatePersoInfoTextfield"
        defaultValue={props.value}
        disablePortal
        id="combo-box-demo"
        noOptionsText="Pas de résultat"
        options={props.customLocations}
        onChange={(e) => props.onChange(e.target.innerText)}
        style={{width: "100%"}}
        renderInput={(params) => <TextField {...params} className="registerTextfield" label="Votre adresse de départ" variant="outlined" value={props.value} onChange={(e) => {props.updateState(props.stateName, e.target.value)}}/> }
      />
    </div>
  );
}
