import * as React from 'react';
import Radio from '@mui/material/Radio';
import Tooltip from '@mui/material/Tooltip';
import { styled } from "@mui/material/styles";

function getCardStyle(props) {
  /*if (props.currentStep === 3) {
    if (props.value === true) {
      return {border: "1px solid #00c2cb", width: "98%", minWidth: "8em"};
    }
    else {
      return {border: "1px solid grey", width: "98%", minWidth: "8em"}
    }
  }*/
  if (props.value === true) {
    if (props.isCardPink === true) {
      if (typeof props.backgroundColor === "undefined") {
        return {border: "1px solid #ff66c4", width: "98%", minWidth: "8em"};
      }
      else {
        return {border: "1px solid #ff66c4", backgroundColor: props.backgroundColor, width: "98%", minWidth: "8em"}
      }
    }
    if (typeof props.backgroundColor === "undefined") {
      return {border: "1px solid #00c2cb", width: "98%", minWidth: "8em"};
    }
    else {
      return {border: "1px solid #00c2cb", backgroundColor: props.backgroundColor, width: "98%", minWidth: "8em"}
    }
  }
  else {
    if (typeof props.backgroundColor === "undefined") {
      return {border: "1px solid grey", width: "98%", minWidth: "8em"};
    }
    else {
      return {border: "1px solid grey", backgroundColor: props.backgroundColor, width: "98%", minWidth: "8em"}
    }
  }
}

//used to not display tooltip if card budget is enable
function displayTooltipText(props) {
  if (props.currentStep === 2) {
    if (props.id === 1) {
      if (props.budget >= 5000 && props.budget > -1) {
        return '';
      }
      else {
        return props.tooltipText;
      }
    }
    else if (props.id === 2 && props.budget > -1) {
      if (props.budget >= 8000) {
        return "";
      }
      else {
        return props.tooltipText;
      }
    }
  }
  return props.tooltipText;
}

const GreyRadio = styled(Radio)(({ theme }) => ({
  color: 'grey !important',
  "& .css-l18y8w.Mui-checked": {
    color: 'grey !important'
  }
}));

const PinkRadio = styled(Radio)(({ theme }) => ({
  color: '#ff66c4 !important',
  "& .css-l18y8w.Mui-checked": {
    color: '#ff66c4 !important'
  }
}));

const BlueRadio = styled(Radio)(({ theme }) => ({
  color: '#00c2cb !important',
  "& .css-l18y8w.Mui-checked": {
    color: '#00c2cb !important'
  }
}));

export default function card(props) {
  return (
    displayTooltipText(props) === "" ?
    <div className="oneLineCard" onClick={() => props.onChange(props.id, !props.value, props.item)} style={getCardStyle(props)}>
      <div style={{display: "flex", justifyContent: "flex-start", width: "75%"}}>
        <span style={{fontSize: "0.9em", marginTop: "auto", marginBottom: "auto"}}>{props.cardText}</span>
      </div>
      <div style={{display: "flex", paddingRight: "5px", position: "absolute", right: '5px', bottom: '10px'}}>
      {
        props.value !== true
        ?
        <GreyRadio value="" checked={props.value} />
        :
        props.isCardPink
        ?
        <PinkRadio value="" checked={props.value} />
        :
        <BlueRadio value="" checked={props.value} />
      }
      </div>
    </div>
    :
    <Tooltip title={props.tooltipText} placement="top">
      <div className="oneLineCard" onClick={() => props.onChange(props.id, !props.value, props.item)} style={getCardStyle(props)}>
        <div style={{display: "flex", justifyContent: "flex-start", width: "75%"}}>
          <span style={{fontSize: "0.9em", marginTop: "auto", marginBottom: "auto"}}>{props.cardText}</span>
        </div>
        <div style={{display: "flex", paddingRight: "5px", position: "absolute", right: '5px', bottom: '10px'}}>
        {
          props.value !== true
          ?
          <GreyRadio value="" checked={props.value} />
          :
          props.isCardPink
          ?
          <PinkRadio value="" checked={props.value} />
          :
          <BlueRadio value="" checked={props.value} />
        }
        </div>
      </div>
    </Tooltip>
  );
}
