import React from 'react';
import {withRouter} from '../services/withRouter.js';
import Content from '../components/pageContent/newCampaignContent';
import Alert from '../components/alerts/newCampaignAlerts';

import "../css/app.css";

class NewCampaign extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sessionData: [],
      isPageLoading: true,
      isAlertVisible: false,
      alertText: "",
      alertColor: "",
      alertSeverity: '',
    };
  }

  componentDidMount() {
    this.setState({isPageLoading: false});
  }

  disconnectUser = () => {
    this.props.disconnectUser();
  }

  displayPage = (index) => {
    switch(index) {
      case 0:
        this.props.navigate('/accueil');
        break;
      case 1:
        this.props.navigate('/mes-campages');
        break;
      case 2:
        this.props.navigate('/nouvelle-campagne');
        break;
      case 3:
        this.props.navigate('/profil');
        break;
      default:
        return;
    }
  }

  setIsAlertVisible = (val) => {
    this.setState({isAlertVisible: val});
  }

  setIsAlert = (val, text, severity, color) => {
    this.setState({isAlertVisible: val});
    this.setState({alertText: text});
    this.setState({alertSeverity: severity});
    this.setState({alertColor: color});
  }

  render() {
    return(
        this.state.isPageLoading ? null :
        <div className="">
          <Content
            displayPage={this.displayPage}
            disconnectUser={this.disconnectUser}
            setIsAlert={this.setIsAlert}
            setIsAlertVisible={this.setIsAlertVisible}
            {...this.state}
          />
        </div>
    );
  }
}

export default withRouter(NewCampaign);
