import React from 'react';
import Logo from "../assets/videoclub.png";
import TypeWriter from '../components/typewriter.js';
import LoginRegisterAlerts from '../components/alerts/loginRegisterAlerts.js';
import ForgotPasswordButton from '../components/buttons/returnButton.js';

import * as Api from '../services/userApi.js';
import {withRouter} from '../services/withRouter.js';
import "../css/app.css";

class EmailConfirmed extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      email: "",
      token: "",
      pageText: "",
      greetingMsg: "VALIDATION EMAIL",
      isAlertVisible: false,
      alertSeverity: "",
      alertText: "",
      alertColor: ""
    };
  }

  async componentDidMount() {
    const data = {
      userId: this.props.email,
      token: this.props.token
    };
    const ret = await Api.confirmEmail(data);
    if (ret !== false) {
      this.setState({pageText: "Votre adresse email est maintenant validée. Vous pouvez vous connecter."});
    }
    else {
      this.setState({pageText: "Le lien de validation d'adresse email a expiré. Merci de recommencer"});
    }
  }

  //general function used to update state
  updateState = (stateName, value) => {
    this.setState({[stateName]: value});
  }

  //function called when login / register button are clicked
  buttonClicked = async () => {
    this.props.navigate("/connexion");
  }

  goBack = () => {
    this.props.navigate("/connexion");
  }

  updateWelcomeText = () => {

  }

  returnFunction = () => {

  }

  //function used to display / hide alerts
  updateAlertState = () => {
    this.setState({isAlertVisible: !this.state.isAlertVisible});
  }

  render() {
    return(
      <div className="contentWrapper animatedBackground">
        <div className="registerContentWrapper">
          <div className="registerCard">
            <div className="registerCardLeft">
              <div className="registerLogoCenterDiv">
                <div className="registerCenterWrapper">
                  <img src={Logo} alt="" className="registerLogo"/>
                </div>
              </div>
            </div>
            <div className="registerCardRight">
            <LoginRegisterAlerts {...this.state} onClose={this.updateAlertState}/>
              <div className="typewriterDiv">
                <TypeWriter
                  ref={this.child}
                  updateWelcomeText={this.updateWelcomeText}
                  content={this.state.greetingMsg}
                  returnFunction={this.returnFunction}
                />
              </div>
              <div style={{textAlign: "center", width: "80%", marginLeft: "auto", marginRight: "auto"}}>
                <p style={{width: "100%"}}>
                  {this.state.pageText}
                </p>
                <ForgotPasswordButton onClick={this.buttonClicked} submitButtonText="Retour"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(EmailConfirmed);
