import React, { useEffect  } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TF1 from '../../assets/logo/tf1.png';
import M6 from '../../assets/logo/M6.png';
import France2 from '../../assets/logo/France2.png';
import France3 from '../../assets/logo/France3.png';
import France4 from '../../assets/logo/France4.png';
import France5 from '../../assets/logo/France5.png';
import Canal from '../../assets/logo/canal.png';
import TMC from '../../assets/logo/tmc.png';
import C8 from '../../assets/logo/C8.png';
import W9 from '../../assets/logo/W9.png';
import BFM from '../../assets/logo/BFM.png';
import TFX from '../../assets/logo/TFX.png';
import TF1Series from '../../assets/logo/FT1Series.png';
import TER from '../../assets/logo/6TER.jpg';
import RMC from '../../assets/logo/RMC.png';
import RMC2 from '../../assets/logo/RMC2.png';
import Equipe from '../../assets/logo/Equipe.png';
import Bein from '../../assets/logo/Bein.png';
import MYTF1 from '../../assets/logo/MYTF1.jpg';
import FranceTV from '../../assets/logo/FranceTV.png';
import Play from '../../assets/logo/6PLAY.png';
import Loader from '../loaders/loader.js';
import GridImg from '../../assets/logo/grid.png';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "30vw",
  bgcolor: 'background.paper',
  border: '2px solid black',
  boxShadow: 24,
  p: 4,
  minWidth: "25em",
  maxWidth: '35em'
};

const logo = [
  TF1,
  M6,
  France2,
  France3,
  France4,
  France5,
  Canal,
  TMC,
  C8,
  W9,
  BFM,
  TFX,
  TF1Series,
  TER,
  RMC,
  RMC2,
  Equipe,
  Bein,
  MYTF1,
  FranceTV,
  Play,
  GridImg
]

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export default function TransitionsModal(props) {
  const [animationIsRunning, setAnimationIsRunning] = React.useState(false);

  useEffect(() => {
    setAnimationIsRunning(true);
    setTimeout(handleAnimation, 500);
  }, props.isCmdModalVisible);


  async function handleAnimation() {
    const length = logo.length;
    let item;
    let timer = 1100;
    for (let i = 0; i < length; i++) {
      if (animationIsRunning) {
        item = document.getElementById("logo" + i.toString());
        if (item) {
          item.classList.remove('displayNone');
          item.classList.add('animate__zoomIn');
          if (i + 1 === length) {
            return;
          }
          await sleep(timer);
          item.classList.remove('animate__zoomIn');
          item.classList.add('animate__zoomOut');
          await sleep(timer / 2);
          item.classList.add('displayNone');
          if (timer > 500) {
            timer = timer - 100;
          }
        }
      }
      else {
        return;
      }
    }
  }

  function closePopup() {
    props.handleClose();
    if (props.isCmdValidated === 1) {
      window.location.reload(false);
    }
  }

  function validateMediaPlan(props) {
    setAnimationIsRunning(false);
    const items = document.getElementsByClassName('imgWrapper');
    for (let i = 0; i < items.length; i++) {
      items[i].classList.add('displayNone');
    }
    props.validateCmd(2);
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        open={props.open}
        onClose={() => closePopup()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box sx={style} style={{position: "relative"}}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
            {
              <span className="bold" style={{textAlign: "center"}}>Confirmation du plan média</span>
            }
            </Typography>
            <div style={{height: '16.5em'}}>
              <div style={props.isCmdValidated === 1 ? {display: "none"} : animationIsRunning ? {position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"} : {display: "none"}}>
                <span className='imgWrapper'><img className="step4LogoImg animate__animated displayNone" src={logo[0]} alt="" id="logo0" style={{width: "12em"}}/></span>
                <span className='imgWrapper'><img className="step4LogoImg animate__animated displayNone" src={logo[1]} alt="" id="logo1" style={{width: "9em"}}/></span>
                <span className='imgWrapper'><img className="step4LogoImg animate__animated displayNone" src={logo[2]} alt="" id="logo2" style={{width: "8em"}}/></span>
                <span className='imgWrapper'><img className="step4LogoImg animate__animated displayNone" src={logo[3]} alt="" id="logo3" style={{width: "8em"}}/></span>
                <span className='imgWrapper'><img className="step4LogoImg animate__animated displayNone" src={logo[4]} alt="" id="logo4" style={{width: "8em"}}/></span>
                <span className='imgWrapper'><img className="step4LogoImg animate__animated displayNone" src={logo[5]} alt="" id="logo5" style={{width: "8em"}}/></span>
                <span className='imgWrapper'><img className="step4LogoImg animate__animated displayNone" src={logo[6]} alt="" id="logo6" style={{width: "15em"}}/></span>
                <span className='imgWrapper'><img className="step4LogoImg animate__animated displayNone" src={logo[7]} alt="" id="logo7" style={{width: "12em"}}/></span>
                <span className='imgWrapper'><img className="step4LogoImg animate__animated displayNone" src={logo[8]} alt="" id="logo8"/></span>
                <span className='imgWrapper'><img className="step4LogoImg animate__animated displayNone" src={logo[9]} alt="" id="logo9"/></span>
                <span className='imgWrapper'><img className="step4LogoImg animate__animated displayNone" src={logo[10]} alt="" id="logo10" style={{width: "9em"}}/></span>
                <span className='imgWrapper'><img className="step4LogoImg animate__animated displayNone" src={logo[11]} alt="" id="logo11"/></span>
                <span className='imgWrapper'><img className="step4LogoImg animate__animated displayNone" src={logo[12]} alt="" id="logo12" style={{width: "15em"}}/></span>
                <span className='imgWrapper'><img className="step4LogoImg animate__animated displayNone" src={logo[13]} alt="" id="logo13"/></span>
                <span className='imgWrapper'><img className="step4LogoImg animate__animated displayNone" src={logo[14]} alt="" id="logo14"/></span>
                <span className='imgWrapper'><img className="step4LogoImg animate__animated displayNone" src={logo[15]} alt="" id="logo15"/></span>
                <span className='imgWrapper'><img className="step4LogoImg animate__animated displayNone" src={logo[16]} alt="" id="logo16" style={{width: "12em"}}/></span>
                <span className='imgWrapper'><img className="step4LogoImg animate__animated displayNone" src={logo[17]} alt="" id="logo17"/></span>
                <span className='imgWrapper'><img className="step4LogoImg animate__animated displayNone" src={logo[18]} alt="" id="logo18" style={{width: "11em"}}/></span>
                <span className='imgWrapper'><img className="step4LogoImg animate__animated displayNone" src={logo[19]} alt="" id="logo19" style={{width: "12em"}}/></span>
                <span className='imgWrapper'><img className="step4LogoImg animate__animated displayNone" src={logo[20]} alt="" id="logo20"/></span>
                <span className='imgWrapper'><img className="step4LogoImg animate__animated displayNone" src={logo[21]} alt="" id="logo21" style={{width: "20em"}}/></span>
              </div>
              <div style={props.isCmdLoading === false && props.isCmdValidated === -1 ? {height: 'auto', width: '90%', maxWidth: "35em", marginLeft: "auto", marginRight: "auto", marginTop: '2em'} : {border: '1px solid #ff66c4', height: 'auto', width: '90%', maxWidth: "35em", padding: '10px', marginLeft: "auto", marginRight: "auto", marginTop: '2em'}}>
                {
                  props.isCmdValidated === 1
                  ?
                  <div style={{display: "flex", justifyContent: "center", flexDirection: "column", textAlign: "center", alignItems: "center"}}>
                    <Loader {...props}/>
                    <p className='bold' style={{fontSize: '1.1em', color: '#ff66c4'}}>Merci !</p>
                    <p>Nous calculons les <span className="bold">meilleurs emplacements</span> et les <span className="bold">meilleures disponibilités</span></p>
                    <p style={{marginTop: "-0.5em"}}>Retour avec votre plan sous <span className="bold">48 heures</span></p>
                  </div>
                  :
                  props.isCmdValidated === 0
                  ?
                  <div style={{display: "flex", justifyContent: "center", flexDirection: "column", textAlign: "center", alignItems: "center"}}>
                    <Loader {...props}/>
                    <p className='bold' style={{fontSize: '1.1em', color: '#ff66c4'}}>Une erreur est survenue</p>
                    <p>Merci de réessayer plus tard</p>
                  </div>
                  :
                  props.isCmdLoading
                  ?
                  <div style={{display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center"}}>
                    <Loader {...props}/>
                    <p className='bold' style={{fontSize: '1.1em', color: '#ff66c4'}}>Chargement ...</p>
                  </div>
                  :
                  <div style={{display: "flex", justifyContent: "center"}}>
                    <Button onClick={() => validateMediaPlan(props)} className="pinkButton blueShadow" style={{minWidth: "22em", position: "absolute", bottom: "30px", height: '3.5em'}}><span style={{fontSize: "0.9em"}}>Confirmer votre plan média</span></Button>
                  </div>
                }
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
