import React, { useState, useEffect } from "react";
import * as locales from "react-date-range/dist/locale";
import { DateRange  } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { addDays } from "date-fns";


function DatePicker(props) {
  const [ranges, setRanges] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "rollup"
    }
  ]);
  const [previewRange, setPreviewRange] = React.useState({});

  useEffect(() => {
    initValues();
  }, []);

  function initValues() {
    let start = handleDate(props.startDate);
    start = new Date(start);
    let stop = handleDate(props.stopDate);
    stop = new Date(stop);
    const data = {
      startDate: start,
      endDate: stop,
      color: '#00c2cb'
    }
    setPreviewRange(data);
  }

  //function used to handle date if cmd is loaded
  function handleDate(date) {
    let array = date.split("/");
    return array[1] + "/" + array[0] + "/" + array[2];
  }

  function onDateChange(ranges) {
    const maxDate = addDays(ranges.rollup.startDate, 28)
    if (ranges.rollup.endDate > maxDate) {
      ranges.rollup.endDate = maxDate
    }
    setRanges([ranges.rollup])
    props.handleDateChange(ranges)
  }

  return (
    <DateRange
      startDatePlaceholder="Début de la campagne"
      endDatePlaceholder="Fin de la campagne"
      ranges={ranges}
      preview={previewRange}
      rangeColors='#00c2cb'
      onChange={ranges => onDateChange(ranges)}
      inputRanges={[]}
      color='#00c2cb'
      locale={locales["fr"]}
      minDate={addDays(new Date(), 15)}
    />
  );
}

export default DatePicker;
