import "../../css/app.css";

function LoginFormTitle(props) {
  return (
    <div className="">
      <div id="loginFormTitle" className="registerTitleDiv showFlex">
        <h1 className="registerTitle">{props.pageTitle}</h1>
        <button className="bold" id="createAccount" onClick={(e) => props.changePage(e)}>
          <span style={props.isLoginPage ? {marginRight: "30px"} : {}}>{props.updateFormButton}</span>
        </button>
      </div>
      <div id="loginFormTitleMin" className="registerTitleDiv hide">
        <button className="bold" id="createAccount" onClick={(e) => props.changePage(e)}>
          <i style={{paddingLeft: "4px"}} id="returnToLoginArrow" className="fa-solid fa-arrow-left-long"></i>
        </button>
        <h1 className="registerTitleMin">{props.pageTitle}</h1>
      </div>
    </div>
  );
}

export default LoginFormTitle;
