const QuotationApiAddr = initQuotationAddr();
const UserApiAddr = initUserAddr();

function initQuotationAddr() {
  let ret = "";
  if (window.location.href.includes('pawlaczyk.dev')) {
    ret = "https://api.pawlaczyk.dev/api/quotations";
  }
  else {
    ret = "https://api.videoclub-pub.app/api/quotations";
  }
  //console.log('QUOTATION ADDR')
  //console.log(ret)
  return ret;
}

function initUserAddr() {
  let ret = "";
  if (window.location.href.includes('pawlaczyk.dev')) {
    ret = "https://api.pawlaczyk.dev/api/user";
  }
  else {
    ret = "https://api.videoclub-pub.app/api/user";
  }
  //console.log('User ADDR')
  //console.log(ret)
  return ret;
}

export {QuotationApiAddr, UserApiAddr};
