import * as React from 'react';
import Radio from '@mui/material/Radio';
import MovingIcon from '@mui/icons-material/Moving';
import StorefrontIcon from '@mui/icons-material/Storefront';
import StarIcon from '@mui/icons-material/Star';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ConnectedTvIcon from '@mui/icons-material/ConnectedTv';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import HomeIcon from '@mui/icons-material/Home';
import FavoriteIcon from '@mui/icons-material/Favorite';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import WcIcon from '@mui/icons-material/Wc';
import { styled } from "@mui/material/styles";
import { ReactComponent as Natio } from '../../assets/icons/natio.svg';

function getIcon(id, currentStep, item) {
  if (currentStep === 0) {
    switch (id) {
      case 1:
        return <StarIcon />
        break;
      case 2:
        return <MovingIcon />
        break;
      case 3:
        return <StorefrontIcon />
        break;
      case 4:
        return <HowToRegIcon />
        break;
      default:
        return null;
    }
  }
  if (currentStep === 1 && item === 'geoArea') {
    switch (id) {
      case 1:
        return <Natio style={{width: "1.8em", height: "auto"}}/>
        break;
      case 2:
        return <FmdGoodIcon />
        break;
      case 3:
        return <AddLocationAltIcon />
        break;
      case 4:
        return <LocationCityIcon />
        break;
      case 5:
        return null
        break;
      case 6:
        return <EditLocationAltIcon />
        break;
      default:
        return null;
    }
  }
  if (currentStep === 1 && item === 'target') {
    switch (id) {
      case 0:
        return <WcIcon />
        break;
      case 1:
        return <FamilyRestroomIcon />
        break;
      case 2:
        return <BusinessCenterIcon />
        break;
      case 3:
        return <HomeIcon />
        break;
      case 4:
        return <FavoriteIcon />
        break;
      case 5:
        return <ManageAccountsIcon />
        break;
      case 6:
        return <MapsHomeWorkIcon />
        break;
      case 7:
        return <ConnectedTvIcon />
        break;
      case 8:
        return <WbSunnyIcon />
        break;
      default:
        return null;
    }
  }
}

function getHeight(item, position) {
  if (item === 'objective') {
    if (position === 1) {
      return "35%";
    }
    else {
      return "33%";
    }
  }
  else {
    if (position === 1) {
      return "40%";
    }
    else {
      return "60%";
    }
  }
}

function getClassName(item) {
  if (item === 'objective') {
    return "objectifCard";
  }
  else if (item === "target") {
    return "targetCard";
  }
  else {
    return "geoAreaCard";
  }
}

function getCardStyle(props) {
  if (props.value === true) {
    if (props.isCardPink === true) {
      return {border: "1px solid #ff66c4"};
    }
    return {border: "1px solid #00c2cb"};
  }
  else {
    return {border: "1px solid grey"};
  }
}

const GreyRadio = styled(Radio)(({ theme }) => ({
  color: 'grey !important',
  "& .css-l18y8w.Mui-checked": {
    color: 'grey !important'
  }
}));

const PinkRadio = styled(Radio)(({ theme }) => ({
  color: '#ff66c4 !important',
  "& .css-l18y8w.Mui-checked": {
    color: '#ff66c4 !important'
  }
}));

const BlueRadio = styled(Radio)(({ theme }) => ({
  color: '#00c2cb !important',
  "& .css-l18y8w.Mui-checked": {
    color: '#00c2cb !important'
  }
}));

export default function card(props) {
  return (
    <div className={getClassName(props.item)} onClick={() => props.onChange(props.id, !props.value)} style={getCardStyle(props)}>
      <div style={{display: "flex", flexDirection: "row", width: "100%", height: getHeight(props.item, 1)}}>
        <div style={{width: "85%", display: "flex", flexDirection: "column"}}>
          <span style={{marginBottom: "0.1em"}}>{getIcon(props.id, props.currentStep, props.item)}</span>
          <span className="bold">{props.cardTitle}</span>
        </div>
        <div style={{width: "15%", display: "flex", justifyContent: "center"}}>
        {
          props.value !== true
          ?
          <GreyRadio value="" checked={props.value} />
          :
          props.isCardPink
          ?
          <PinkRadio value="" checked={props.value} />
          :
          <BlueRadio value="" checked={props.value} />
        }
        </div>
      </div>
      <div style={props.examples !== '' ? {display: "flex", flexDirection: "row", height: getHeight(props.item, 2), position: "relative"} : {display: "flex", flexDirection: "row", height: "66%", position: "relative"}}>
        <span style={props.currentStep === 0 ? {position: "absolute", fontSize: "0.8em"} : {position: "absolute", fontSize: "0.8em", bottom: "0px"}}>{props.cardText}</span>
      </div>
      <div style={props.examples !== "" ? {display: "flex", flexDirection: "row", height: getHeight(props.item, 2), position: "relative"} : null}>
        <span style={{position: "absolute", bottom: "0px", fontSize: "0.8em", fontStyle: "italic"}}>{props.examples}</span>
      </div>
    </div>
  );
}
