import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate, useParams } from "react-router-dom";
import * as LocalStorage from './localStorage';
import * as UserApi from './userApi';
import Home from '../pages/Home';
import Login from '../pages/Login';
import Profile from '../pages/Profile';
import Campaign from '../pages/Campaign';
import NewCampaign from '../pages/NewCampaign';
import ForgotPassword from '../pages/ForgotPassword';
import NewPassword from '../pages/NewPassword';
import EmailConfirmed from '../pages/EmailConfirmed';
import * as UAParser from 'ua-parser-js';

const routeAllowedNoAuth = [
  "recuperation-mot-de-passe",
  "nouveau-mot-de-passe",
  "validation-email",
];

function RoutesManager() {
  const [isUserConnected, setIsUserConnected] = useState(false);
  const [userId, setUserId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    //used to check token validity
    const checkToken = async(data) => {
      const ret = await UserApi.handleSessionToken(data);
      if (ret !== false) {
        const route = window.location.href;
        if (route.includes("/connexion")) {
          setIsUserConnected(true);
        }
        else {
          if (route.includes('/accueil')) {
            navigate("/accueil");
          }
          else if (route.includes('/mes-campages')) {
            navigate("/mes-campages");
          }
          else if (route.includes('/nouvelle-campagne')) {
            navigate("/nouvelle-campagne");
          }
          else if (route.includes('/profil')) {
            navigate("/profil");
          }
        }
      }
      else {
        LocalStorage.setToken(ret);
        navigate("/connexion");
      }
    }
    //user connection success
    if (isUserConnected) {
      navigate("/");
    }
    //user not connected yet
    else if (!isUserConnected) {
      if (isRouteAllowed()) {
        return;
      }
      //if user is stay connected
      else if (LocalStorage.getStayConnected() === 'true') {
        const token = LocalStorage.getToken();
        const id = LocalStorage.getId();
        if (token === -1) {
          navigate("/connexion");
        }
        else {
          let parser = new UAParser();
          parser = parser.getResult();
          const data = {
            token: token,
            userId: id,
            browserUsed: parser.browser.name,
            osUsed: parser.os.name,
            location: window.location.href
          }
          //try to log user thanks to token
          checkToken(data);
        }
      }
      else {
        navigate("/connexion");
      }
    }
  }, [isUserConnected]);

  function isRouteAllowed() {
    let routesArray = routeAllowedNoAuth;
    const route = window.location.href;
    for (let i = 0; i < routesArray.length; i++) {
      if (route.includes(routesArray[i])) {
        return true;
      }
    }
    return false;
  }

  function handleUserConnection() {
    const newState = !isUserConnected;
    setIsUserConnected(newState);
    if (newState === false) {
      LocalStorage.resetToken();
    }
  }

  function disconnectUser() {
    setIsUserConnected(false);
    LocalStorage.resetToken();
    navigate('/');
  }


  function handleUserId(id) {
    setUserId(id);
  }

  function NewPasswordWrapper() {
    let params = useParams();
    return <NewPassword email={params.account} token={params.token}/>;
  }

  function EmailConfirmedWrapper() {
    let params = useParams();
    return <EmailConfirmed email={params.account} token={params.token}/>;
  }

    return(
      <div className="App">
        <Routes>
        <Route path="/" element={isUserConnected ?  <Navigate to="/accueil"/> : <Navigate to="/connexion"/>} />
          <Route path="connexion"
            element={
              <Login
                handleUserConnection={() => handleUserConnection()}
                handleUserId={(id) => handleUserId(id)}
              />
            }/>
          <Route path="accueil" element={<Home handleUserConnection={() => handleUserConnection()} disconnectUser={() => disconnectUser()}/>}/>
          <Route path="recuperation-mot-de-passe" element={<ForgotPassword />}/>
          <Route path="nouveau-mot-de-passe/:account/:token" element={<NewPasswordWrapper />}/>
          <Route path="validation-email/:account/:token" element={<EmailConfirmedWrapper />}/>
          <Route path="profil" element={<Profile handleUserConnection={() => handleUserConnection()} disconnectUser={() => disconnectUser()}/>}/>
          <Route path="mes-campages" element={<Campaign handleUserConnection={() => handleUserConnection()} disconnectUser={() => disconnectUser()}/>}/>
          <Route path="nouvelle-campagne" element={<NewCampaign handleUserConnection={() => handleUserConnection()} disconnectUser={() => disconnectUser()}/>}/>
        </Routes>
      </div>
    );
}

export default RoutesManager;
