import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import Button from '@mui/material/Button';
import Card from '../cards/homeCards';
import WidgetsIcon from '@mui/icons-material/Widgets';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import Carousel from 'nuka-carousel';
import TF1 from '../../assets/logo/tf1.png';
import M6 from '../../assets/logo/M6.png';
import France2 from '../../assets/logo/France2.png';
import France3 from '../../assets/logo/France3.png';
import France5 from '../../assets/logo/France5.png';
import Canal from '../../assets/logo/canal.png';
import TMC from '../../assets/logo/tmc.png';
import C8 from '../../assets/logo/C8.png';
import CStar from '../../assets/logo/CSTAR.jpg';
import W9 from '../../assets/logo/W9.png';
import BFM from '../../assets/logo/BFM.png';
import TFX from '../../assets/logo/TFX.png';
import TF1Series from '../../assets/logo/FT1Series.png';
import TER from '../../assets/logo/6TER.jpg';
import RMC from '../../assets/logo/RMC.png';
import RMC2 from '../../assets/logo/RMC2.png';
import Equipe from '../../assets/logo/Equipe.png';
import Bein from '../../assets/logo/Bein.png';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import Modal from '../modals/homeModal';
import LargeModal from '../modals/largeHomeModal';
import Primes from '../../assets/img/Primes.png';
import Sports from '../../assets/img/Sports.png';
import FirstTime from '../../assets/img/FirstTime.png';
import Family from '../../assets/img/Family.png';
import Actualité from '../../assets/img/Actualité.png';
import Generation from '../../assets/img/GenerationY&Z.png';
import Geoloc from '../../assets/img/Geoloc.png';

import logo from '../../assets/videoclub.png';

const cardsInfo = [
  {
    cardTitle: "First Time TV",
    cardContent1: 'Vous souhaitez vous lancer en TV pour la première fois ? Mais vous ne savez pas par où commencer !',
    cardContent2: '« First Time TV » C’est l’offre clef en main dont vous avez besoin !',
    priceContent: 'A partir de 3000 euros.',
    cardImg: FirstTime,
  },
  {
    cardTitle: "Geoloc TV",
    cardContent1: 'Diffusez votre pub TV uniquement auprès des téléspectateurs présents sur la zone géographique de votre choix :',
    cardContent2: 'Des ciblages géolocalisés selon vos objectifs : Région(s) - Département(s) - Ville(s) – et même jusqu’au code Postal',
    priceContent: '',
    cardImg: Geoloc,
  },
  {
    cardTitle: "100% Prime",
    cardContent1: 'En quête de notoriété pour votre marque, communiquez en TV au sein des plus gros contextes et programmes !',
    cardContent2: 'Accédez ainsi aux plus grands primes TV et renforcez ainsi votre image de marque.',
    priceContent: '',
    cardImg: Primes,
  },
  {
    cardTitle: "Sport & 100% Club",
    cardContent1: 'Vous souhaitez communiquer en TV et vous associer aux plus gros évènements sportifs ?',
    cardContent2: 'Diffusez votre publicité au sein des plus grandes compétitions sportives et matchs de votre club.',
    priceContent: 'L’offre idéale pour faire le plein de Notoriété et gagner en visibilité !',
    cardImg: Sports,
  },
  {
    cardTitle: "Family TV",
    cardContent1: 'Acteurs de l’évènementiel, de la culture, du Tourisme,  des loisirs…',
    cardContent2: 'Ciblez les familles et les programmes TV qui se regardent à plusieurs ! ',
    priceContent: '',
    cardImg: Family,
  },
  {
    cardTitle: "Génération YZ",
    cardContent1: 'Une offre 4 écrans pour cibler les millienals',
    cardContent2: 'Diffusez votre publicité en ciblant les -30 ans et les programmes regardés par les jeunes.',
    priceContent: '',
    cardImg: Generation,
  },
  {
    cardTitle: "Info & Décideurs",
    cardContent1: 'Vous souhaitez cibler une audience CSP+ et décideurs d’entreprise ?',
    cardContent2: 'Diffusez votre publicité  en TV sur BFMTV et sur les plus gros magazines d’infos',
    priceContent: '',
    cardImg: Actualité,
  },
];

const drawerWidth = 240;

export default function HomeContent(props) {
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [isLargeModalVisible, setIsLargeModalVisible] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState(-1);

  const closeModal = () => {
    setIsModalVisible(false);
    setIsLargeModalVisible(false);
  }

  const openModal = (id) => {
    setSelectedId(id);
    if (id === 3) {
      setIsLargeModalVisible(true);
    }
    else {
      setIsModalVisible(true);
    }
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <div>
            <img src={logo} alt="logo" className="appBarLogo"/>
            <Button onClick={() => window.open('https://www.videoclub-pub.fr/contact')} style={{position: "absolute", right: "350px", marginTop: "30px"}} color="inherit">Besoin d'aide ?</Button>
            <Button onClick={() => window.open('https://www.videoclub-pub.fr/')} style={{position: "absolute", right: "180px", marginTop: "30px"}} color="inherit">Démarrer en TV</Button>
            <Button onClick={() => props.disconnectUser()} style={{position: "absolute", right: "30px", marginTop: "30px"}} color="inherit">Déconnexion</Button>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }} style={{marginTop: "2em"}}>
          <List>
            {['Accueil', 'Mes campagnes', 'Nouvelle campagne', 'Mon Profil'].map((text, index) => (
              <div button key={text} className="homeMenuItemWrapper" onClick={() => props.displayPage(index)}>
                <ListItemIcon style={{justifyContent: "center"}}>
                  {
                    index  === 0 ? <WidgetsIcon className="homeMenuIcon" /> :
                    index  === 1 ? <DashboardIcon className="homeMenuIcon" /> :
                    index  === 2 ? <DashboardCustomizeIcon className="homeMenuIcon" /> :
                    <CoPresentIcon className="homeMenuIconSmall"/>
                  }
                </ListItemIcon>
                <span className="menuIconText">{text}</span>
              </div>
            ))}
          </List>
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <div style={{marginTop: "5em"}}>
          <div style={{display: "flex", justifyContent: "center", maxWidth: '1500px', flexDirection: "column", border: "1px solid lightGrey", padding: '20px'}}>
            <h1 style={{textAlign: "center", color: "#ff66c4"}}>Et si vous passiez à la TV ?</h1>
            <div style={{maxWidth: '900px', marginLeft: "auto", marginRight: "auto"}}>
              <p style={{textAlign: "center"}}>Bénéficiez de la notoriété des chaînes TV pour diffuser votre première campagne publicitaire et adresser précisément le bon message à la bonne cible.</p>
              <p style={{textAlign: "center"}}>Votre campagne publicitaire TV à partir de seulement <span className="bold" style={{color: '#ff66c4'}}>3 000 euros</span>.</p>
              <p style={{textAlign: "center"}}>Programmez et réservez en quelques clics votre campagne pub sur l’ensemble des chaines TV.</p>
            </div>
            <div>
              <h2 style={{marginBottom: "2em", textAlign: "center", textDecoration: "underline"}}>Votre publicité diffusée sur :</h2>
              <Carousel
                autoplay={true}
                autoplayInterval={1500}
                wrapAround={true}
                cellSpacing={50}
                adaptiveHeight={true}
                slidesToShow={4}
                pauseOnHover={false}
                style={{maxWidth: "900px", marginLeft: "auto", marginRight: "auto", height: "8em"}}
                defaultControlsConfig={{pagingDotsStyle: {display: 'none'}}}
                renderCenterLeftControls={({ previousSlide }) => (<button onClick={previousSlide}><ArrowBackIos className="displayNone" fontSize='large'/></button>)}
                renderCenterRightControls={({ nextSlide }) => (<button onClick={nextSlide}><ArrowForwardIos className="displayNone" fontSize='large'/></button>)}
              >
                <span className="homecarouselImgWrapper"><img className="homecarouselImg" alt="" src={TF1} style={{width: "8em"}}/></span>
                <span className="homecarouselImgWrapper"><img className="homecarouselImg" alt="" src={TMC} style={{width: "8em"}}/></span>
                <span className="homecarouselImgWrapper"><img className="homecarouselImg" alt="" src={TFX} style={{width: "8em"}}/></span>
                <span className="homecarouselImgWrapper"><img className="homecarouselImg" alt="" src={TF1Series} style={{width: "11em"}}/></span>
                <span className="homecarouselImgWrapper"><img className="homecarouselImg" alt="" src={M6} style={{width: "6em"}}/></span>
                <span className="homecarouselImgWrapper"><img className="homecarouselImg" alt="" src={W9} style={{width: "7.5em"}}/></span>
                <span className="homecarouselImgWrapper"><img className="homecarouselImg" alt="" src={TER} style={{width: "9em"}}/></span>
                <span className="homecarouselImgWrapper"><img className="homecarouselImg" alt="" src={Canal} style={{width: "12em"}}/></span>
                <span className="homecarouselImgWrapper"><img className="homecarouselImg" alt="" src={C8} style={{width: "7em"}}/></span>
                <span className="homecarouselImgWrapper"><img className="homecarouselImg" alt="" src={CStar} style={{width: "11.3em"}}/></span>
                <span className="homecarouselImgWrapper"><img className="homecarouselImg" alt="" src={BFM} style={{width: "5em"}}/></span>
                <span className="homecarouselImgWrapper"><img className="homecarouselImg" alt="" src={RMC} style={{width: "7em"}}/></span>
                <span className="homecarouselImgWrapper"><img className="homecarouselImg" alt="" src={RMC2} style={{width: "7em"}}/></span>
                <span className="homecarouselImgWrapper"><img className="homecarouselImg" alt="" src={Equipe} style={{width: "10em"}}/></span>
                <span className="homecarouselImgWrapper"><img className="homecarouselImg" alt="" src={Bein} style={{width: "8em"}}/></span>
              </Carousel>
            </div>
          </div>
          <div style={{display: "flex", flexDirection: "column", marginTop: "0.5em", maxWidth: "1500px", border: "1px solid lightGrey", padding: '20px'}}>
            <div style={{display: "flex", flexDirection: "row", gap: "20px", justifyContent: "space-around"}}>
              <div style={{width: "33%"}}>
                <p className="bold" style={{fontSize: '1.5em'}}>Nos offres en pack</p>
                <Card onCardClick={openModal} id={0} cardWidth='100%' cardTitle={cardsInfo[0].cardTitle} cardContent1={cardsInfo[0].cardContent1} cardContent2={cardsInfo[0].cardContent2} priceContent={cardsInfo[0].priceContent} cardImg={cardsInfo[0].cardImg}/>
              </div>
              <div style={{width: "33%"}}>
                <div style={{marginTop: "5.1em"}}><Card onCardClick={openModal} id={1} cardWidth='100%' cardTitle={cardsInfo[1].cardTitle} cardContent1={cardsInfo[1].cardContent1} cardContent2={cardsInfo[1].cardContent2} priceContent={cardsInfo[1].priceContent} cardImg={cardsInfo[1].cardImg}/></div>
              </div>
              <div style={{width: "33%"}}>
                <div style={{marginTop: "5.1em"}}><Card onCardClick={openModal} id={2} cardWidth='100%' cardTitle={cardsInfo[2].cardTitle} cardContent1={cardsInfo[2].cardContent1} cardContent2={cardsInfo[2].cardContent2} priceContent={cardsInfo[2].priceContent} cardImg={cardsInfo[2].cardImg}/></div>
              </div>
            </div>
            <div style={{display: "flex", flexDirection: "row", gap: "20px", justifyContent: "space-around", marginTop: "1em", marginBottom: "1em"}}>
              <div style={{width: "33%"}}>
                <Card onCardClick={openModal} cardWidth='100%' id={3} cardTitle={cardsInfo[3].cardTitle} cardContent1={cardsInfo[3].cardContent1} cardContent2={cardsInfo[3].cardContent2} priceContent={cardsInfo[3].priceContent} cardImg={cardsInfo[3].cardImg}/>
              </div>
              <div style={{width: "33%"}}>
                <Card onCardClick={openModal} cardWidth='100%' id={4} cardTitle={cardsInfo[4].cardTitle} cardContent1={cardsInfo[4].cardContent1} cardContent2={cardsInfo[4].cardContent2} priceContent={cardsInfo[4].priceContent} cardImg={cardsInfo[4].cardImg}/>
              </div>
              <div style={{width: "33%"}}>
                <Card onCardClick={openModal} cardWidth='100%' id={5} cardTitle={cardsInfo[5].cardTitle} cardContent1={cardsInfo[5].cardContent1} cardContent2={cardsInfo[5].cardContent2} priceContent={cardsInfo[5].priceContent} cardImg={cardsInfo[5].cardImg}/>
              </div>
            </div>
            <div style={{display: "flex", flexDirection: "row", gap: "20px", justifyContent: "space-around"}}>
              <div style={{width: "33%"}}>
              </div>
              <div style={{width: "33%"}}>
                <Card onCardClick={openModal} id={6} cardWidth='100%' cardTitle={cardsInfo[6].cardTitle} cardContent1={cardsInfo[6].cardContent1} cardContent2={cardsInfo[6].cardContent2} priceContent={cardsInfo[6].priceContent} cardImg={cardsInfo[6].cardImg}/>
              </div>
              <div style={{width: "33%"}}>
              </div>
            </div>
          </div>
          <div id="homeCreateNewCampaignCta" className="pinkButton" onClick={() => props.displayPage(2)}>
            <p id="homeCreateNewCampaignCtaText">Créer votre campagne pub TV personnalisée</p>
          </div>
        </div>
      </Box>
      <Modal
        handleClose={() => closeModal()}
        open={isModalVisible}
        selectedId={selectedId}
        cardsInfo={cardsInfo[selectedId]}
      />
      <LargeModal
        handleClose={() => closeModal()}
        open={isLargeModalVisible}
        selectedId={selectedId}
        cardsInfo={cardsInfo[selectedId]}
      />
    </Box>
  );
}
