import React, { useEffect, useState  } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import SelectSpotDuration from '../selects/newCampaignSpotTvDurationSelect';
import SelectSpotDurationBlue from '../selects/newCampaignSpotTvDurationSelectBlue';
import OneLineCard from '../cards/newCampainCardsOneLine';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Logo1 from '../../assets/logo/logo1.jpg';
import Logo2 from '../../assets/logo/logo2.jpg';
import Logo3 from '../../assets/logo/logo3.jpg';
import HelpIcon from '@mui/icons-material/HelpOutlineOutlined';
import Carousel from 'nuka-carousel';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import Img1 from "../../assets/img/1.png";
import Img2 from "../../assets/img/2.png";
import Img3 from "../../assets/img/3.png";
import Img4 from "../../assets/img/4.png";
import Img5 from "../../assets/img/5.png";
import Modal from '../modals/videoCarouselModal.js';

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

function secondCard(props) {
  return(
    props.spotTvAlreadyAvailable === 0
    ?
    <React.Fragment>
      <CardContent>
        <span className="cardText">
          Informations sur votre vidéo
        </span>
        <Box component="form" noValidate autoComplete="off">
          <div style={{marginTop: "2em"}}>
            <p className="bold">Indiquez la durée de votre spot TV</p>
            <SelectSpotDurationBlue {...props} onChange={props.updateState}/>
          </div>
          <CustomWidthTooltip followCursor={true} title="Le pub ID est le numéro d’identification de votre spot délivré par l’ARPP après validation et conformité des règles de diffusion à respecter." placement="bottom">
            <div style={{marginTop: "2em"}}>
              <p className="bold" style={{marginTop: "1.5em"}}>Indiquez le pub ID</p>
              <TextField label="Pub ID" variant="outlined" style={{width: "100%"}} onChange={(e) => props.updateState('pubId', e.target.value)} value={props.spotId}/>
            </div>
          </CustomWidthTooltip>
          <div style={{width: "94%"}}>
            <p style={{marginTop: "2em", fontStyle: "italic", paddingLeft: "10px"}}>Notre équipe se chargera de vérifier si votre vidéo répond bien aux normes à respecter pour sa diffusion. Dans le cas contraire, nos équipes vous contacteront afin de vous accompagner sur les modifications à apporter.</p>
          </div>
        </Box>
      </CardContent>
    </React.Fragment>
    :
    props.spotTvAlreadyAvailable === 1
    ?
    <React.Fragment>
      <CardContent>
        <span className="cardText">
          Notre accompagnement
        </span>
        <Box component="form" noValidate autoComplete="off">
          <div className="containerz">
            <ul style={{marginTop: "1.5em", marginLeft: '-30px'}}>
              <li style={{display: 'flex'}}>
                <span style={{width: '20px', marginTop: "auto", marginBottom: "auto"}}>○</span><span style={{marginTop: "auto", marginBottom: "auto", color: "#ff66c4"}}>Conseil et Conception</span>
                  <span className='infoIcon'>
                    <Tooltip title="Écriture et scénarisation de votre spot TV" placement='right'>
                      <HelpIcon fontSize='small'/>
                    </Tooltip>
                </span>
              </li>
              <li style={{display: 'flex'}}>
                <span style={{width: '20px', marginTop: "1em", marginBottom: '1em'}}>○</span> <span style={{marginTop: "auto", marginBottom: "auto", color: "#ff66c4"}}>Réalisation - Production et Post Production</span>
                  <span className='infoIcon' style={{marginTop: '1em', marginBottom: '1em'}}>
                    <Tooltip title={<span><p>Réception de vos contenus (images, visuels et/ou vidéos) ou Achat d’art (image de stock) ou journée(s) tournage(s)</p><p>Habillage - Montage – Motion design</p><p>Voix off et musique</p></span>} placement='right'>
                      <HelpIcon fontSize='small'/>
                    </Tooltip>
                  </span>
              </li>
              <li style={{display: 'flex'}}>
                <span style={{width: '20px', marginTop: "auto", marginBottom: "auto"}}>○</span> <span style={{marginTop: "auto", marginBottom: "auto", color: "#ff66c4"}}>Diffusion et Médiatisation</span>
                  <span className='infoIcon'>
                    <Tooltip title="Frais administratifs de validité ARPP et livraison de votre spot aux chaînes TV" placement='right'>
                      <HelpIcon fontSize='small'/>
                    </Tooltip>
                </span>
              </li>
            </ul>
          </div>
          <div style={{display: "flex", flexDirection: "column", marginLeft: '10px'}}>
            <p>Vous souhaitez avoir des informations sur notre accompagnement ?</p>
            <Button className="pinkButton" onClick={() => window.open('https://www.videoclub-pub.fr/contact')} style={{width: "250px"}}><span style={{fontSize: "0.9em"}}>Nous contacter</span></Button>
          </div>
          <div style={{marginTop: "2em", marginLeft: '10px'}}>
            <p className="bold">Indiquez la durée envisagée de votre spot TV</p>
            <SelectSpotDuration {...props} onChange={props.updateState}/>
          </div>
        </Box>
      </CardContent>
    </React.Fragment>
    :
    null
  );
}

function ThirdCard(props) {
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [videoId, setVideoId] = React.useState(0);

  function setVideoAndOpenModal(id) {
    setVideoId(id);
    setIsModalVisible(true);
  }

  const closeModal = () => {
    setIsModalVisible(false);
  }

  const openModal = () => {

  }

  return(
    props.spotTvAlreadyAvailable === 1
    ?
    <React.Fragment>
      <CardContent>
        <span className="cardText">
          Nos productions
        </span>
        <Box component="" noValidate autoComplete="off">
          <div style={{marginTop: "2em", marginLeft: '10px'}}>
            <p className="bold">Nous adaptons la production de votre spot TV en fonction de votre budget de communication</p>
            <p style={{fontStyle: "italic"}}>Votre spot TV à partir de 2 000 euros HT</p>
          </div>
          <div style={{marginTop: "3em", marginLeft: 'auto', marginRight: 'auto', minWidth: '750px', maxWidth: '1500px'}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <div className="step3CarouselDiv">
                <p className="bold" style={{textAlign: 'center', fontSize: '1em'}}>Budget Spot TV</p>
                <p className="bold" style={{textAlign: 'center', fontSize: '1em', marginTop: "-0.5em"}}>d'environ 2 000 euros HT</p>
                <Carousel style={{marginBottom: "1.5em"}} defaultControlsConfig={{pagingDotsStyle: {display: 'none'}}}
                  renderCenterLeftControls={({ previousSlide }) => (
                    <button onClick={previousSlide}><ArrowBackIos className="carouselIcon" fontSize='large'/></button>
                  )}
                  renderCenterRightControls={({ nextSlide }) => (
                    <button onClick={nextSlide}><ArrowForwardIos className="carouselIcon" fontSize='large'/></button>
                  )}
                >
                  <img src={Img1} alt='video 1' onClick={() => setVideoAndOpenModal(1)} style={{cursor: "pointer"}}/>
                  <img src={Img2} alt='video 2' onClick={() => setVideoAndOpenModal(2)} style={{cursor: "pointer"}}/>
                  <img src={Img3} alt='video 3' onClick={() => setVideoAndOpenModal(3)} style={{cursor: "pointer"}}/>
                </Carousel>
              </div>
              <div className="step3CarouselDiv">
                <p className="bold" style={{textAlign: 'center', fontSize: '1em'}}>Budget Spot TV</p>
                <p className="bold" style={{textAlign: 'center', fontSize: '1em', marginTop: "-0.5em"}}>d'environ 4 000 euros HT</p>
                <Carousel style={{marginBottom: "1.5em"}} defaultControlsConfig={{pagingDotsStyle: {display: 'none'}}}
                  renderCenterLeftControls={({ previousSlide }) => (
                    <button onClick={previousSlide}><ArrowBackIos className="carouselIcon" fontSize='large'/></button>
                  )}
                  renderCenterRightControls={({ nextSlide }) => (
                    <button onClick={nextSlide}><ArrowForwardIos className="carouselIcon" fontSize='large'/></button>
                  )}
                >
                  <img src={Img4} alt='video 4' onClick={() => setVideoAndOpenModal(4)} style={{cursor: "pointer"}}/>
                  <img src={Img5} alt='video 5' onClick={() => setVideoAndOpenModal(5)} style={{cursor: "pointer"}}/>
                </Carousel>
              </div>
            </div>
            <div style={{marginTop: '3.5em'}}>
              <p className='bold' style={{paddingLeft: '10px', fontSize: '1.1em', marginBottom: '2em', textDecoration: "underline", textAlign: "center"}}>Nos partenaires</p>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap'}}>
                <div >
                  <img style={{width: '4.5em', height: '4.5em'}} src={Logo1} alt='logo partenaire 1' />
                </div>
                <div style={{marginRight: '8%', marginLeft: '8%'}}>
                  <img style={{width: '4em', height: '4em'}} src={Logo2} alt='logo partenaire 2' />
                </div>
                <div >
                  <img style={{width: '4em', height: '4em'}} src={Logo3} alt='logo partenaire 3' />
                </div>
              </div>
            </div>
            <Modal
              handleClose={closeModal}
              open={isModalVisible}
              videoId={videoId}
            />
          </div>
        </Box>
      </CardContent>
    </React.Fragment>
    :
    null
  );
}

function cardSpotTv(props) {
  return(
    <React.Fragment>
      <CardContent>
        <span className="cardText">
          Votre spot TV / Vidéo
        </span>
        <Box component="form" noValidate autoComplete="off">
          <div style={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap", marginTop: "1.5em"}}>
            <div style={{width: "33%", minWidth: "15em"}}>
              <OneLineCard isCardPink={true} {...props} id={0} onChange={props.handleSpotTv} value={props.spotTvAlreadyAvailable === 0 ? true : false} cardText="J'ai déjà un spot TV" tooltipText=""/>
            </div>
            <div style={{width: "33%", minWidth: "15em"}}>
              <OneLineCard isCardPink={true} {...props} id={1} onChange={props.handleSpotTv} value={props.spotTvAlreadyAvailable === 1 ? true : false} cardText="Je n'ai pas de spot TV" tooltipText=""/>
            </div>
            <div style={{width: "33%"}}>
            </div>
            <div style={{width: "100%", marginTop: "1em", marginLeft: "10px"}}>
              {
                props.spotTvAlreadyAvailable === 0
                ?
                  <div>
                    <p>A faire <span className="bold">obligatoirement</span> avant la diffusion de votre campagne :</p>
                    <ul>
                      <li style={{marginBottom: "0.5em"}}>Faire valider votre spot à l’ARPP (Autorité de Régulation Professionnelle de la Publicité)</li>
                      <li>Envoi et livraison de votre spot aux régies publicitaires via les plateformes <a href="https://www.peach.me/fr-fr/" target="_blank" rel="noopener noreferrer"><span className="bold">Peach.me</span></a> ou <a href="https://www.adstream.com/fr/" target="_blank" rel="noopener noreferrer"><span className="bold">Adstream.com</span></a> au moins <span className="bold">7 jours</span> avant le début de la campagne</li>
                    </ul>
                  </div>
                :
                props.spotTvAlreadyAvailable === 1
                ?
                  <div>
                    <p><span className="bold">Pas de problème</span>, on s’occupe de tout !</p>
                    <p>Nous vous accompagnons de la réalisation de votre spot TV jusqu’à sa diffusion.</p>
                  </div>
                :
                null
              }
            </div>
          </div>
        </Box>
      </CardContent>
    </React.Fragment>
  );
}

function Step3(props) {

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);


  return (
    <div>
      <Box sx={{ minWidth: 275 }} style={{marginBottom: "1em"}}>
        <Card id="step3TvSpot" {...props} variant="outlined">{cardSpotTv(props)}</Card>
      </Box>
      <Box sx={{ minWidth: 275 }} style={{marginBottom: "1em"}}>
        <Card id='step3SpotDuration' {...props} variant="outlined">{secondCard(props)}</Card>
      </Box>
      <Box sx={{ minWidth: 275 }} style={{marginBottom: "1em"}}>
        <Card {...props}  variant="outlined">{ThirdCard(props)}</Card>
      </Box>
      <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
        <Button variant="white" onClick={() => props.goPrevStep()}>Étape précédente</Button>
        <Button variant="white" onClick={() => props.goNextStep()}>Étape suivante</Button>
      </div>
    </div>
  );
}

export default Step3;
