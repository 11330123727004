import React from 'react';
import {withRouter} from '../services/withRouter.js';
import Content from '../components/pageContent/homeContent';

import "../css/app.css";

class Campaign extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPageLoading: true,
    };
  }

  componentDidMount() {
    this.setState({isPageLoading: false});
    /*const token = LocalStorage.getToken();
    if (token === null) {
      this.props.navigate('/connexion');
    }*/
  }

  displayPage = (index) => {
    switch(index) {
      case 0:
        this.props.navigate('/accueil');
        break;
      case 1:
        this.props.navigate('/mes-campages');
        break;
      case 2:
        this.props.navigate('/nouvelle-campagne');
        break;
      case 3:
        this.props.navigate('/profil');
        break;
      default:
        return;
    }
  }

  render() {
    return(
        this.state.isPageLoading ? null :
        <div className="">
          <Content disconnectUser={this.props.disconnectUser} displayPage={this.displayPage}/>
        </div>
    );
  }
}

export default withRouter(Campaign);
