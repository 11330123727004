import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function FullAddrAutocompleteTextfield(props) {
  return (
    <div>
      <Autocomplete
        className="updatePersoInfoTextfield"
        defaultValue={props.value}
        disablePortal
        noOptionsText="Pas de résultat"
        options={props.fullAddrAutocomplete}
        onChange={(e) => props.selectAddr(e.target.innerText)}
        style={{width: "100%"}}
        renderInput={(params) => <TextField {...params} className="registerTextfield" label={props.placeholder} variant="outlined" value={props.value} onChange={(e) => {props.updateState(props.stateName, e.target.value)}}/> }
      />
    </div>
  );
}
