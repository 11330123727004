import React, { useState, useEffect  } from 'react';
import { format } from "date-fns";
import { fr } from 'date-fns/locale';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import Button from '@mui/material/Button';
import WidgetsIcon from '@mui/icons-material/Widgets';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import Stepper from '../steppers/newCampaignStepper';
import StepperHandler from '../steppers/StepperHandler';
//import VideoHandler from '../videosHandler/newCampainVideoHandler';
import * as ApiAddr from '../../services/apiAdress.js';
import SavedOrderModal from '../modals/orderSavedModal';
import Modal from '../modals/informationModal';
import CmdModal from '../modals/cmdModal';
import * as LocalStorage from '../../services/localStorage.js';
import * as SessionSorage from '../../services/sessionStorage';
import * as QuotationApi from '../../services/quotationApi.js';
import Alert from '../alerts/newCampaignAlerts'

import logo from '../../assets/videoclub.png';
const drawerWidth = 240;
const maxStep = 5;
let timer = null;

//step 1 interest array
/*const interestArray = [
  {text: "Bricolage, Jardinage, Décoration", description: "individus particulièrement intéréssés par l'univers du bricolage, du jardinage et de la décoration. Ils sont à la pointe des dernières tendances, aiment créer et aménager leur intérieur et extérieur par eux même"},
  {text: "Santé, Bien être", description: "individus particulièrement attentifs à leur santé et à leur consommation pour accroître un bien être en phase avec leurs valeurs"},
  {text: "Mode, Beauté", description: "individus qui portent un intérêt tout particulier à la mode et à la beauté"},
  {text: "Énvironnement, Écologie", description: "Individus qui portent un intérêt tout particulier aux sujets environnementaux et écologiques"},
  {text: "Évasion, Voyage", description: "individus particulièrement intéréssés l'univers du voyage de l'évasion et de la découverte"},
  {text: "Cuisine", description: "Individus passionnés par l'univers de la cuisine. Ils consomment des contenus culinaires et aiment reproduire les recettes qu'ils y trouvent"},
  {text: "Automobile", description: "Individus qui surconsomment des contenus en lien avec l'automobile. Ils sont à l'affut des nouveautés et actualités des marques et constructeurs"},
  {text: "Sport", description: "individus fan de sport qui surconsomment des programmes sportifs. Ils ne ratent aucune rencontre sportive, aiment se renseigner sur équipe/club préféré et se tiennent au courant de l'actualité sportive"},
  {text: "Football ou Rugby", description: "Individus fans de football ou de rugby, particulièrement intéréssés par les matchs, les grandes compétitions et les magazines dédiés"},
  {text: "Séries", description: "Individus passionnés par les séries et les fictions"},
  {text: "Divertissement, Comédie", description: "individus passionnés par l'univers de la comédie. Ils consomment beaucoup de série, d'émissions et divertissements pour se détendre et rire en famille"},
  {text: "Hight tech addict", description: "Individus passionnés et particulièrement intéréssés par les produits hight tech, les nouvelles technologies"},
];*/

//ste1 housingType array
/*const housingTypeArray = [
  {text: "Maison", description: "Individus vivants en maison"},
  {text: "Appartement", description: "Individus vivants en appartement"},
  {text: "Propriétaire", description: "Individus étant propriétaire de leur logement"},
  {text: "Locataire", description: "Individus étant locataire de leur logement"},
  {text: "Propriétaire maison", description: "Individus étant propriétaire d'une maison"},
  {text: "Propriétaire appartement", description: "Individus étant propriétaire d'un appartement"},
  {text: "Locataire maison", description: "Individus étant locataire d'une maison"},
  {text: "Locataire appartement", description: "Individus étant locataire d'un appartement"},
];*/

//setp1 Data
/*const dataArray = [
  {text: "Shoppers", description: "Acheteur de produits alimentaires, grande distribution, fashion addict"},
  {text: "Acheteurs(es) commerce", description: "FMCG, généraliste, data Amazon"},
  {text: "Intentionniste selon le moment de vie", description: "Souscription assurence/banque, achat automobile, FAI/télécom, déménagement"},
  {text: "Cible selon le style de vie", description: "Made in France, fait maison, gamers"},
];*/

//step1 geolife
/*const geolifeArray = [
  {text: "Pavillon familial aisé", description: "Familles actives avec enfants, ils sont souvent cadres ou de professions intermédiaires avec très bon niveau de vie. Propriétaire de grandes sufaces, ils habitent plutôt dans des quartiers périphériques pavillonaires"},
  {text: "Périhurbain en croissance", description: "Familles avec enfants, ils sont souvent de professions intermédiaires et vivent dans des logements récents en quartier périphérique"},
  {text: "Populaire", description: "Profil familials ou jeunes, ils sont souvent locataires, vivant dans de petite surfaces et CSP-"},
  {text: "Résidence secondaire", description: "Retraités, artisans ou indépendants, le logement secondaire peut être une maison ou un appartement, souvent dans des stations de ski, stations balnéaires ou tourisme vert"},
  {text: "Rural dynamique", description: "Familles actives avec enfants, ils sont propriétaires de grandes surfaces, dans des logements plutôt récents en ruralité mais proche des grandes agglomérations"},
  {text: "Rural ouvrier", description: "CSP ouvrier, ils sont souvent propriétaires de logements anciens et vivent dans des anciens bassins industriels"},
  {text: "Rural traditionnel", description: "Agriculteurs, ils sont souvent propriétaires de grandes maisons anciennes au sein de petits villages reculés avec peu de commerce"},
  {text: "Urbain classe moyenne", description: "Profil jeunes couples, actifs ou encore étudiants, ils vivent en appartement dans de petites surfaces. Ils préférent les centres villes et les quartiers attractifs et dynamiques"},
  {text: "Urbain dynamique", description: "Profil jeune cadre ou étudiant, l'urbain dynamique habite la plupart du temps en appartement dans un centre ville animé et cosmopolite"},
  {text: "Urbain familial aisé", description: "Familles actives avec enfants, il sont souvent cadres à hauts revenus et propriétaires. Ils habitent en centre ville ou dans des quartiers plutôt résidentiels"},
];*/

//step1 consoTV
/*const consoTvArray = [
  {text: "Petit conso TV", description: "Individus qui consomment moins d'une heure de télévision par jour. Considéré comme rares et difficiles à atteindre, ils sont une cible particulièrement tactique"},
  {text: "Moyen conso TV", description: "Individus qui consomment environ 3 heures de télévision par jour. Ils ont un profil 'classique' et correspondent à la plupart des foyers français"},
  {text: "Gros conso TV", description: "Individus qui consomment plus de 7 heures de télévision par jour. Ils sont de véritables fan de télévision"},
  {text: "Consommateur VOD/SVOD", description: "Individus qui surconsomment chaque mois des contenus VOD/SVOD. Il sont abonnés à plusieurs plateformes de séries"},
];*/

//step1 moment marketing
/*const momentMarketingArray = [
  {text: "Météo", description: "Activer votre publicité sur des critères météorologiques (pluie, neige, chaleurs, canicule)"},
  {text: "Pic de pollution", description: "Activer votre publicité selon les conditions de pollution"},
  {text: "Seuil épidémique", description: "Activer votre publicité selon des critères épidémiques"},
];*/

//step2 campainType
/*const campainTypeArray = [
  {text: "TV", description: "Non disponible si budget inférieur à 5000€"},
  {text: "Replay", description: ""},
  {text: "TV + Replay", description: "Non disponible si budget inférieur à 8000€"},
];*/

/*const communicationObjectiveArray = [
  {id: 1, name: 'Visibilité et Notoriété'},
  {id: 2, name: 'Trafic digital'},
  {id: 3, name: 'Trafic physique'},
  {id: 4, name: 'Conversion'},
];*/

/*const geoAreaArray = [
  {id: 1, name: 'France entière'},
  {id: 2, name: 'Région(s)'},
  {id: 3, name: 'Département(s)'},
  {id: 4, name: 'Ville(s)'},
  {id: 5, name: 'Sur mesure'},
];*/

/*const campaignTypeArray = [
  {id: 1, name: 'Replay'},
  {id: 2, name: 'TV'},
  {id: 3, name: 'TV + Replay'},
];*/

/*const targetSexArray = [
  {id: 1, name: 'Homme'},
  {id: 2, name: 'Femme'},
  {id: 3, name: 'Mixte'},
];*/

/*const targetSexAgeArray = [
  {id: 1, name: '15 ans et plus'},
  {id: 2, name: '15 à 24 ans'},
  {id: 3, name: '15 à 34 ans'},
  {id: 4, name: '15 à 49 ans'},
  {id: 5, name: '15 à 59 ans'},
  {id: 6, name: '25 à 34 ans'},
  {id: 7, name: '25 à 49 ans'},
  {id: 8, name: '25 à 59 ans'},
  {id: 9, name: '35 à 49 ans'},
  {id: 10, name: '35 à 59 ans'},
  {id: 11, name: '50 ans et plus'},
];*/

/*const targetHouseholdCompositionArray = [
  {id: 1, name: 'Célibataire'},
  {id: 2, name: 'Famille avec enfant(s)'},
  {id: 3, name: 'Famille sans enfant(s)'},
];*/

/*const targetCPSArray = [
  {id: 1, name: 'CSP++'},
  {id: 2, name: 'CSP+'},
  {id: 3, name: 'CSP-'},
  {id: 4, name: 'Haut Revenus'},
];*/

/*const targetHousingTypeArray = [
  {id: 1, name: 'Maison'},
  {id: 2, name: 'Appartement'},
  {id: 3, name: 'Propriétaire'},
  {id: 4, name: 'Locataire'},
  {id: 5, name: 'Propriétaire maison'},
  {id: 6, name: 'Propriétaire appartement'},
  {id: 7, name: 'Locataire maison'},
  {id: 8, name: 'Locataire appartement'},
];*/

/*const targetInterestArray = [
  {id: 1, name: 'Bricolage, Jardinage, Décoration'},
  {id: 2, name: 'Santé, bien être'},
  {id: 3, name: 'Beauté'},
  {id: 4, name: 'Environnement, Écologie'},
  {id: 5, name: 'Évasion, voyage'},
  {id: 6, name: 'Cuisine'},
  {id: 7, name: 'Automobile'},
  {id: 8, name: 'Sport'},
  {id: 9, name: 'Football ou Rugby'},
  {id: 10, name: 'Séries'},
  {id: 11, name: 'Divertissement, Comédie'},
  {id: 12, name: 'Hight tech addict'},
];*/

/*const targetDataArray = [
  {id: 1, name: 'Shoppers'},
  {id: 2, name: 'Acheteurs(es) commerce'},
  {id: 3, name: 'Intentionniste selon le moment de vie'},
  {id: 4, name: 'Cible selon le style de vie'},
];*/

/*const targetGeolifeArray = [
  {id: 1, name: 'Pavillon familial aisé'},
  {id: 2, name: 'Périhurbain en croissance'},
  {id: 3, name: 'Populaire'},
  {id: 4, name: 'Résidence secondaire'},
  {id: 5, name: 'Rural dynamique'},
  {id: 6, name: 'Rural ouvrier'},
  {id: 7, name: 'Rural traditionnel'},
  {id: 8, name: 'Urbain classe moyenne'},
  {id: 9, name: 'Urbain dynamique'},
  {id: 10, name: 'Urbain familial aisé'},
];*/

/*const targetConsoTVArray = [
  {id: 1, name: 'Petit conso TV'},
  {id: 2, name: 'Moyen conso TV'},
  {id: 3, name: 'Gros conso TV'},
  {id: 4, name: 'Consommateur VOD/SVOD'},
];*/

/*const targetMomentMarketingArray = [
  {id: 1, name: 'Météo'},
  {id: 2, name: 'Pic de pollution'},
  {id: 3, name: 'Seuil épidémique'},
];*/

export default function NewCampaignContent(props) {
  useEffect(() => {
    getConstants();
  }, []);

  //used to scroll on region select
  useEffect(() => {
    if (regionArea === true && nbOfRegionSelected === -1) {
      document.getElementById("regionText").scrollIntoView({ behavior: 'smooth'});
    }
  }, regionArea);

  //used to scroll on dept select
  useEffect(() => {
    if (departmentArea === true && nbOfDepartmentSelected === -1) {
      document.getElementById("departementText").scrollIntoView({ behavior: 'smooth'});
    }
  }, departmentArea);

  //current step
  const [currentStep, setCurrentStep] = useState(0);
  //tootltip error
  const [tooltipNextOpen, setTooltipNextOpen] = useState(false);
  //saved order modal
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);
  const [isCmdModalVisible, setIsCmdModalVisible] = useState(false);

  //step 0 commecial name
  const [commercialName, setCommercialName] = React.useState('');
  const [displayCommercialName, setDisplayCommercialName] = React.useState(false);

  //step0 product name
  const [productName, setProductName] = React.useState('');
  const [displayProductName, setDisplayProductName] = React.useState(false);

  //steo 0 campain objective
  const [objectifValue1, setObjectifValue1] = React.useState(false);
  const [objectifValue2, setObjectifValue2] = React.useState(false);
  const [objectifValue3, setObjectifValue3] = React.useState(false);
  const [objectifValue4, setObjectifValue4] = React.useState(false);
  const [displayGoal, setDisplayGoal] = React.useState(false);
  const [goalName, setGoalName] = React.useState('');

  //step1 target
  const [targetValue0, setTargetValue0] = React.useState(false);
  const [targetValue1, setTargetValue1] = React.useState(false);
  const [targetValue2, setTargetValue2] = React.useState(false);
  const [targetValue3, setTargetValue3] = React.useState(false);
  const [targetValue4, setTargetValue4] = React.useState(false);
  const [targetValue5, setTargetValue5] = React.useState(false);
  const [targetValue6, setTargetValue6] = React.useState(false);
  const [targetValue7, setTargetValue7] = React.useState(false);
  const [targetValue8, setTargetValue8] = React.useState(false);
  const [displayTarget, setDisplayTarget] = React.useState(false);
  const [targetIsOpen, setTargetIsOpen] = React.useState(-1);
  const [targetName, setTargetName] = React.useState([]);

  //set1 household Composition
  const [householdCompo0, setHouseholdCompo0] = React.useState(false);
  const [householdCompo1, setHouseholdCompo1] = React.useState(false);
  const [householdCompo2, setHouseholdCompo2] = React.useState(false);
  const [householdCompoName, setHouseholdCompoName] = React.useState('');
  const [displayHouseholdCompo, setDisplayHouseholdCompo] = React.useState(false);

  //set1 CSP
  const [csp0, setCsp0] = React.useState(false);
  const [csp1, setCsp1] = React.useState(false);
  const [csp2, setCsp2] = React.useState(false);
  const [csp3, setCsp3] = React.useState(false);
  const [cspName, setCspName] = React.useState('');
  const [displayCsp, setDisplayCsp] = React.useState(false);

  //set1 target sex
  const [targetSex0, setTargetSex0] = React.useState(false);
  const [targetSex1, setTargetSex1] = React.useState(false);
  const [targetSex2, setTargetSex2] = React.useState(false);
  const [selectedAge, setSelectedAge] = React.useState(-1);
  const [selectedAgeText, setSelectedAgeText] = React.useState('');
  const [displayTargetSex, setDisplayTargetSex] = React.useState(false);
  const [targetSexText, setTargetSexText] = React.useState('');

  //set1 housingType
  const [housingType, setHousingType] = React.useState(-1);
  const [displayHousingType, setDisplayHousingType] = React.useState(false);
  const [housingTypeName, setHousingTypeName] = React.useState('');

  //step1 interest
  const [interest, setInterest] = React.useState(-1);
  const [interestName, setInterestName] = React.useState('');
  const [displayInterest, setDisplayInterest] = React.useState(false);

  //step 1 data
  const [data, setData] = React.useState(-1);
  const [dataName, setDataName] = React.useState('');
  const [displayData, setDisplayData] = React.useState(false);

  //ste1 geolife
  const [geolife, setGeolife] = React.useState(-1);
  const [geolifeName, setGeolifeName] = React.useState('');
  const [displayGeolife, setDisplayGeolife] = React.useState(false);

  //step1 consoTv
  const [consoTV, setConsoTV] = React.useState(-1);
  const [consoTVName, setConsoTVName] = React.useState('');
  const [displayConsoTV, setDisplayConsoTV] = React.useState(false);

  //step1 momentMarketing
  const [momentMarketing, setMomentMarketing] = React.useState(-1);
  const [momentMarketingName, setMomentMarketingName] = React.useState('');
  const [displayMomentMarking, setDisplayMomentMarking] = React.useState(false);

  //geographic area selected
  const [frenchArea, setFrenchArea] = React.useState(false);
  const [departmentArea, setDepartmentArea] = React.useState(false);
  const [regionArea, setRegionArea] = React.useState(false);
  const [cityArea, setCityArea] = React.useState(false);
  const [postalCodeArea, setPostalCodeArea] = React.useState(false);
  const [customArea, setCustomArea] = React.useState(false);

  //Campain recap text
  const [deptText, setDeptText] = React.useState('');
  const [regionText, setRegionText] = React.useState('');
  const [nbOfRegionSelected, setNbOfRegionSelected] = React.useState(-1);
  const [nbOfDepartmentSelected, setNbOfDepartmentSelected] = React.useState(-1);
  const [cityText, setCityText] = React.useState('');
  const [postalCodeText, setPostalCodeText] = React.useState('');

  //geographic area postal code
  const [selectedPostalCode, setSelectedPostalCode] = React.useState([]);
  const [postalCode, setPostalCode] = React.useState([]);

  //cities
  const [cities, setCities] = React.useState([]);
  const [selectedCities, setSelectedCities] = React.useState([]);
  const [searchCityTextfieldValue, setSearchCityTextfieldValue] = React.useState('');
  const [searchPostalCodeTextfieldValue, setSearchPostalCodeTextfieldValue] = React.useState('');

  //geographic area display & text
  const [geoAreaText, setGeoAreaText] = React.useState('');
  const [displayGeoArea, setDisplayGeoArea] = React.useState(false);
  const [geoAreaOption, setGeoAreaOption] = React.useState([]);

  //custom distance text
  const [customDistanceText, setCustomDistanceText] = React.useState('');
  const [customLocations, setCustomLocations] = React.useState([]);
  const [customLocationSearchText, setCustomLocationSearchText] = React.useState('');
  const [selectedCustomLocation, setSelectedCustomLocation] = React.useState('');
  const [customLocationType, setCustomLocationType] = React.useState(-1);

  //step2 capaign type
  const [campaignType, setCampainType] = React.useState(-1);
  const [campainTypeName, setCampainTypeName] = React.useState('');
  const [displayCampainType, setDisplayCampainType] = React.useState(false);

  //step2 budget
  const [budget, setBudget] = React.useState(-1);
  const [budgetText, setBudgetText] = React.useState(0);
  const [displayBudgetText, setDisplayBudgetText] = React.useState(false);

  //ste2 campaign date
  const [newCampainStartDate, setNewCampainStartDate] = useState(null);
  const [newCampainStopDate, setNewCampainStopDate] = useState(null);
  const [newCampainDateText, setNewCampainDateText] = React.useState('');
  const [displayCampainDate, setDisplayCampainDate] = React.useState(false);

  //step3 spotTv
  const [spotId, setSpotId] = React.useState('');
  const [spotDuration, setSpotDuration] = React.useState(-1);
  const [displaySpot, setDisplaySpot] = React.useState(false);
  const [spotTvAlreadyAvailable, setSpotTvAlreadyAvailable] = React.useState(-1);

  //validated cmd
  const [isCmdValidated, setIsCmdValidated] = React.useState(-1);
  const [isCmdLoading, setIsCmdLoading] = React.useState(false);
  const [validateCmdButtonDisabled, setValidateCmdButtonDisabled] = React.useState(false);

  //draft CMD id
  const [draftCmdId, setDraftCmdId] = React.useState(-1);
  const [geoAreaOptionsArray, setGeoAreaOptionsArray] = React.useState([]);
  const [isCmdLoaded, setIsCmdLoaded] = React.useState(false);
  const [startDate, setStartDate] = React.useState('');
  const [stopDate, setStopDate] = React.useState('');
  const [userMaxStep, setUserMaxStep] = React.useState(0);
  const [isDraftAlreadyCreated, setIsDraftAlreadyCreated] = React.useState(false);

  //handle Constants
  const [constants, setConstants] = React.useState([]);

  //function used to get constants from api
  async function getConstants() {
    const token = LocalStorage.getToken();
    const id = LocalStorage.getId();
    const data = {
      email: id,
      token: token,
    };
    const ret = await QuotationApi.getConstants(data);
    if (ret !== false) {
      setConstants(ret.data.constants);
      //console.log(ret.data.constants)
      //console.log(ret.data.constants['communicationObjectiveArray'])
    }
    const tmp = handleStartDisplayPage();
    updateStateWithLoadedData(tmp, ret.data.constants);
    resetSessionStorage();
  }

  //function used to get data if cmd is loaded from user CMD page
  function handleStartDisplayPage() {
    const commercialName = SessionSorage.getSessionField('commercialName');
    const productName = SessionSorage.getSessionField('productName');
    const communitationObjective = SessionSorage.getSessionField('communitationObjective');
    const geoArea = SessionSorage.getSessionField('geoArea');
    const geoAreaOptions = SessionSorage.getSessionField('geoAreaOptions');
    const targetInterest = SessionSorage.getSessionField('targetInterest');
    const targetHouseholdComposition = SessionSorage.getSessionField('targetHouseholdComposition');
    const targetCSP = SessionSorage.getSessionField('targetCSP');
    const targetHousingType = SessionSorage.getSessionField('targetHousingType');
    const targetData = SessionSorage.getSessionField('targetData');
    const targetGeolife = SessionSorage.getSessionField('targetGeolife');
    const targetConsoTV = SessionSorage.getSessionField('targetConsoTV');
    const targetMomentMarketing = SessionSorage.getSessionField('targetMomentMarketing');
    const targetSex = SessionSorage.getSessionField('targetSex');
    const targetSexAge = SessionSorage.getSessionField('targetSexAge');
    const budget = SessionSorage.getSessionField('budget');
    const campaignType = SessionSorage.getSessionField('campaignType');
    const startDate = SessionSorage.getSessionField('startDate');
    const stopDate = SessionSorage.getSessionField('stopDate');
    const cmdId = SessionSorage.getSessionField('cmdId');
    const spotTvAlreadyAvailable = SessionSorage.getSessionField('spotTvAlreadyAvailable');
    const spotDuration = SessionSorage.getSessionField('spotDuration');
    const spotId = SessionSorage.getSessionField('spotId');

    if (cmdId !== -1 && cmdId !== '-1') {
      let array = cmdId.split('-');
      setDraftCmdId(array[1]);
    }

    const data = {
      commercialName: commercialName,
      productName: productName,
      communitationObjective: communitationObjective,
      geoArea: geoArea,
      geoAreaOptions: geoAreaOptions,
      targetInterest: targetInterest,
      targetHouseholdComposition: targetHouseholdComposition,
      targetCSP: targetCSP,
      targetHousingType: targetHousingType,
      targetData: targetData,
      targetGeolife: targetGeolife,
      targetConsoTV: targetConsoTV,
      targetMomentMarketing: targetMomentMarketing,
      targetSex: targetSex,
      targetSexAge: targetSexAge,
      budget: budget,
      campaignType: campaignType,
      startDate: startDate,
      stopDate: stopDate,
      cmdId: cmdId,
      spotTvAlreadyAvailable: spotTvAlreadyAvailable,
      spotDuration: spotDuration,
      spotId: spotId
    };
    return data;
  }

  function resetSessionStorage() {
    SessionSorage.setSessionField('commercialName', null);
    SessionSorage.setSessionField('productName', null);
    SessionSorage.setSessionField('communitationObjective', null);
    SessionSorage.setSessionField('geoArea', null);
    SessionSorage.setSessionField('geoAreaOptions', null);
    SessionSorage.setSessionField('targetInterest', null);
    SessionSorage.setSessionField('targetHouseholdComposition', null);
    SessionSorage.setSessionField('targetCSP', null);
    SessionSorage.setSessionField('targetHousingType', null);
    SessionSorage.setSessionField('targetData', null);
    SessionSorage.setSessionField('targetGeolife', null);
    SessionSorage.setSessionField('targetConsoTV', null);
    SessionSorage.setSessionField('targetMomentMarketing', null);
    SessionSorage.setSessionField('targetSex', null);
    SessionSorage.setSessionField('targetSexAge', null);
    SessionSorage.setSessionField('budget', null);
    SessionSorage.setSessionField('campaignType', null);
    SessionSorage.setSessionField('startDate', null);
    SessionSorage.setSessionField('stopDate', null);
    SessionSorage.setSessionField('cmdId', null);
    SessionSorage.setSessionField('spotTvAlreadyAvailable', null);
    SessionSorage.setSessionField('spotDuration', null);
    SessionSorage.setSessionField('spotId', null);
  }

  function updateStateWithLoadedData(data, constantsArray) {
    if (data.commercialName !== null && data.commercialName !== 'null' && data.commercialName !== -1 && data.commercialName !== '-1') {
      setCommercialName(data.commercialName);
      setDisplayCommercialName(true);
      setIsCmdLoaded(true);
      setUserMaxStep(0);
      setCurrentStep(3);
    }
    if (data.productName !== null && data.productName !== 'null' && data.productName !== -1 && data.productName !== '-1' && data.productName !== "Non défini") {
      setProductName(data.productName);
      setDisplayProductName(true);
      setIsCmdLoaded(true);
      setUserMaxStep(0);
    }
    if (data.communitationObjective !== null && data.communitationObjective !== 'null' && data.communitationObjective !== -1 && data.communitationObjective !== '-1') {
      const selectedCommunicationObjective = getIdRelatedToValue(constantsArray['communicationObjectiveArray'], data.communitationObjective);
      switch (selectedCommunicationObjective) {
        case 1:
          setObjectifValue1(true);
          break;
        case 2:
          setObjectifValue2(true);
          break;
        case 3:
          setObjectifValue3(true);
          break;
        case 4:
          setObjectifValue4(true);
          break;
        default:
      }
      setGoalName(data.communitationObjective);
      setDisplayGoal(true);
      setIsCmdLoaded(true);
      setUserMaxStep(0);
    }
    if (data.geoArea!== null && data.geoArea !== 'null' && data.geoArea!== -1 && data.geoArea !== '-1') {
      const selectedGeoArea = getIdRelatedToValue(constantsArray['geoAreaArray'], data.geoArea);
      setGeoAreaText(data.geoArea);
      switch (selectedGeoArea) {
        case 1:
          setFrenchArea(true);
          break;
        case 2:
          setRegionArea(true);
          break;
        case 3:
          setDepartmentArea(true);
          break;
        case 4:
          setCityArea(true);
          setSelectedCities(data.geoAreaOptions.split(','));
          break;
        case 5:
          setCustomArea(true);
          break;
        default:
      }
      setDisplayGeoArea(true);
      setIsCmdLoaded(true);
      setUserMaxStep(1);
    }
    if (data.geoAreaOptions !== null && data.geoAreaOptions !== 'null' && data.geoAreaOptions !== -1 && data.geoAreaOptions !== '-1') {
      let optionsArray;

      //handle custom geo area
      if (getIdRelatedToValue(constantsArray['geoAreaArray'], data.geoArea) === 5) {
        //setCustomLocationSearchText(); PARis
        //setSelectedCuystomType(); KM
        //setCustomDistanceText(); Distance slider

        optionsArray = data.geoAreaOptions;
        optionsArray = optionsArray.replace('A moins de ', '');
        optionsArray = optionsArray.split(' ');
        //console.log('OPT ARRAY 3')
        //console.log(optionsArray)
        let location = null;
        for (let i = 3; i < optionsArray.length; i++) {
          if (location === null) {
            location = optionsArray[i] + ' ';
          }
          else {
            location = location + optionsArray[i] + ' ';
          }
        }
        //set location
        setCustomLocationSearchText(location);
        //set custom type
        if (optionsArray[1] === 'km') {
          setCustomLocationType(1);
        }
        else {
          setCustomLocationType(2);
        }
        //set distance slider
        setCustomDistanceText(optionsArray[0]);
        setGeoAreaOptionsArray(optionsArray);
        setIsCmdLoaded(true);
      }
      else {
        //handle regions
        if (getIdRelatedToValue(constantsArray['geoAreaArray'], data.geoArea) === 2) {
          optionsArray = data.geoAreaOptions.split(',');
          setNbOfRegionSelected(optionsArray.length);
          setGeoAreaOptionsArray(optionsArray);
        }
        //handle dept
        else if (getIdRelatedToValue(constantsArray['geoAreaArray'], data.geoArea) === 3) {
          optionsArray = data.geoAreaOptions.split(',');
          setNbOfDepartmentSelected(optionsArray.length);
          setGeoAreaOptionsArray(optionsArray);
        }
        else {
          optionsArray = data.geoAreaOptions.split(',');
          setNbOfRegionSelected(optionsArray.length);
          setGeoAreaOptionsArray(optionsArray);
        }
      }
      setGeoAreaText(data.geoArea + ' : ' + data.geoAreaOptions);
      setDisplayGeoArea(true);
      setIsCmdLoaded(true);
    }
    //target sex
    if (data.targetSex !== null && data.targetSex !== 'null' && data.targetSex !== -1 && data.targetSex !== '-1') {
      setTargetValue0(true);
      handleTargetCheckboxChange(0, true);
      setDisplayTargetSex(true);
      setTargetSexText(data.targetSex);
      let id = getIdRelatedToValue(constantsArray['targetSexArray'], data.targetSex) - 1;
      switch (id) {
        case 0:
          setTargetSex0(true);
          break;
        case 1:
          setTargetSex1(true);
          break;
        case 2:
          setTargetSex2(true);
          break;
        default:
      }
      setDisplayTargetSex(true);
      setIsCmdLoaded(true);
      if (data.targetSexAge !== null && data.targetSexAge !== 'null' && data.targetSexAge !== -1 && data.targetSexAge !== '-1') {
        if (getIdRelatedToValue(constantsArray['targetSexArray'], data.targetSex) - 1 === 0) {
          setTargetSexText("Hommes de " + data.targetSexAge);
        }
        if (getIdRelatedToValue(constantsArray['targetSexArray'], data.targetSex) - 1 === 1) {
          setTargetSexText("Femmes de " + data.targetSexAge);
        }
        else {
          setTargetSexText("Femmes et Hommes " + data.targetSexAge);
        }
        const id = getIdRelatedToValue(constantsArray['targetSexAgeArray'], data.targetSexAge);
        //alert('ID SEX')
        //alert(id)
        setSelectedAge(id);
        setSelectedAgeText(data.targetSexAge);
        setIsCmdLoaded(true);
      }
    }
    //target interest
    if (data.targetInterest !== null && data.targetInterest !== 'null' && data.targetInterest !== -1 && data.targetInterest !== '-1') {
      setTargetValue4(true);
      handleTargetCheckboxChange(4, true);
      setDisplayTarget(true);
      setInterestName(data.targetInterest);
      let id = getIdRelatedToValue(constantsArray['targetInterestArray'], data.targetInterest) - 1;
      setInterest(id);
      setDisplayInterest(true);
      setIsCmdLoaded(true);
    }
    //target householdCompo
    if (data.targetHouseholdComposition !== null && data.targetHouseholdComposition !== 'null' && data.targetHouseholdComposition !== -1 && data.targetHouseholdComposition !== '-1') {
      setTargetValue1(true);
      handleTargetCheckboxChange(1, true);
      setDisplayTarget(true);
      setHouseholdCompoName(data.targetHouseholdComposition);
      let id = getIdRelatedToValue(constantsArray['targetHouseholdComposition'], data.targetHouseholdComposition) - 1;
      switch (id) {
        case 0:
          setHouseholdCompo0(true);
          break;
        case 1:
          setHouseholdCompo1(true);
          break;
        case 2:
          setHouseholdCompo2(true);
          break;
        default:

      }
      setDisplayHouseholdCompo(true);
      setIsCmdLoaded(true);
    }
    //target CSP
    if (data.targetCSP !== null && data.targetCSP !== 'null' && data.targetCSP !== -1 && data.targetCSP !== '-1') {
      setTargetValue2(true);
      handleTargetCheckboxChange(2, true);
      setDisplayCsp(true);
      setCspName(data.targetCSP);
      let id = getIdRelatedToValue(constantsArray['targetCPSArray'], data.targetCSP) - 1;
      switch (id) {
        case 0:
          setCsp0(true);
          break;
        case 1:
          setCsp1(true);
          break;
        case 2:
          setCsp2(true);
          break;
        case 3:
          setCsp3(true);
          break;
        default:
      }
      setDisplayCsp(true);
      setIsCmdLoaded(true);
    }
    //target housing type
    if (data.targetHousingType !== null && data.targetHousingType !== 'null' && data.targetHousingType !== -1 && data.targetHousingType !== '-1') {
      setTargetValue3(true);
      handleTargetCheckboxChange(3, true);
      setHousingTypeName(data.targetHousingType);
      let id = getIdRelatedToValue(constantsArray['targetHousingTypeArray'], data.targetHousingType) - 1;
      setHousingType(id);
      setDisplayHousingType(true);
      setIsCmdLoaded(true);
    }
    //target data
    if (data.targetData !== null && data.targetData !== 'null' && data.targetData !== -1 && data.targetData !== '-1') {
      setTargetValue5(true);
      handleTargetCheckboxChange(5, true);
      setDataName(data.targetData);
      let id = getIdRelatedToValue(constantsArray['targetDataArray'], data.targetData) - 1;
      setData(id);
      setDisplayData(true);
      setIsCmdLoaded(true);
    }
    //target geolife
    if (data.targetGeolife !== null && data.targetGeolife !== 'null' && data.targetGeolife !== -1 && data.targetGeolife !== '-1') {
      setTargetValue6(true);
      handleTargetCheckboxChange(6, true);
      setGeolifeName(data.targetGeolife);
      let id = getIdRelatedToValue(constantsArray['targetGeolifeArray'], data.targetGeolife) - 1;
      setGeolife(id);
      setDisplayGeolife(true);
      setIsCmdLoaded(true);
    }
    //target conso tv
    if (data.targetConsoTV !== null && data.targetConsoTV !== 'null' && data.targetConsoTV !== -1 && data.targetConsoTV !== '-1') {
      setTargetValue7(true);
      handleTargetCheckboxChange(7, true);
      setConsoTVName(data.targetConsoTV);
      let id = getIdRelatedToValue(constantsArray['targetConsoTVArray'], data.targetConsoTV) - 1;
      setConsoTV(id);
      setDisplayConsoTV(true);
      setIsCmdLoaded(true);
    }
    //target moment marketing
    if (data.targetMomentMarketing !== null && data.targetMomentMarketing !== 'null' && data.targetMomentMarketing !== -1 && data.targetMomentMarketing !== '-1') {
      setTargetValue8(true);
      handleTargetCheckboxChange(8, true);
      setMomentMarketingName(data.targetMomentMarketing);
      const id = getIdRelatedToValue(constantsArray['targetMomentMarketingArray'], data.targetMomentMarketing) - 1;
      setMomentMarketing(id);
      setDisplayMomentMarking(true);
      setIsCmdLoaded(true);
    }
    //campaign budget
    if (data.budget !== null && data.budget !== 'null' && data.budget !== -1 && data.budget !== '-1') {
      data.budget = data.budget.replace(' €', '');
      setBudget(data.budget);
      setBudgetText(data.budget.toString() + ' €');
      setDisplayBudgetText(true);
      setIsCmdLoaded(true);
      setUserMaxStep(2);
    }
    //capaign type
    if (data.campaignType !== null && data.campaignType !== 'null' && data.campaignType !== -1 && data.campaignType !== '-1') {
      const id = getIdRelatedToValue(constantsArray['campaignTypeArray'], data.campaignType);
      switch (id) {
        case 1:
          setCampainType(1);
          break;
        case 2:
          setCampainType(0);
          break;
        case 3:
          setCampainType(2);
          break;
        default:
      }
      setCampainTypeName(data.campaignType);
      setDisplayCampainType(true);
      setIsCmdLoaded(true);
      setUserMaxStep(2);
    }
    //capaign date
    if (data.startDate !== null && data.startDate !== 'null' && data.startDate !== '-1' && data.startDate !== -1 && data.stopDate !== null && data.stopDate !== 'null' && data.stopDate !== -1 && data.stopDate !== '-1') {
      setStartDate(data.startDate);
      setStopDate(data.stopDate);
      setNewCampainStartDate(data.startDate);
      setNewCampainStopDate(data.stopDate);
      setNewCampainDateText("Du " + data.startDate + " au " + data.stopDate);
      setDisplayCampainDate(true);
      setIsCmdLoaded(true);
      setUserMaxStep(2);
    }
    //spot tv available
    if (data.spotTvAlreadyAvailable !== null && data.spotTvAlreadyAvailable !== 'null' && data.spotTvAlreadyAvailable !== -1 && data.spotTvAlreadyAvailable !== '-1') {
      setUserMaxStep(3);
      if (data.spotTvAlreadyAvailable === "Je n'ai pas de spot TV") {
        setSpotTvAlreadyAvailable(1);
      }
      else {
        setSpotTvAlreadyAvailable(0);
      }
      setDisplaySpot(true);
    }
    if (data.spotDuration !== null && data.spotDuration !== 'null' && data.spotDuration !== -1 && data.spotDuration !== '-1') {
      switch (data.spotDuration) {
        case '15':
          setSpotDuration(0);
          break;
        case '20':
          setSpotDuration(1);
          break;
        case '30':
          setSpotDuration(2);
          break;
        default:
      }
    }
    if (data.spotId !== null && data.spotId !== 'null' && data.spotId !== -1 && data.spotId !== '-1') {
      setSpotId(data.spotId);
    }
  }

  function getIdRelatedToValue(array, value) {
    for (let i = 0; i < array.length; i++) {
      //console.log('COMPARE ARRAY : ' + array[i].name + ' VAL : ' + value);
//      console.log(array[i].name.length + ' : ' + value.length)
      if (array[i].name === value) {
        return array[i].id;
      }
    }
    return 0;
  }

  function changeErrorDisplayInner(activate, id) {
    //error must be enable
    if (activate) {
      const item = document.getElementById(id);
      item.style = 'border-color: red !important';
    }
    //error must be disable
    else {
      const item = document.getElementById(id);
      item.style = 'border : 1px solid rgba(0, 0, 0, 0.12);';
    }
  }

  //function used to change error display
  function changeErrorDisplay(item, activate) {
    //step0 commercial name
    if (item === "commercialName") {
      changeErrorDisplayInner(activate, "step0CommercialName");
    }
    /*if (item === "productName") {
      changeErrorDisplayInner(activate, "step0ProductName");
    }*/
    if (item === "objective") {
      changeErrorDisplayInner(activate, "step0Objective");
    }
    //handle target error (homme femme mixte)
    if (item === "target") {
      changeErrorDisplayInner(activate, "step1Target");
    }
    if (item === "geoarea") {
      changeErrorDisplayInner(activate, "step1Area");
    }
    if (item === "profile") {
      changeErrorDisplayInner(activate, "step1Profile");
    }
    if (item === "hobbies") {
      changeErrorDisplayInner(activate, "step1Hobbies");
    }
    if (item === 'campaignType') {
      changeErrorDisplayInner(activate, "step2CampaignType");
    }
    if (item === 'campaignDate') {
      changeErrorDisplayInner(activate, "step2CampaignDate");
    }
    if (item === 'spotAvailable') {
      changeErrorDisplayInner(activate, "step3TvSpot");
    }
    if (item === "spotDuration") {
      changeErrorDisplayInner(activate, "step3SpotDuration");
    }
  }

  //function used to check if all required fields are sets before go to the next page
  function checkIfRequiredFieldsAreSets(stepToCheck) {
    if (stepToCheck === 0) {
      /* STEP 0 */
      if (commercialName === '') {
        changeErrorDisplay('commercialName', true);
        return false;
      }
      /*if (productName === '') {
        changeErrorDisplay('productName', true);
        return false;
      }*/
      if (objectifValue1 === false && objectifValue2 === false && objectifValue3 === false && objectifValue4 === false) {
        changeErrorDisplay('objective', true);
        return false;
      }
      return true;
    }
    /* STEP 1 */
    if (stepToCheck === 1) {
      //Check geoArea
      if (frenchArea === false && regionArea === false && departmentArea === false && cityArea === false && postalCodeArea === false && customArea === false) {
        changeErrorDisplay('geoarea', true);
        return false;
      }
      //check french area target
      if (frenchArea === true) {
        if (getNbOfTargetSelected() < 2) {
          changeErrorDisplay('target', true);
          return false;
        }
      }
      //check region area target
      if (regionArea === true) {
        //check if gero area region select is set
        if (nbOfRegionSelected <= 0) {
          changeErrorDisplay('geoarea', true);
          return false;
        }
        if (nbOfRegionSelected >= 2) {
          if (getNbOfTargetSelected() < 1) {
            changeErrorDisplay('target', true);
            return false;
          }
        }
      }
      //check departments area target
      if (departmentArea === true) {
        //check if geo area departement is set
        if (deptText === '') {
          changeErrorDisplay('geoarea', true);
          return false;
        }
      }
      //check city area target
      if (cityArea === true) {
        //check if geao area city is set
        if (selectedCities.length === 0) {
          changeErrorDisplay('geoarea', true);
          return false;
        }
      }
      //check custom area target
      if (customArea === true) {
        //check if customArea is set
        if ((selectedCustomLocation === null || typeof selectedCustomLocation === "undefined") || customLocationType === -1 || customDistanceText === '') {
          changeErrorDisplay('geoarea', true);
          return false;
        }
      }
      return true;
    }
    /* STEP 2 */
    if (stepToCheck === 2) {
      //campaign type
      if (campaignType === -1) {
        changeErrorDisplay('campaignType', true);
        return false;
      }
      //campaign date
      if (newCampainStartDate === null || newCampainStopDate === null) {
        changeErrorDisplay('campaignDate', true);
        return false;
      }
      return true;

    }
    if (stepToCheck === 3) {
      //check if user set spot already available
      if (spotTvAlreadyAvailable === -1) {
        changeErrorDisplay('spotAvailable', true);
        return false;
      }
      //check for spot duration
      if (spotDuration === -1) {
        changeErrorDisplay('spotDuration', true);
        return false;
      }
      //if user already have spot check if spot ID is defined
      if (spotTvAlreadyAvailable === 0) {
        if (spotId === '') {
          changeErrorDisplay('spotDuration', true);
          return false;
        }
      }
    }
    return true;
  }


  //function used to close tooltip
  function closeTooltip() {
    setTooltipNextOpen(false);
  }

  function openModal(id) {
    if (id === 0) {
      setIsModalVisible(true);
    }
    else if (id === 1) {
      setIsInfoModalVisible(true);
    }
    else {
      setIsCmdModalVisible(true);
    }
  }

  //close saved order modal
  function closeModal(id) {
    if (id === 0) {
      setIsModalVisible(false);
    }
    else if (id === 1){
      setIsInfoModalVisible(false);
    }
    else {
      setValidateCmdButtonDisabled(true);
      setTimeout(function () {
        setValidateCmdButtonDisabled(false);
      }, 2000);
      setIsCmdModalVisible(false);
    }
  }

  //function used to next in the stepper
  function goNextStep() {
    const newStep = currentStep + 1;
    if (newStep <= maxStep) {
      if (!checkIfRequiredFieldsAreSets(currentStep)) {
        setTooltipNextOpen(true);
        return;
      }
      setUserMaxStep(newStep);
      setCurrentStep(newStep);
      if (currentStep === 3) {
        props.setIsAlertVisible(false);
      }
    }
  }

  //function used to prev in the stepper
  function goPrevStep() {
    const newStep = currentStep - 1;
    if (newStep >= 0) {
      setCurrentStep(newStep);
    }
  }

  //called when commercialName or product name are set in step0
  function onCommercialOrProductNameChange(value, type) {
    if (type === "commercialName") {
      changeErrorDisplay('commercialName', false);
      setCommercialName(value);
      if (value !== "") {
        setDisplayCommercialName(true);
      }
      else {
        setDisplayCommercialName(false);
      }
    }
    else {
      changeErrorDisplay('productName', false);
      setProductName(value);
      if (value !== "") {
        setDisplayProductName(true);
      }
      else {
        setDisplayProductName(false);
      }
    }
  }

  //function called when target age change
  function handleSelectTargetAgeChange(id) {
    setSelectedAge(id);
    changeErrorDisplay('target', false);
    let tmp;
    switch (id) {
      case 0:
        tmp = '';
        break;
      case 1:
        tmp = "15 ans et plus";
        break;
      case 2:
        tmp = "15 à 24 ans";
        break;
      case 3:
        tmp = "15 à 34 ans";
        break;
      case 4:
        tmp = "15 à 49 ans";
        break;
      case 5:
        tmp = "15 à 59 ans";
        break;
      case 6:
        tmp = "25 à 34 ans";
        break;
      case 7:
        tmp = "25 à 49 ans";
        break;
      case 8:
        tmp = "25 à 59 ans";
        break;
      case 9:
        tmp = "35 à 49 ans";
        break;
      case 10:
        tmp = "35 à 59 ans";
        break;
      case 11:
        tmp = "50 ans et plus";
        break;
      default:
    }
    setSelectedAgeText(tmp);
    if (targetSex0 === true) {
      setTargetSexText("Hommes de " + tmp);
    }
    else if (targetSex1 === true) {
      setTargetSexText("Femmes de " + tmp);
    }
    else {
      setTargetSexText("Femmes et Hommes " + tmp);
    }
  }

  //function used to check how many target are selected
  function getNbOfTargetSelected() {
    let nb = 0;
    if (targetValue0 === true) {
      nb++;
    }
    if (targetValue1 === true) {
      nb++;
    }
    if (targetValue2 === true) {
      nb++;
    }
    if (targetValue3 === true) {
      nb++;
    }
    if (targetValue4 === true) {
      nb++;
    }
    if (targetValue5 === true) {
      nb++;
    }
    if (targetValue6 === true) {
      nb++;
    }
    if (targetValue7 === true) {
      nb++;
    }
    if (targetValue8 === true) {
      nb++;
    }
    return nb;
  }

  function handleOptionChange(id, value, item) {
    if (value === true) {
      let tmp = '';
      if (item === 'householdCompo') {
        switch (id) {
          case 0:
            setHouseholdCompo0(true);
            setHouseholdCompo1(false);
            setHouseholdCompo2(false);
            tmp = 'Célibataire';
            break;
          case 1:
            setHouseholdCompo0(false);
            setHouseholdCompo1(true);
            setHouseholdCompo2(false);
            tmp = 'Famille avec enfant(s)';
            break;
          case 2:
            setHouseholdCompo0(false);
            setHouseholdCompo1(false);
            setHouseholdCompo2(true);
            tmp = "Famille sans enfant";
            break;
          default:
        }
        setHouseholdCompoName(tmp);
        setDisplayHouseholdCompo(true);
        return;
      }
      else if (item === 'csp') {
        switch (id) {
          case 0:
            setCsp0(true);
            setCsp1(false);
            setCsp2(false);
            setCsp3(false);
            tmp = 'CSP++';
            break;
          case 1:
            setCsp0(false);
            setCsp1(true);
            setCsp2(false);
            setCsp3(false);
            tmp = 'CSP+';
            break;
          case 2:
            setCsp0(false);
            setCsp1(false);
            setCsp2(true);
            setCsp3(false);
            tmp = 'CSP-';
            break;
          case 3:
            setCsp0(false);
            setCsp1(false);
            setCsp2(false);
            setCsp3(true);
            tmp = 'Haut Revenus';
            break;
          default:
        }
        setCspName(tmp);
        setDisplayCsp(true);
      }
      else if (item === 'interest') {
        setInterest(id);
        setInterestName(constants['targetInterestArray'][id].name);
        setDisplayInterest(true);
      }
      else if (item === "data") {
        setData(id);
        setDataName(constants['targetDataArray'][id].name);
        setDisplayData(true);
      }
      else if (item === "geolife") {
        setGeolife(id);
        setGeolifeName(constants['targetGeolifeArray'][id].name);
        setDisplayGeolife(true);
      }
      else if (item === "consoTV") {
        setConsoTV(id);
        setConsoTVName(constants['targetConsoTVArray'][id].name);
        setDisplayConsoTV(true);
      }
      else if (item === "momentMarketing") {
        setMomentMarketing(id);
        setMomentMarketingName(constants['targetMomentMarketingArray'][id].name);
        setDisplayMomentMarking(true);
      }
      else if (item === 'housingType') {
        setHousingType(id);
        setHousingTypeName(constants['targetHousingTypeArray'][id].name);
        setDisplayHousingType(true);
      }
      else if (item === 'campainType') {
        changeErrorDisplay('campaignType', false);
        setCampainType(id);
        setCampainTypeName(constants['campaignTypeArray'][id].name);
        setDisplayCampainType(true);
      }
      else if (item === 'targetSex') {
        switch (id) {
          case 0:
            setTargetSex0(true);
            setTargetSex1(false);
            setTargetSex2(false);
            tmp = 'Homme';
            setTargetSexText('Homme');
            break;
          case 1:
            setTargetSex0(false);
            setTargetSex1(true);
            setTargetSex2(false);
            tmp = 'Femme';
            setTargetSexText('Femme');
            break;
          case 2:
            setTargetSex0(false);
            setTargetSex1(false);
            setTargetSex2(true);
            tmp = 'Femme et Homme';
            setTargetSexText('Femme et Homme');
            break;
          default:
        }
        if (selectedAgeText !== '') {
          setTargetSexText(tmp + ' de ' + selectedAgeText);
        }
        else {
          setTargetSexText(tmp);
        }
        setDisplayTargetSex(true);
      }
    }
  }

  function closeTargetOption(validated, id) {
    disableNonValidatedOptions(id);
    if (!validated) {
      disableCurrentOption(id);
    }
    setTargetIsOpen(-1);
    document.getElementById("targetContent").scrollIntoView({ behavior: 'smooth'});
  }

  function handleSpotTv(value) {
    setDisplaySpot(true);
    changeErrorDisplay('spotAvailable', false);
    setSpotTvAlreadyAvailable(value);
  }

  function disableCurrentOption(id) {
    let tmp = targetName;
    switch (id) {
      case -1:
        return;
        break;
      case 0:
        tmp[0] = '';
        setTargetValue0(false);
        setDisplayTargetSex(false);
        return;
        break;
      case 1:
        tmp[1] = '';
        setTargetValue1(false);
        setDisplayHouseholdCompo(false);
        return;
      case 2:
        tmp[2] = '';
        setTargetValue2(false);
        setDisplayCsp(false);
        return;
        break;
      case 3:
        tmp[3] = '';
        setTargetValue3(false);
        setDisplayHousingType(false);
        return;
        break;
      case 4:
        tmp[4] = '';
        setTargetValue4(false);
        setDisplayInterest(false);
        return;
      case 5:
        tmp[5] = '';
        setTargetValue5(false);
        setDisplayData(false);
        return;
      case 6:
        tmp[6] = '';
        setTargetValue6(false);
        setDisplayGeolife(false);
        return;
      case 7:
        tmp[7] = '';
        setTargetValue7(false);
        setDisplayConsoTV(false);
        return;
        break;
      case 8:
        tmp[8] = '';
        setTargetValue8(false);
        setDisplayMomentMarking(false);
        return;
        break;
      default:
      return;
    }
    setTargetName(tmp);
  }

  //function used to disable non validated target options
  function disableNonValidatedOptions(id) {
    let tmp = targetName;
    let target;
    if (id === null) {
      target = targetIsOpen;
    }
    else {
      target = id;
    }
    switch (target) {
      case -1:
        return;
        break;
      case 0:
        if (targetSex0 === false && targetSex1 === false && targetSex2 === false) {
          tmp[0] = '';
          setTargetValue0(false);
          return;
        }
        break;
      case 1:
        if (householdCompo0 === false && householdCompo1 === false && householdCompo2 === false) {
          tmp[1] = '';
          setTargetValue1(false);
          return;
        }
        break;
      case 2:
        if (csp0 === false && csp1 === false && csp2 === false && csp3 === false) {
          tmp[2] = '';
          setTargetValue2(false);
          return;
        }
        break;
      case 3:
        if (housingType === -1) {
          tmp[3] = '';
          setTargetValue3(false);
          return;
        }
        break;
      case 4:
        if (interest === -1) {
          tmp[4] = '';
          setTargetValue4(false);
          return;
        }
        break;
      case 5:
        if (data === -1) {
          tmp[5] = '';
          setTargetValue5(false);
          return;
        }
        break;
      case 6:
        if (geolife === -1) {
          tmp[6] = '';
          setTargetValue6(false);
          return;
        }
        break;
      case 7:
        if (consoTV === -1) {
          tmp[7] = '';
          setTargetValue7(false);
          return;
        }
        break;
      case 8:
        if (momentMarketing === -1) {
          tmp[8] = '';
          setTargetValue8(false);
          return;
          }
          break;
      default:
      return;
    }
    setTargetName(tmp);
  }

  //function called when checkbox target change
  function handleTargetCheckboxChange(checkboxId, value) {
    //changeErrorDisplay('target', false);
    let limit;
    if (frenchArea === true) {
      limit = 3;
    }
    else {
      if (nbOfRegionSelected === 1) {
        limit = 1;
      }
      else if (nbOfRegionSelected >= 2) {
        limit = 2;
      }
    }
    let tmp = targetName;
    const nbOfTargetSelected = getNbOfTargetSelected();
    disableNonValidatedOptions(null);
    if (value === true) {
      if (nbOfTargetSelected + 1 <= limit) {
        switch (checkboxId) {
          case 0:
            setTargetIsOpen(0);
            setTargetValue0(value);
            tmp[0] = 'Socio Démo';
            break;
          case 1:
            setTargetIsOpen(1);
            setTargetValue1(value);
            tmp[1] = 'Composition du foyer';
            break;
          case 2:
            setTargetIsOpen(2);
            setTargetValue2(value);
            tmp[2] = 'CSP & Revenus';
            break;
          case 3:
            setTargetIsOpen(3);
            setTargetValue3(value);
            tmp[3] = "Type d'habitat";
            break;
          case 4:
            setTargetIsOpen(4);
            setTargetValue4(value);
            tmp[4] = 'intérêts';
            break;
          case 5:
            setTargetIsOpen(5);
            setTargetValue5(value);
            tmp[5] = 'Data';
            break;
          case 6:
            setTargetIsOpen(6);
            setTargetValue6(value);
            tmp[6] = 'Géolife';
            break;
          case 7:
            setTargetIsOpen(7);
            setTargetValue7(value);
            tmp[7] = 'Conso TV';
            break;
          case 8:
            setTargetIsOpen(8);
            setTargetValue8(value);
            tmp[8] = 'Moment marketing';
            break;
          default:
        }
      }
    }
    else {
      switch (checkboxId) {
        case 0:
          setTargetValue0(value);
          tmp[0] = '';
          break;
        case 1:
          setTargetValue1(value);
          tmp[1] = '';
          break;
        case 2:
          setTargetValue2(value);
          tmp[2] = '';
          break;
        case 3:
          setTargetValue3(value);
          tmp[3] = '';
          break;
        case 4:
          setTargetValue4(value);
          tmp[4] = '';
          break;
        case 5:
          setTargetValue5(value);
          tmp[5] = '';
          break;
        case 6:
          setTargetValue6(value);
          tmp[6] = '';
          break;
        case 7:
          setTargetValue7(value);
          tmp[7] = '';
          break;
        case 8:
          setTargetValue8(value);
          tmp[8] = '';
          break;
        default:
      }
    }
    setTargetName(tmp);
    setDisplayTarget(true);
    return;
  }

  //function called when checkbox objectif change
  function handleObjectifCheckboxChange(checkboxId, value) {
    changeErrorDisplay('objective', false);
    switch(checkboxId) {
      case 1:
        if (value) {
          setObjectifValue1(value);
        }
        setObjectifValue2(false);
        setObjectifValue3(false);
        setObjectifValue4(false);
        setGoalName(constants['communicationObjectiveArray'][0].name);
        setDisplayGoal(true);
        break;
      case 2:
        setObjectifValue1(false);
        if (value) {
          setObjectifValue2(value);
        }
        setObjectifValue3(false);
        setObjectifValue4(false);
        setGoalName(constants['communicationObjectiveArray'][1].name);
        setDisplayGoal(true);
        break;
      case 3:
        setObjectifValue1(false);
        setObjectifValue2(false);
        if (value) {
          setObjectifValue3(value);
        }
        setObjectifValue4(false);
        setGoalName(constants['communicationObjectiveArray'][2].name);
        setDisplayGoal(true);
        break;
      case 4:
        setObjectifValue1(false);
        setObjectifValue2(false);
        setObjectifValue3(false);
        if (value) {
          setObjectifValue4(value);
        }
        setGoalName(constants['communicationObjectiveArray'][3].name);
        setDisplayGoal(true);
        break;
      default:
        return;
    }
  }

  //function used when departement change to update recap
  function handleSelectDeptChange(value) {
    changeErrorDisplay('geoarea', false);
    let first;
    setDeptText(value);
    setNbOfDepartmentSelected(value.length);
    if (frenchArea === true) {
      first = "France entière ";
    }
    else {
      first = "Département(s) : ";
    }
    handleGeoAreaText(first, value);
  }

  //function used when departement change to update recap
  function handleSelectRegionChange(value) {
    changeErrorDisplay('geoarea', false);
    let first;
    setRegionText(value);
    setNbOfRegionSelected(value.length);
    if (frenchArea === true) {
      first = "France entière ";
    }
    else {
      first = "Région(s) : ";
    }
    handleGeoAreaText(first, value);
  }

  //function used to reset all selected target (step 1)
  function resetSelectedTarget() {
    //reset open target
    setTargetIsOpen(-1);
    //reset target
    setTargetValue0(false);
    setTargetValue1(false);
    setTargetValue2(false);
    setTargetValue3(false);
    setTargetValue4(false);
    setTargetValue5(false);
    setTargetValue6(false);
    setTargetValue7(false);
    setTargetValue8(false);
    let tmp = targetName;
    for (let i = 0; i < targetName.length; i++) {
      tmp[i] = '';
    }
    setTargetName(tmp);
    setDisplayTarget(false);

    //reset target sex
    setTargetSex0(false);
    setTargetSex1(false);
    setTargetSex2(false);
    setTargetSexText('');
    setDisplayTargetSex(false);

    //reset household compo
    setHouseholdCompo0(false);
    setHouseholdCompo1(false);
    setHouseholdCompo2(false);
    setHouseholdCompoName('');
    setDisplayHouseholdCompo(false);

    //reset csp
    setCsp0(false);
    setCsp1(false);
    setCsp2(false);
    setCsp3(false);
    setCspName('');
    setDisplayCsp(false);

    //reset housing Type
    setHousingType(-1);
    setHousingTypeName('');
    setDisplayHousingType(false);

    //reset interest
    setInterest(-1);
    setInterestName('');
    setDisplayInterest(false);

    //reset data
    setData(-1);
    setDataName('');
    setDisplayData(false);

    //reset geolife
    setGeolife(-1);
    setGeolifeName('');
    setDisplayGeolife(false);

    //reset consoTv
    setConsoTV(-1);
    setConsoTVName('');
    setDisplayConsoTV(false);

    //reset moment marketing
    setMomentMarketing(-1);
    setMomentMarketingName('');
    setDisplayMomentMarking(false);
  }

  //function used to set selected geographic area on recap part 1
  function handleGeoArea(checkboxId, value) {
    changeErrorDisplay('geoarea', false);
    setGeoAreaOptionsArray([]);
    if (value === true) {
      resetSelectedTarget();
      let first = "";
      //national campaign
      if (checkboxId === 1) {
        setFrenchArea(value);
        setDepartmentArea(false);
        setRegionArea(false);
        setPostalCodeArea(false);
        setCityArea(false);
        setCustomArea(false);
        if (value) {
          first = "France entière ";
          handleGeoAreaText(first, null);
        }
        else {
          handleGeoAreaText("", null);
        }
      }
      //regional campaign
      else if (checkboxId === 2) {
        setFrenchArea(false);
        setDepartmentArea(false);
        setRegionArea(value);
        setPostalCodeArea(false);
        setCityArea(false);
        setCustomArea(false);
        if (value) {
          first = "Région(s) : ";
          handleGeoAreaText(first);
        }
        else {
          handleGeoAreaText("", null);
          setRegionText("");
        }
      }
      //departemental campaign
      else if (checkboxId === 3) {
        setFrenchArea(false);
        setDepartmentArea(value);
        setRegionArea(false);
        setPostalCodeArea(false);
        setCityArea(false);
        setCustomArea(false);
        if (value) {
          first = "Département(s) : ";
          handleGeoAreaText(first);
        }
        else {
          handleGeoAreaText("", null);
          setDeptText("");
        }
      }
      // campaign
      else if (checkboxId === 4) {
        setFrenchArea(false);
        setDepartmentArea(false);
        setRegionArea(false);
        setPostalCodeArea(false);
        setCityArea(value);
        setCustomArea(false);
        if (value) {
          first = "Ville(s) : ";
          handleGeoAreaText(first);
        }
        else {
          handleGeoAreaText("", null);
          setCityText("");
        }
      }
      //postal code campaign
      else if (checkboxId === 5) {
        setFrenchArea(false);
        setDepartmentArea(false);
        setRegionArea(false);
        setPostalCodeArea(value);
        setCityArea(false);
        setCustomArea(false);
        if (value) {
          first = "Code postal(aux) : ";
          handleGeoAreaText(first);
        }
        else {
          handleGeoAreaText("", null);
          setPostalCodeText("");
        }
      }
      //custom campaign
      else if (checkboxId === 6) {
        setFrenchArea(false);
        setDepartmentArea(false);
        setRegionArea(false);
        setPostalCodeArea(false);
        setCityArea(false);
        setCustomArea(value);
        if (value) {
          first = "Sur mesure : ";
          handleGeoAreaText(first);
        }
        else {
          handleGeoAreaText("", null);
        }
      }
      if (first === "") {
        setDisplayGeoArea(false);
      }
    }
  }

  //function used to set selected geographic area on recap part 2
  function handleGeoAreaText(first, last) {
    if (first.includes("France entière")) {
      setGeoAreaText(first);
    }
    if (first === "") {
      setGeoAreaText(first);
    }
    else {
      if (typeof last !== 'undefined' && last !== null) {
        setGeoAreaText(first + last.toString().split(",").join(", "));
        setGeoAreaOption(last);
      }
      else {
        setGeoAreaText(first);
      }
    }
    setDisplayGeoArea(true);
  }

  //if val > 6 user enter a specific value in textfied budget
  function handleBudget(value) {
    //reset campaign type
    setCampainType(-1);
    setCampainTypeName('');
    setDisplayCampainType(false);

    setBudget(value);
    setBudgetText(value.toString() + ' €');
    setDisplayBudgetText(true);
  }

  //function used to handle date change on cmd recap
  function handleDateChange(date) {
    changeErrorDisplay('campaignType', false);
    setNewCampainStartDate(format(date.rollup.startDate, 'dd/MM/yyyy', {locale: fr}));
    setStartDate(format(date.rollup.startDate, 'dd/MM/yyyy', {locale: fr}));
    if (date.rollup.endDate === date.rollup.startDate) {
      setNewCampainDateText("Du " + format(date.rollup.startDate, 'dd/MM/yyyy', {locale: fr}));
    }
    else {
      setNewCampainStopDate(format(date.rollup.endDate, 'dd/MM/yyyy', {locale: fr}));
      setStopDate(format(date.rollup.endDate, 'dd/MM/yyyy', {locale: fr}));
      setNewCampainDateText("Du " + format(date.rollup.startDate, 'dd/MM/yyyy', {locale: fr}) + ' au ' + format(date.rollup.endDate, 'dd/MM/yyyy', {locale: fr}));
    }
    setDisplayCampainDate(true);
  }

  //function used to handle the size of right bottom part of new campain grid
  //Must be uncommented for videoHandler
  function handleStyleForNewOrderRecap() {
    /*if (currentStep === 0) {
      return "29vh";
    }
    else {
      return "auto";
    }*/
    return "auto";
  }

  //function used to update state of TextField
  async function updateState(stateName, value) {
    clearTimeout(timer);
    if (stateName === 'pubId') {
      changeErrorDisplay('spotDuration', false);
      setSpotId(value);
    }
    if (stateName === 'spotDuration') {
      changeErrorDisplay('spotDuration', false);
      setSpotDuration(value);
    }
    //the timer is used to call the public address api only when user stop writing
    if (stateName === "searchCityTextfieldValue") {
      setSearchCityTextfieldValue(value);
      timer = setTimeout(async function() {
        const tmp = await handleAddrChange(value, 'city');
        if (tmp !== null) {
          setCities(tmp);
        }
      }, 500);
    }
    if (stateName === "searchPostalCodeTextfieldValue") {
      setSearchPostalCodeTextfieldValue(value);
      timer = setTimeout(async function() {
        const tmp = await handleAddrChange(value, 'postalCode');
        if (tmp !== null) {
          setPostalCode(tmp);
        }
      }, 500);
    }
    if (stateName === 'customDistanceText') {
      setCustomDistanceText(value);
      handleCustomTargetSelectChange(customLocationType, value, null);
    }
    if (stateName === "customLocationSearchText") {
      setCustomLocationSearchText(value);
      timer = setTimeout(async function() {
        const tmp = await handleAddrChange(value, 'custom');
        if (tmp !== null) {
          //console.log(tmp)
          setCustomLocations(tmp);
        }
      }, 500);
    }
  }

  function handleItemSelectChange(value, category) {
    let tmp;
    changeErrorDisplay('geoarea', false);
    if (category === "city") {
      tmp = selectedCities;
      if (value.length > 0) {
        tmp.push(value);
        setSelectedCities(tmp);
        handleGeoAreaText('Ville(s) : ' + tmp);
        setCities([]);
        setSearchCityTextfieldValue('');
      }
    }
    else if (category === "postalCode") {
      tmp = selectedCities;
      tmp.push(value);
      setSelectedPostalCode(tmp);
      handleGeoAreaText('Code postal(aux) : ' + tmp);
    }
  }

  //function called to remove selected custom location
  function removeSelectedItem(val, category) {
    let index = selectedCities.indexOf(val);
    if (index !== -1) {
      if (category === 'city') {
        const tmp = removeItemFromArray(val, selectedCities);
        setSelectedCities(tmp);
        handleGeoAreaText('Ville(s) : ' + tmp);
      }
      else if (category === 'postalCode') {
        const tmp = removeItemFromArray(val, selectedPostalCode);
        setSelectedPostalCode(tmp);
        handleGeoAreaText('Code postal(aux) : ' + tmp);
      }

    }
    if (selectedCities.length === 0) {
      setSelectedCities([]);
    }
  }

  function removeItemFromArray(item, array) {
    let tmp = [];
    for (let i = 0; i < array.length; i++) {
      if (array[i] !== item && array[i].length > 0) {
        tmp.push(array[i]);
      }
    }
    return tmp;
  }

  //called when user stop writing address or postal code
  async function handleAddrChange(value, category) {
    if (value === null) {
      return null;
    }
    if (category === "custom") {
      const ret = await ApiAddr.getAddressFromPublicApi(value);
      if (ret === false || typeof ret.data === "undefined") {
        return null;
      }
      if (typeof ret.data.features !== "undefined") {
        let tmp = [];
        for (let i = 0; i < ret.data.features.length; i++) {
          //console.log(ret.data.features[i].properties)
          tmp.push(ret.data.features[i].properties.label);
        }
        return tmp;
      }
      else {
        return null;
      }
    }
    if (category === "city") {
      const ret = await ApiAddr.getCityFromPublicApi(value);
      if (ret === false || typeof ret.data === "undefined") {
        return null;
      }
      if (typeof ret.data.features !== "undefined") {
        let tmp = [];
        for (let i = 0; i < ret.data.features.length; i++) {
          //console.log(ret.data.features[i].properties)
          tmp.push(ret.data.features[i].properties.city + ' (' + ret.data.features[i].properties.postcode + ')');
        }
        return tmp;
      }
      else {
        return null;
      }
    }
    else if (category === "postalCode") {
      const ret = await ApiAddr.getAddressFromPublicApi(value);
      if (ret === false || typeof ret.data === "undefined") {
        return null;
      }
      if (typeof ret.data.features !== "undefined") {
        let tmp = [];
        for (let i = 0; i < ret.data.features.length; i++) {
          //console.log(ret.data.features[i].properties)
          if (checkIfItemIsAlreadyInList(tmp, ret.data.features[i].properties.postcode) === false) {
            tmp.push(ret.data.features[i].properties.postcode);
          }
        }
        return tmp;
      }
      else {
        return null;
      }
    }
  }

  function checkIfItemIsAlreadyInList(list, item) {
    for (let i = 0; i < list.length; i++) {
      if (list[i] === item) {
        return true;
      }
    }
    return false;
  }

  //function called when custom target (distance / time) change (used to handle recap text)
  function handleCustomTargetSelectChange(option, option1, option2) {
    changeErrorDisplay("geoarea", false);
    let id;
    let one = "A moins de ";
    let two = "";
    let three = "";
    let check = false;

    if (option !== null) {
      setCustomLocationType(option);
      id = option;
    }
    else {
      id = customLocationType;
    }
    //distance KM
    if (id === 1) {
      if (option1 !== null && typeof option1 !== "undefined") {
        two = option1 + " km de ";
      }
      else if (customDistanceText !== '') {
        two = customDistanceText + " km de ";
      }
      else {
        two = '... km de ';
      }
      if (option2 !== null && typeof option2 !== "undefined") {
        three = option2;
      }
      else if (selectedCustomLocation !== "") {
        three = selectedCustomLocation;
      }
      else {
        three = " ...";
      }
    }
    //distance time travel
    else if (id === 2) {
      let hours = Math.trunc(option1 / 60);
      let minutes = option1 % 60;
      if (isNaN(hours)) {
        check = true;
      }
      if (option1 !== null && typeof option1 !== "undefined") {
        if (hours < 1) {
          two = option1 + " minutes de  ";
        }
        else if (hours === 1 && minutes === 0) {
          two = hours + 'h' + " de  ";
        }
        else {
          two = hours + 'h' + minutes + " de  ";
        }

      }
      else if (customDistanceText !== '') {
        if (hours < 1) {
          two = customDistanceText + " minutes de ";
        }
        else if (hours === 1 && minutes === 0) {
          two = customDistanceText + hours + 'h' + " de  ";
        }
        else {
          two = customDistanceText + hours + 'h' + minutes + " de  ";
        }
      }
      else {
        if (hours < 1) {
          two = '... minutes de ';
        }
        else if (hours === 1 && minutes === 0) {
          two = '... ' + hours + 'h' + " de  ";
        }
        else {
          two = '... ' + hours + 'h' + minutes + " de  ";
        }
      }
      if (option2 !== null && typeof option2 !== "undefined") {
        three = option2;
      }
      else if (selectedCustomLocation !== "") {
        three = selectedCustomLocation;
      }
      else {
        three = " ...";
      }
    }
    else {
      if (option1) {
        two = option1 + " ...";
      }
      else {
        two = ' ... ';
        three = option2;
      }
    }
    //check if two is defined
    if (check) {
      two = ' 5 minutes';
    }

    else {
      handleGeoAreaText("Sur mesure : " + one + two + three);
    }
  }

  //function called when custom location change
  function customLocationChange(value) {
    setSelectedCustomLocation(value);
    handleCustomTargetSelectChange(null, null, value);
  }

  function handleTargetOptions() {
    let ret = [];
    let tmp;
    //homme femme mixte age
    if (targetValue0 === true) {
      if (targetSex0 === true) {
        tmp = 'Homme';
        ret.push(
          [
            {id: 'Socio Démo'},
            {value: tmp},
            {age: selectedAgeText}
          ]
        );
      }
      else if (targetSex1 === true) {
        tmp = 'Femme';
        ret.push(
          [
            {id: 'Socio Démo'},
            {value: tmp},
            {age: selectedAgeText}
          ]
        );
      }
      else if (targetSex2 === true) {
        tmp = 'Mixte';
        ret.push(
          [
            {id: 'Socio Démo'},
            {value: tmp},
            {age: selectedAgeText}
          ]
        );
      }
    }
    if (targetValue1 === true) {
      if (householdCompo0 === true) {
        tmp = 'Célibataire';
        ret.push(
          [
            {id: 'Composition du foyer'},
            {value: tmp},
          ]
        );
      }
      else if (householdCompo1 === true) {
        tmp = 'Famille avec enfant(s)';
        ret.push(
          [
            {id: 'Composition du foyer'},
            {value: tmp},
          ]
        );
      }
      else if (householdCompo2 === true) {
        tmp = 'Famille sans enfant(s)';
        ret.push(
          [
            {id: 'Composition du foyer'},
            {value: tmp},
          ]
        );
      }
    }
    if (targetValue2 === true) {
      if (csp0 === true) {
        tmp = 'CSP++';
        ret.push(
          [
            {id: 'CSP & Revenus'},
            {value: tmp},
          ]
        );
      }
      else if (csp1 === true) {
        tmp = 'CSP+';
        ret.push(
          [
            {id: 'CSP & Revenus'},
            {value: tmp},
          ]
        );
      }
      else if (csp2 === true) {
        tmp = 'CSP-';
        ret.push(
          [
            {id: 'CSP & Revenus'},
            {value: tmp},
          ]
        );
      }
      else if (csp3 === true) {
        tmp = 'Haut Revenus';
        ret.push(
          [
            {id: 'CSP & Revenus'},
            {value: tmp},
          ]
        );
      }
    }
    if (targetValue3 === true) {
      ret.push(
        [
          {id: "Type d'habitat"},
          {value: housingTypeName},
        ]
      );
    }
    if (targetValue4 === true) {
      ret.push(
        [
          {id: "intérêts"},
          {value: interestName},
        ]
      );
    }
    if (targetValue5 === true) {
      ret.push(
        [
          {id: "Data"},
          {value: dataName},
        ]
      );
    }
    if (targetValue6 === true) {
      ret.push(
        [
          {id: "Géolife"},
          {value: geolifeName},
        ]
      );
    }
    if (targetValue7 === true) {
      ret.push(
        [
          {id: "Conso TV"},
          {value: consoTVName},
        ]
      );
    }
    if (targetValue8 === true) {
      ret.push(
        [
          {id: "Moment marketing"},
          {value: momentMarketingName},
        ]
      );
    }
    return ret;
  }

  //used to validate cmd
  async function validateCmd(status) {

    if (!checkIfRequiredFieldsAreSets(currentStep)) {
      setTooltipNextOpen(true);
      return;
    }

    const token = LocalStorage.getToken();
    const id = LocalStorage.getId();
    let startDate = checkForDate(newCampainStartDate);
    let stopDate = checkForDate(newCampainStopDate);
    const data = {
      email: id,
      token: token,
      status: status,
      productName: productName,
      commercialName: commercialName,
      communicationObjective: goalName,
      geoArea: geoAreaText,
      geoAreaOptions: geoAreaOption,
      target: targetName,
      targetOptions: handleTargetOptions(),
      budget: budgetText === 0 ? "3000 €" : budgetText,
      campaignType: campainTypeName,
      campaignStartDate: startDate,
      campaignStopDate: stopDate,
      spotTvAlreadyAvailable: spotTvAlreadyAvailable,
      spotDuration: spotDuration,
      spotId: spotId,
      draftId: draftCmdId
    };
    setIsCmdLoading(true);

    //update
    if (isDraftAlreadyCreated === true && status !== 2|| isCmdLoaded === true && status !== 2) {
      setIsDraftAlreadyCreated(true);
      const ret = await QuotationApi.updateUserCmd(data);
      if (ret === false) {
        setIsCmdValidated(0);
        setIsCmdLoading(false);
      }
      if (ret.data.message === 'error') {
        setIsCmdValidated(0);
        setIsCmdLoading(false);
      }
      if (ret.data.message === 'success') {
        props.setIsAlert(true, "Un brouillon de votre plan média a été enregistré", "success", 'info');
        if (typeof ret.data.draftCmdId !== 'undefined') {
          setDraftCmdId(ret.data.draftCmdId);
        }
        setIsCmdLoading(false);
        return ret.data.draftCmdId;
      }
    }
    else if (status === 2) {
      setIsDraftAlreadyCreated(true);
      const ret = await QuotationApi.updateUserCmd(data);
      if (ret === false) {
        setIsCmdValidated(0);
        setIsCmdLoading(false);
      }
      if (ret.data.message === 'error') {
        setIsCmdValidated(0);
        setIsCmdLoading(false);
      }
      if (ret.data.message === 'success') {
        setIsCmdValidated(1);
        setIsCmdLoading(false);
      }
    }
    //create
    else {
      const ret = await QuotationApi.validateUserCmd(data);
      setIsCmdLoading(false);
      if (ret.data.message === 'success') {
        setIsDraftAlreadyCreated(true);
        props.setIsAlert(true, "Un brouillon de votre plan média a été enregistré", "success", 'info');
        if (typeof ret.data.draftCmdId !== 'undefined') {
          setDraftCmdId(ret.data.draftCmdId);
        }
        if (typeof ret.data.draftCmdId !== 'undefined') {
          setDraftCmdId(ret.data.draftCmdId);
        }
        setIsCmdLoading(false);
        return ret.data.draftCmdId;
      }
      else {
        //handle create cmd error
      }
    }
  }

  //function used to check if date is string
  function checkForDate(date) {
    if (typeof date === 'string') {
      return date;
    }
    else {
      return 0;
    }
  }

  //function used to display step when user clicks on stepper items
  function displayStep(stepAsked) {
    //alert("step asked : " + stepAsked + ' || userMaxStep : ' + userMaxStep + ' || current : ' + currentStep)
    if (stepAsked <= maxStep) {
      if (stepAsked <= userMaxStep) {
        setCurrentStep(stepAsked);
        return;
      }
      if (stepAsked === (userMaxStep + 1)) {
        if (!checkIfRequiredFieldsAreSets(currentStep)) {
          return;
        }
        setUserMaxStep(stepAsked);
        setCurrentStep(stepAsked);
        if (stepAsked === 3) {
          validateCmd(1);
        }
        return;
      }
      if (stepAsked === currentStep) {
        return;
      }
      else {
        props.setIsAlert(true, "Vous devez d'abord remplir les étapes intermédiaires", "warning", 'warning');
        return;
      }
    }
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar style={{position: "relative"}}>
        <div>
          <img src={logo} alt="logo" className="appBarLogo"/>
          <Button onClick={() => window.open('https://www.videoclub-pub.fr/contact')} style={{position: "absolute", right: "350px", marginTop: "30px"}} color="inherit">Besoin d'aide ?</Button>
          <Button onClick={() => window.open('https://www.videoclub-pub.fr/')} style={{position: "absolute", right: "180px", marginTop: "30px"}} color="inherit">Démarrer en TV</Button>
          <Button onClick={() => props.disconnectUser()} style={{position: "absolute", right: "30px", marginTop: "30px"}} color="inherit">Déconnexion</Button>
        </div>
        </Toolbar>
        {
          props.isAlertVisible === true ? <Alert isAlerVisible={props.isAlertVisible} text={props.alertText} color={props.alertColor} severity={props.alertSeverity} onClose={() => props.setIsAlertVisible(false)} /> : null
        }
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }} style={{marginTop: "2em"}}>
          <List>
            {['Accueil', 'Mes campagnes', 'Nouvelle campagne', 'Mon Profil'].map((text, index) => (
              <div button key={text} className="homeMenuItemWrapper" onClick={() => props.displayPage(index)}>
                <ListItemIcon style={{justifyContent: "center"}}>
                  {
                    index  === 0 ? <WidgetsIcon className="homeMenuIcon" /> :
                    index  === 1 ? <DashboardIcon className="homeMenuIcon" /> :
                    index  === 2 ? <DashboardCustomizeIcon className="homeMenuIcon" /> :
                    <CoPresentIcon className="homeMenuIconSmall"/>
                  }
                </ListItemIcon>
                <span className="menuIconText">{text}</span>
              </div>
            ))}
          </List>
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <div style={{marginTop: "5em"}}>
          <h1>Nouvelle campagne</h1>
          <Stepper
            currentStep={currentStep}
            goNextStep={goNextStep}
            goPrevStep={goPrevStep}
            displayStep={displayStep}
          />
          <div class="grid-container" style={{marginTop: "4em"}}>
            <div class="grid-item-large">
              <StepperHandler
                //general steps props
                currentStep={currentStep}
                goNextStep={goNextStep}
                goPrevStep={goPrevStep}
                //tooltip error & open close
                tooltipNextOpen={tooltipNextOpen}
                closeTooltip={closeTooltip}

                objectifValue1={objectifValue1}
                objectifValue2={objectifValue2}
                objectifValue3={objectifValue3}
                objectifValue4={objectifValue4}
                productName={productName}
                commercialName={commercialName}
                onCommercialOrProductNameChange={onCommercialOrProductNameChange}

                handleObjectifCheckboxChange={handleObjectifCheckboxChange}

                targetValue0={targetValue0}
                targetValue1={targetValue1}
                targetValue2={targetValue2}
                targetValue3={targetValue3}
                targetValue4={targetValue4}
                targetValue5={targetValue5}
                targetValue6={targetValue6}
                targetValue7={targetValue7}
                targetValue8={targetValue8}
                targetIsOpen={targetIsOpen}
                closeTargetOption={closeTargetOption}
                handleTargetCheckboxChange={handleTargetCheckboxChange}

                targetSex0={targetSex0}
                targetSex1={targetSex1}
                targetSex2={targetSex2}
                handleSelectTargetAgeChange={handleSelectTargetAgeChange}

                householdCompo0={householdCompo0}
                householdCompo1={householdCompo1}
                householdCompo2={householdCompo2}

                csp0={csp0}
                csp1={csp1}
                csp2={csp2}
                csp3={csp3}

                housingType={housingType}
                housingTypeArray={constants['targetHousingTypeArray']}
                interest={interest}
                interestArray={constants['targetInterestArray']}
                data={data}
                dataArray={constants['targetDataArray']}
                geolife={geolife}
                geolifeArray={constants['targetGeolifeArray']}
                consoTV={consoTV}
                consoTvArray={constants['targetConsoTVArray']}
                momentMarketing={momentMarketing}
                momentMarketingArray={constants['targetMomentMarketingArray']}
                handleOptionChange={handleOptionChange}

                campainTypeArray={constants['campaignTypeArray']}

                frenchAreaValue={frenchArea}
                departmentAreaValue={departmentArea}
                regionAreaValue={regionArea}
                cityAreaValue={cityArea}
                cities={cities}
                updateState={updateState}
                nbOfRegionSelected={nbOfRegionSelected}
                nbOfDepartmentSelected={nbOfDepartmentSelected}

                customDistanceText={customDistanceText}
                customLocations={customLocations}
                customLocationChange={customLocationChange}
                customLocationSearchText={customLocationSearchText}
                customLocationType={customLocationType}

                searchCityTextfieldValue={searchCityTextfieldValue}
                handleItemSelectChange={handleItemSelectChange}
                selectedCities={selectedCities}
                removeSelectedItem={removeSelectedItem}
                selectedPostalCode={selectedPostalCode}
                postalCode={postalCode}
                selectedCustomLocation={selectedCustomLocation}
                handleCustomTargetSelectChange={handleCustomTargetSelectChange}

                postalCodeAreaValue={postalCodeArea}
                customAreaValue={customArea}
                handleSelectDeptChange={handleSelectDeptChange}
                handleSelectRegionChange={handleSelectRegionChange}
                handleGeoArea={handleGeoArea}

                campaignType={campaignType}
                budget={budget}
                handleBudget={handleBudget}
                handleDateChange={handleDateChange}

                spotTvAlreadyAvailable={spotTvAlreadyAvailable}
                handleSpotTv={handleSpotTv}
                spotDuration={spotDuration}
                spotId={spotId}

                openModal={openModal}
                validateCmd={validateCmd}
                isCmdValidated={isCmdValidated}
                isCmdLoading={isCmdLoading}

                draftCmdId={draftCmdId}
                validateCmdButtonDisabled={validateCmdButtonDisabled}
                geoAreaOptionsArray={geoAreaOptionsArray}
                selectedAge={selectedAge}
                startDate={startDate}
                stopDate={stopDate}
                cityText={cityText}
                postalCodeText={postalCodeText}
                searchPostalCodeTextfieldValue={searchPostalCodeTextfieldValue}
                setGeoAreaOptionsArray={setGeoAreaOptionsArray}
                isDraftAlreadyCreated={isDraftAlreadyCreated}

              />
            </div>
            <div class="grid-item">
              {/*<VideoHandler step={currentStep} />*/}
              <div style={{height: handleStyleForNewOrderRecap(), /*overflowY: "scroll"*/}}>
                <h2 style={{fontSize: '1em', marginTop: "1em"}}>Récapitulatif de la campagne</h2>
                <div>
                {
                  displayCommercialName
                  ?
                  <div style={{textAlign: "left", display: "flex", flexDirection: "column", paddingLeft: "20px", marginTop: "1em"}}>
                    <span className="bold">Nom commercial</span>
                    <span>{commercialName}</span>
                  </div>
                  :
                  null
                }
                {
                  displayProductName
                  ?
                  <div style={{textAlign: "left", display: "flex", flexDirection: "column", paddingLeft: "20px", marginTop: "1em"}}>
                    <span className="bold">Nom du produit</span>
                    <span>{productName}</span>
                  </div>
                  :
                  null
                }
                {
                  displayGeoArea
                  ?
                  <div style={{textAlign: "left", display: "flex", flexDirection: "column", paddingLeft: "20px", marginTop: "1em"}}>
                    <span className="bold">Zone Géograhique</span>
                    <span>{geoAreaText}</span>
                  </div>
                  :
                  null
                }
                {
                  displayTarget
                  ?
                  <div style={{textAlign: "left", display: "flex", flexDirection: "column", paddingLeft: "20px", marginTop: "1em"}}>
                    <span className="bold">Cible</span>
                    {targetName.map(item => <span>{item}</span>)}
                  </div>
                  :
                  null
                }
                {
                  targetValue0 === true || targetValue1 === true || targetValue2 === true || targetValue3 === true || targetValue4 === true || targetValue5 === true || targetValue6 === true || targetValue7 === true || targetValue8 === true
                  ?
                  <div style={{textAlign: "left", display: "flex", flexDirection: "column", paddingLeft: "20px", marginTop: "1em"}}>
                    <span className="bold">Options ciblage</span>
                    {
                      displayTargetSex
                      ?
                      <span>{targetSexText}</span>
                      :
                      null
                    }
                    {
                      displayHouseholdCompo
                      ?
                      <span>{householdCompoName}</span>
                      :
                      null
                    }
                    {
                      displayCsp
                      ?
                      <span>{cspName}</span>
                      :
                      null
                    }
                    {
                      displayHousingType
                      ?
                      <span>{housingTypeName}</span>
                      :
                      null
                    }
                    {
                      displayInterest
                      ?
                      <span>{interestName}</span>
                      :
                      null
                    }
                    {
                      displayData
                      ?
                      <span>{dataName}</span>
                      :
                      null
                    }
                    {
                      displayGeolife
                      ?
                      <span>{geolifeName}</span>
                      :
                      null
                    }
                    {
                      displayConsoTV
                      ?
                      <span>{consoTVName}</span>
                      :
                      null
                    }
                    {
                      displayMomentMarking
                      ?
                      <span>{momentMarketingName}</span>
                      :
                      null
                    }
                  </div>
                  :
                  null
                }

                {
                  displayGoal
                  ?
                  <div style={{textAlign: "left", display: "flex", flexDirection: "column", paddingLeft: "20px", marginTop: "1em"}}>
                    <span className="bold">Objectif</span>
                    <span>{goalName}</span>
                  </div>
                  :
                  null
                }
                {
                  displayBudgetText
                  ?
                  <div style={{textAlign: "left", display: "flex", flexDirection: "column", paddingLeft: "20px", marginTop: "1em"}}>
                    <span className="bold">Budget</span>
                    <span>
                    {
                      budgetText
                    }
                    </span>
                  </div>
                  :
                  null
                }
                {
                  displayCampainType
                  ?
                  <div style={{textAlign: "left", display: "flex", flexDirection: "column", paddingLeft: "20px", marginTop: "1em"}}>
                    <span className="bold">Type de campagne</span>
                    <span>
                    {
                      campainTypeName
                    }
                    </span>
                  </div>
                  :
                  null
                }
                {
                  displayCampainDate
                  ?
                  <div style={{textAlign: "left", display: "flex", flexDirection: "column", paddingLeft: "20px", marginTop: "1em"}}>
                    <span className="bold">Période de communication</span>
                    <span>
                    {
                      newCampainDateText
                    }
                    </span>
                  </div>
                  :
                  null
                }
                {
                  displaySpot
                  ?
                  <div style={{textAlign: "left", display: "flex", flexDirection: "column", paddingLeft: "20px", marginTop: "1em"}}>
                    <span className="bold">{!spotTvAlreadyAvailable ? "J'ai déjà un spot TV" : "Je n'ai pas de spot TV"}</span>
                    <span>
                    {
                      spotDuration === -1
                      ?
                      null
                      :
                      spotDuration === 0
                      ?
                      "Spot de 15 secondes"
                      :
                      spotDuration === 1
                      ?
                      "Spot de 20 secondes"
                      :
                      spotDuration === 2
                      ?
                      "Spot de 30 secondes"
                      :
                      null
                    }
                    </span>
                  </div>
                  :
                  null
                }
                </div>
              </div>
            </div>
          </div>
        </div>
      </Box>
      {
        1 === 2
        ?
        <SavedOrderModal open={isModalVisible}  />
        : null
      }
      {
        <Modal
          handleClose={() => closeModal(1)}
          open={isInfoModalVisible}
        />
      }
      {
        isCmdModalVisible === true ?
        <CmdModal
          handleClose={() => closeModal(2)}
          open={isCmdModalVisible}
          validateCmd={validateCmd}
          isCmdValidated={isCmdValidated}
          isCmdLoading={isCmdLoading}
        />
        :
        null
      }
    </Box>
  );
}
