function onClick(id, buttonClicked){
  buttonClicked("login");
}

function LoginLoadingButton(props) {
  return (
    <div className="registerButtonWrapper">
    {
      props.isLoaderVisible
      ?
      null
      :
      <button onClick={() => onClick("loginLoadingButton", props.onClick)} id="loginLoadingButton" className="btn btn-green btnRegister">
        {props.submitButtonText}
        <span id="loginloader"></span>
      </button>
    }
    </div>
  );
}

export default LoginLoadingButton;
