import Checkbox from '@mui/material/Checkbox';

function RegisterCheckbox(props) {
  return (
    <div className="registerCheckboxDiv" id={props.id} style={{marginLeft: "1px", marginRight: "1px"}}>
      <Checkbox
        color="primary"
        checked={props.checked}
        onChange={e => {
          props.updateCheckboxState(props.checkboxStateName, e.target.checked);
        }}
      />
      <p style={props.checkboxLabel === "Je suis une agence" ? {marginLeft: "8px", marginRight: "8px"} : {}}>{props.checkboxLabel}</p>
    </div>
  );
}

export default RegisterCheckbox;
