import React from 'react';
import Logo from "../assets/videoclub.png";
import LoginForm from '../components/forms/loginForm.js';
import LoginFormTitle from '../components/forms/formTitle.js'
import TypeWriter from '../components/typewriter.js';
import LoginRegisterAlerts from '../components/alerts/loginRegisterAlerts.js';
import Modal from '../components/modals/koEmailConfirmedModal.js';
import * as LocalStorage from '../services/localStorage';
import * as Api from '../services/userApi.js';
import * as Validation from '../services/validation.js';
import * as UAParser from 'ua-parser-js';
import {withRouter} from '../services/withRouter.js';
import "../css/app.css";

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const arrayIdForLoginAnimation = ['loginLoadingButton', 'loginCheckboxForgotPassword', 'loginPasswordTextfield', 'loginCheckbox', 'loginPasswordTextfield', 'loginEmailTextfield'];
const arrayIdForRegisterAnimation = ['registerLoadingButton', 'strongnessPassword', 'registerConfirmationtextfield', 'registerPasswordtextfield', 'registerEmailPhoneDiv', 'registerFirstLastnameDiv', 'registerCompanyDiv', 'registerStatusDiv'];

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      isLoginPage: true,
      Nom: "",
      Prénom: "",
      Email: "",
      password: "",
      confirmation: "",
      company: "",
      phone: "",
      submitButtonText: "Je me connecte",
      isUserAnnonceur: false,
      isUserPro : false,
      isStayConnectedActivated: true,
      displayedText: '',
      pageTitle: 'Connexion',
      greetingMsg: 'Bonjour !',
      firstDisplayGreetingMsg: true,
      updateFormButton: 'Créer un compte',
      animationIsRunning: false,
      isAlertVisible: false,
      isModalVisible: false,
      alertSeverity: "",
      alertText: "",
      alertColor: "",
      alertMultiple: false,
      passwordStrongness: 0,
      passwordError: "",
      isModalLoading: false,
      browserUsed: "",
      osUsed: ""
    };
  }

  componentDidMount() {
    setTimeout(() => this.changeAnimState(), 1000);
    const stayConnected = LocalStorage.getStayConnected();
    if (stayConnected === null) {
      LocalStorage.setStayConnected(true);
    }
    else {
      if (stayConnected === 'true'){
        this.setState({isStayConnectedActivated: true});
      }
      else {
        this.setState({isStayConnectedActivated: false});
      }
    }
    //set user browser & os info
    let parser = new UAParser();
    parser = parser.getResult();
    this.setState({browserUsed: parser.browser.name});
    this.setState({osUsed: parser.os.name});
  }

  //general function used to update state
  updateState = (stateName, value) => {
    this.setState({[stateName]: value});
    if (stateName === "password") {
      this.setState({passwordStrongness: this.checkPasswordStrongnessForDisplay(value)});
    }
  }

  testPassword() {
    if (this.state.password === "" || this.state.confirmation === "") {
      this.setState({alertText: "Merci de renseigner l'ensemble des champs du formulaire"});
      this.setState({alertColor: "error"});
      this.setState({alertSeverity: "error"});
      this.setState({alertMultiple: false});
      this.updateAlertState();
      return false;
    }
    if (this.state.password !== this.state.confirmation) {
      this.setState({alertText: "Les mots de passes ne correspondent pas"});
      this.setState({alertColor: "error"});
      this.setState({alertSeverity: "error"});
      this.setState({alertMultiple: false});
      this.updateAlertState();
      return false;
    }
    if (this.state.password.length < 8) {
      this.setState({alertText: "Votre mot de passe doit faire au minimum 8 caractères"});
      this.setState({alertColor: "error"});
      this.setState({alertSeverity: "error"});
      this.setState({alertMultiple: false});
      this.updateAlertState();
      return false;
    }
    let add = 0;
    let specialChars = "<>@!#$%^&*()_+[]{}?:;|'\"\\,./~`-=";
    for (let i = 0; i < specialChars.length; i++) {
      if (this.state.password.indexOf(specialChars[i]) > -1) {
        add = 1;
      }
    }
    if (add === 0) {
      this.setState({alertText: "Votre mot de passe doit comptenir au moins un caractère spécial"});
      this.setState({alertColor: "error"});
      this.setState({alertSeverity: "error"});
      this.setState({alertMultiple: false});
      this.updateAlertState();
      return false;
    }
    add = 0;
    specialChars = "0123456789";
    for (let i = 0; i < specialChars.length; i++) {
      if (this.state.password.indexOf(specialChars[i]) > -1) {
        add = 1;
      }
    }
    if (add === 0) {
      this.setState({alertText: "Votre mot de passe doit comptenir au moins un chiffre"});
      this.setState({alertColor: "error"});
      this.setState({alertSeverity: "error"});
      this.setState({alertMultiple: false});
      this.updateAlertState();
      return false;
    }
    add = 0;
    for (let i = 0; i < this.state.password.length; i++) {
      let tmp = this.state.password[i];
      if (tmp.toUpperCase() === tmp && tmp !== tmp.toLowerCase()) {
        add = 1;
      }
    }
    if (add === 0) {
      this.setState({alertText: "Votre mot de passe doit comptenir au moins un caractère en majuscule"});
      this.setState({alertColor: "error"});
      this.setState({alertSeverity: "error"});
      this.setState({alertMultiple: false});
      this.updateAlertState();
      return false;
    }
    return true;
  }

  //function used to check password strongness for display bar
  checkPasswordStrongnessForDisplay(value) {
    let strongness = 0;

    if (value.length > 8) {
      strongness += 1;
    }

    if (value.length > 12) {
      strongness += 1;
    }

    let add = 0;
    let specialChars = "<>@!#$%^&*()_+[]{}?:;|'\"\\,./~`-=";
    for (let i = 0; i < specialChars.length; i++) {
      if (value.indexOf(specialChars[i]) > -1) {
        add = 1;
      }
    }
    if (add === 0) {
      this.setState({passwordError: "Votre mot de passe doit comptenir au moins un caractère spécial"});
    }
    strongness += add;

    add = 0;
    specialChars = "0123456789";
    for (let i = 0; i < specialChars.length; i++) {
      if (value.indexOf(specialChars[i]) > -1) {
        add = 1;
      }
    }
    if (add === 0) {
      this.setState({passwordError: "Votre mot de passe doit comptenir au moins un chiffre"});
    }
    strongness += add;

    add = 0;
    for (let i = 0; i < value.length; i++) {
      let tmp = value[i];
      if (tmp.toUpperCase() === tmp && tmp !== tmp.toLowerCase()) {
        add = 1;
      }
    }
    if (add === 0) {
      this.setState({passwordError: "Votre mot de passe doit comptenir au moins un caractère en majuscule"});
    }
    return strongness += add;
  }

  //function called when login / register button are clicked
  buttonClicked = async () => {
    this.updateButtonState();
    if (!this.state.isLoginPage) {
      if (this.testPassword() === true) {
        await this.tryToSignUp();
      }
    }
    else {
      await this.tryToSignIn();
    }
    this.updateButtonState();
  }


  //Function used to update button text
  updateButtonState() {
    this.setState({isLoaderVisible: true});
    if (this.state.isLoginPage) {
      if (this.state.submitButtonText === "Chargement ") {
        this.setState({submitButtonText: "Je me connecte"});
      }
      else {
        this.setState({submitButtonText: "Chargement "});
      }
    }
    else {
      if (this.state.submitButtonText === "Chargement ") {
        this.setState({submitButtonText: "Je m'inscris !"});
      }
      else {
        this.setState({submitButtonText: "Chargement "});
      }
    }
    this.setState({isLoaderVisible: false});
  }

  //function used to try to log user in
  tryToSignIn = async () => {
    const data = {
      email: this.state.Email,
      password: this.state.password,
      browserUsed: this.state.browserUsed,
      osUsed: this.state.osUsed,
    }
    let ret = await Validation.checkDataForLogin(data);
    //validation failed
    if (!ret) {
      this.setState({alertText: "Merci de remplir l'ensemble des champs correctement"});
      this.setState({alertColor: "error"});
      this.setState({alertSeverity: "error"});
      this.setState({alertMultiple: false});
      this.updateAlertState();
    }
    else {
      ret = await Api.logUserIn(data);
      //console.log(ret.status)
      if (ret.status === 401) {
        if (ret.error === "KO emailConfirmed") {
          this.setState({isModalVisible: true});
          return;
        }
        else {
          this.setState({alertText: "Email ou mot de passe incorrect"});
          this.setState({alertMultiple: false});
        }
        this.setState({alertColor: "error"});
        this.setState({alertSeverity: "error"});
        this.updateAlertState();
      }
      else if (ret.status === 500) {
        this.setState({alertText: "Une erreur est survenue. Merci de réésayer plus tard"});
        this.setState({alertColor: "error"});
        this.setState({alertSeverity: "error"});
        this.setState({alertMultiple: false});
        this.updateAlertState();
      }
      //connection succed
      else if (ret.status === 200) {
        LocalStorage.setToken(ret.token);
        LocalStorage.setId(this.state.Email);
        this.props.handleUserConnection();
        this.props.handleUserId(this.state.Email);
      }
    }
  }

  //function used to try to register user
  tryToSignUp = async () => {
    let data = {
      email: this.state.email,
      isAgency: this.state.isUserAnnonceur,
      isPro: this.state.isUserPro,
      firstName: this.state.Prénom,
      lastName: this.state.Nom,
      company: this.state.company,
      phoneNumber: this.state.phone,
      password: this.state.password,
      confirmation: this.state.confirmation,
      emailConfirmed: false,
    }
    let ret = await Validation.checkDataForRegister(data);
    if (!ret) {
      this.setState({alertText: "Merci de remplir l'ensemble des champs correctement"});
      this.setState({alertColor: "error"});
      this.setState({alertSeverity: "error"});
      this.setState({alertMultiple: false});
      this.updateAlertState();
    }
    else {
      ret = await Api.registerUser(data);
      if (ret) {
        data = {
          userId: this.state.email,
        };
        ret = await Api.confirmEmail(data);
          if (ret === true) {
            this.setState({alertText: "Votre compte a bien été créé. Vous pouvez vous connecter"});
            this.setState({alertColor: "info"});
            this.setState({alertSeverity: "success"});
            this.setState({alertMultiple: false});
            this.updateAlertState();
            this.changePage();
          }
          else {
            this.setState({alertText: "Cette adresse email est déjà liée à un compte"});
            this.setState({alertColor: "error"});
            this.setState({alertSeverity: "error"});
            this.setState({alertMultiple: false});
            this.updateAlertState();
          }
        }
      else {
        this.setState({alertText: "Cette adresse email est déjà liée à un compte"});
        this.setState({alertColor: "error"});
        this.setState({alertSeverity: "error"});
        this.setState({alertMultiple: false});
        this.updateAlertState();
      }
    }
  }

  generateNewEmailValidationLink = async () => {
    this.setState({isModalLoading: true});
    const data = {
      userId: this.state.Email,
    };
    const ret = await Api.sendNewEmailConfirmation(data);
    if (ret === true) {
      this.setState({isAlertVisible: false}, function() {
        this.setState({alertText: "Un email vous a été envoyé pour valider la création de votre compte"});
        this.setState({alertColor: "info"});
        this.setState({alertSeverity: "success"});
        this.setState({alertMultiple: false});
        this.updateAlertState();
        this.setState({isModalVisible: false});
      });
    }
    else {
      this.setState({isAlertVisible: false}, function() {
        this.setState({alertText: "Une erreur s'est produite."});
        this.setState({alertColor: "error"});
        this.setState({alertSeverity: "error"});
        this.setState({alertMultiple: false});
        this.updateAlertState();
        this.setState({isModalVisible: false});
      });
    }
    this.setState({isModalLoading: false});
  }

  //function used to update register and login checkbox state
  updateCheckboxState = (checkboxName, checkboxValue) => {
    if (checkboxName === "isUserAnnonceur") {
      this.setState({isUserAnnonceur: checkboxValue});
      this.setState({isUserPro: !checkboxValue});
    }
    else if (checkboxName === "isUserPro") {
      this.setState({isUserPro: checkboxValue});
      this.setState({isUserAnnonceur: !checkboxValue});
    }
    else {
      this.setState({isStayConnectedActivated: checkboxValue});
      LocalStorage.setStayConnected(checkboxValue);
    }
  }

  //function used to display / hide alerts
  updateAlertState = () => {
    this.setState({isAlertVisible: !this.state.isAlertVisible});
  }

  //function used to update the tipewriter text
  updateWelcomeText = async (value) => {
    this.setState({displayedText: value});
    if (value.length === 1) {
      if (this.state.firstDisplayGreetingMsg) {
        this.setState({firstDisplayGreetingMsg: false});
      }
      else {
        this.child.current.addBlinkCursor();
        if (this.state.isLoginPage) {
          this.child.current.displayGreetingMsg('ienvenue !');
        }
        else {
          this.child.current.displayGreetingMsg('onjour !');
          this.setState({animationIsRunning: false});
        }
      }
    }
  }

  //function used to change page from login / register 1
  changePage = async (e) => {
    //e.preventDefault();
    if (this.state.animationIsRunning) {
      return;
    }
    this.setState({animationIsRunning: true});
    this.child.current.removeGreetingMsg();
    this.removeAnimationClasses(arrayIdForLoginAnimation);
    this.removeAnimationClasses(arrayIdForRegisterAnimation);
    if (this.state.isLoginPage) {
      this.addAnimationClass('animate__fadeOutDown', arrayIdForLoginAnimation).then(
        async (onResolve) => {
          this.updatePageState();
        }
      );
    }
    else {
      this.addAnimationClass('animate__fadeOutDown', arrayIdForRegisterAnimation).then(
        (onResolve) => {
          this.updatePageState();
        }
      );
    }
  }

  //function used to change page from login / register 2
  updatePageState = () => {
    if (this.state.isLoginPage) {
      this.setState({pageTitle: "Création de compte"});
      this.setState({updateFormButton: "Retour"});
      this.setState({submitButtonText: "Je m'inscris !"});
    }
    else {
      this.setState({pageTitle: "Connexion"});
      this.setState({updateFormButton: "Créer un compte"});
      this.setState({submitButtonText: "Je me connecte"});
    }
    this.changeDisplayedForm();
    this.setState({animationIsRunning: true});
    this.setState({isLoginPage: !this.state.isLoginPage});
  }

  //function used to change page from login / register 3
  changeDisplayedForm() {
    if (this.state.isLoginPage){
      const loginForm = document.getElementById('loginForm');
      const registerForm = document.getElementById('registerForm');
      const loginFormTitle = document.getElementById('loginFormTitle');
      const loginFormTitleMin = document.getElementById('loginFormTitleMin');

      loginForm.classList.remove('showFlex');
      loginForm.classList.add("hide");
      registerForm.classList.remove('hide');
      registerForm.classList.add("showFlex");

      loginFormTitle.classList.remove('showFlex');
      loginFormTitle.classList.add("hide");
      loginFormTitleMin.classList.remove('hide');
      loginFormTitleMin.classList.add("showFlex");
    }
    else {
      const loginForm = document.getElementById('loginForm');
      const registerForm = document.getElementById('registerForm');
      const loginFormTitle = document.getElementById('loginFormTitle');
      const loginFormTitleMin = document.getElementById('loginFormTitleMin');

      loginForm.classList.remove('hide');
      loginForm.classList.add("showFlex");
      registerForm.classList.remove('showFlex');
      registerForm.classList.add("hide");

      loginFormTitle.classList.remove('hide');
      loginFormTitle.classList.add("showFlex");
      loginFormTitleMin.classList.remove('showFlex');
      loginFormTitleMin.classList.add("hide");
    }
  }

  //upate state if animation is still running or not
  changeAnimState = () => {
    this.setState({animationIsRunning: !this.state.animationIsRunning}, () => {
      //alert(this.state.animationIsRunning);
    });

  }

  //function used to add animation class
  addAnimationClass = async (animation, array) => {
    const length = array.length;
    let obj;
    for (let i = 0; i < length; i++) {
      obj = document.getElementById(array[i]);
      obj.classList.add("animate__animated");
      obj.classList.add(animation);
      await sleep(220);
    }
  }

  //function used to remove animation class
  removeAnimationClasses = (array) => {
    const length = array.length;
    let obj;
    for (let i = 0; i < length; i++) {
      //console.log(array[i])
      obj = document.getElementById(array[i]);
      obj.classList.remove('animate__animated');
      obj.classList.remove('animate__fadeOutDown');
      obj.classList.remove('animate__fadeInUp');
    }
  }

  render() {
    return(
      <div className="contentWrapper animatedBackground">
        <div className="registerContentWrapper">
          <div className="registerCard">
            <div className="registerCardLeft">
              <div className="registerLogoCenterDiv">
                <div className="registerCenterWrapper">
                  <img src={Logo} alt="" className="registerLogo"/>
                  <p style={{color: "white", fontSize: '1.2em'}}>A seulement quelques clics de votre campagne publicitaire TV</p>
                </div>
              </div>
            </div>
            <div className="registerCardRight">
            <LoginRegisterAlerts {...this.state} onClose={this.updateAlertState} generateNewEmailValidationLink={this.generateNewEmailValidationLink}/>
            <Modal
              handleClose={() => this.setState({isModalVisible: false})}
              open={this.state.isModalVisible}
              generateNewEmailValidationLink={this.generateNewEmailValidationLink}
              isModalLoading={this.state.isModalLoading}
            />
              <div className="typewriterDiv" style={{zIndex: 0}}>
                <TypeWriter
                  ref={this.child}
                  updateWelcomeText={this.updateWelcomeText}
                  content={this.state.greetingMsg}
                  returnFunction={this.changeAnimState}
                />
              </div>
              <div style={this.state.isLoginPage ? {marginTop: "-1em", display: "flex", justifyContent: "center", textAlign: "center", flexDirection: "column", paddingLeft: "1em", paddingRight: "1em", marginBottom: "1em"} : {display: "none"}}>
                <p>Accès <span className="bold">100% gratuit</span> – <span className="bold">sécurisé</span> – et <span className="bold">simulations illimitées</span> de vos plans média TV.</p>
              </div>
              <LoginFormTitle {...this.state} changePage={this.changePage}/>
              <LoginForm
                {...this.state}
                navigate={this.props.navigate}
                updateCheckboxState={this.updateCheckboxState}
                buttonClicked={this.buttonClicked}
                updateState={this.updateState}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Login);
