import * as React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Fab from '@mui/material/Fab';
import CheckIcon from '@mui/icons-material/Check';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

export default function CircularIntegration(props) {
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);

  React.useEffect(() => {
    setLoading(props.isCmdLoading);
    if (props.isCmdValidated === 1) {
      setSuccess(true);
      setLoading(false);
    }
    else if (props.isCmdValidated === 0) {
      setError(true);
      setLoading(false);
    }
  });

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }} style={{cursor: "auto"}}>
      <Box sx={{ m: 1, position: 'relative' }} style={{cursor: "auto"}}>
        <Fab
          style={{cursor: "auto"}}
          aria-label={props.isCmdValidated ? '' : 'Chargement'}
          color="secondary"
        >
          {
            success ? <CheckIcon style={{color: 'white', cursor: 'auto'}} /> : error ? <ErrorOutlineOutlinedIcon style={{color: 'white', cursor: 'auto'}} /> : <PendingActionsOutlinedIcon fontSize="large" style={{color: 'white', cursor: 'progress'}}/>}
        </Fab>
        {loading && (
          <CircularProgress
            style={{cursor: "auto"}}
            size={68}
            sx={{
              color: '#CE93D8',
              position: 'absolute',
              top: -6,
              left: -6,
              zIndex: 1,
            }}
          />
        )}
      </Box>
    </Box>
  );
}
