export async function checkDataForRegister(data) {
  const emailRegex =  new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/);
  const phoneRegex =  new RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);

  if (data.isPro === false && data.isAgency === false) {
    return false;
  }
  if (data.email === "" || data.firstName === "" || data.lastName === "" || data.company === "" || data.phoneNumber === "" || data.password === "" || data.confirmation === "") {
    return false;
  }
  if (data.password !== data.confirmation) {
    return false;
  }
  if (!emailRegex.test(data.email)) {
    return false;
  }
  if (!phoneRegex.test(data.phoneNumber)) {
    return false;
  }
  //must add password strongness
  return true;
}

export async function checkDataForLogin(data) {
  if (data.email === "" || data.password === "") {
    return false;
  }
  return true;
}
