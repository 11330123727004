import * as React from 'react';
import Checkbox from '../checkboxs/registerCheckbox.js';
import Textfield from '../textfields/updateProfileTextfield.js';
import Autocomplete from '../textfields/addrAutocompleteTextfield.js';
import FullAddrAuto from '../textfields/fullAddrAutocompleteTextfield';
import TextfieldDeactivated from '../textfields/updateProfileTextfieldDeactivated.js';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Alert from '../alerts/updateProfileAlerts.js';
import "../../css/responsive.css";

function UpdateProfileForm(props) {

  return (
    <div className="">
      <form onSubmit={e => e.preventDefault()}>
        <div className="personalInfoContainer">
          <div className="personalInfoDiv">
            <Checkbox id="isUserAnnonceurCheckbox" checked={props.isUserAnnonceur} updateCheckboxState={props.handleCheckboxChange} checkboxStateName="isUserAnnonceur" checkboxLabel="Je suis un annonceur"/>
          </div>
          <div className="personalInfoDiv">
            <Checkbox id="isUserProCheckbox" checked={props.isUserPro} updateCheckboxState={props.handleCheckboxChange} checkboxStateName="isUserPro" checkboxLabel="Je suis une agence"/>
          </div>
        </div>
        <div className="personalInfoContainer">
          <Textfield className="registerTextfield" textFieldType="text" placeholder="Société" stateName="company" textfieldValue={props.company} updateState={props.updateState}/>
        </div>
        <div className="personalInfoContainer">
          <div className="personalInfoDiv" style={{paddingRight: "2px"}}>
            <Textfield className="updatePersoInfoTextfield" textFieldType="text" placeholder="Prénom" stateName="firstname" textfieldValue={props.firstName} updateState={props.updateState}/>
          </div>
          <div className="personalInfoDiv">
            <Textfield className="updatePersoInfoTextfield" textFieldType="text" placeholder="Nom" stateName="lastname" textfieldValue={props.lastName} updateState={props.updateState}/>
          </div>
        </div>
        <div className="personalInfoContainer">
          <Tooltip title="L'adresse email n'est pas modifiable" followCursor>
            <div className="personalInfoDiv" style={{paddingRight: "2px"}}>
              <TextfieldDeactivated className="updatePersoInfoTextfield" placeholder="Email" textFieldType="text" textfieldValue={props.email} />
            </div>
          </Tooltip>
          <div className="personalInfoDiv">
            <Textfield className="updatePersoInfoTextfield" textFieldType="text" placeholder="Téléphone" stateName="phone" textfieldValue={props.phone} updateState={props.updateState}/>
          </div>
        </div>
        <h2 style={{color: "lightgray", fontSize: "1em"}}>Votre adresse</h2>
        <hr style={{color: "lightgray"}} />
        <div className="" style={{marginTop: "2em"}}>
          <FullAddrAuto className="updatePersoInfoTextfield" textFieldType="text" placeholder="Addresse complète" stateName="fullAddr" value={props.fullAddr} updateState={props.updateState} {...props}/>
        </div>
        <div style={props.displayAddr ? {} : {display: "none"}}>
          <div className="personalInfoContainer">
            <div className="personalInfoDiv" style={{paddingRight: "2px"}}>
              <Textfield className="updatePersoInfoTextfield" textFieldType="text" placeholder="Numéro" stateName="addrNumber" textfieldValue={props.addrNumber} updateState={props.updateState} onChange={props.onAddrAutocompleteSelectedChanged}/>
            </div>
            <div className="personalInfoDiv">
              <Autocomplete {...props} placeholder="Rue" stateName="address" value={props.address} onChange={props.onAddrAutocompleteSelectedChanged} autocomplete={props.addrAutocomplete} isOpen={props.isSelectAddrOpen}/>
            </div>
          </div>
          <div className="personalInfoContainer">
            <div className="personalInfoDiv" style={{paddingRight: "2px"}}>
              <Autocomplete {...props} placeholder="Code postal" stateName="postalCode" value={props.postalCode} onChange={props.onPostalCodeAutocompleteSelectedChanged} autocomplete={props.postalCodeAutocomplete} isOpen={props.isSelectPostalCodeOpen}/>
            </div>
            <div className="personalInfoDiv">
              <Textfield className="updatePersoInfoTextfield" textFieldType="text" placeholder="Ville" stateName="city" textfieldValue={props.city} updateState={props.updateState}/>
            </div>
          </div>
        </div>
        <div className="personalInfoContainer" style={{justifyContent: "space-between"}}>
          <div className="personalInfoDiv">
            <Button className="updatePersoInfoTextfield" onClick={() => props.buttonClicked()} style={{height: "3.2em", border: "1px solid lightgray", color: '#ff66c4'}}>Modifier le profil</Button>
          </div>
          <div className="personalInfoDiv">
            <Button className="updatePersoInfoTextfield" onClick={() => props.goToUpdatePassword()} style={{height: "3.2em", border: "1px solid lightgray", color: '#00c2cb'}}>Changement mot de passe</Button>
          </div>
        </div>
        <div style={{marginTop: "-1em"}} className="animate__animated fadeInUp">
          <Alert id="updateProfileAlert" alertSeverity={props.alertSeverity} alertColor={props.alertColor} alertText={props.alertText} isAlertVisible={props.isAlertVisible} onClose={props.updateAlertState} />
        </div>
      </form>
    </div>
  );
}

export default UpdateProfileForm;
