import axios from 'axios';
import{ QuotationApiAddr } from './constant.js';

const apiAddr = QuotationApiAddr;

//validate user new cmd
export async function validateUserCmd(data) {
  try {
    const ret = await axios.post(apiAddr+'/validateUserCmd', data);
    return ret;
  }
  catch(error) {
    console.log('error', error);
    return false;
  }
}

//update user cmd
export async function updateUserCmd(data) {
  try {
    const ret = await axios.post(apiAddr+'/updateUserCmd', data);
    return ret;
  }
  catch(error) {
    console.log('error', error);
    return false;
  }
}

//update user cmd
export async function deleteUserCmd(data) {
  try {
    await axios.post(apiAddr+'/deleteUserCmd', data);
    return true;
  }
  catch(error) {
    console.log('error', error);
    return false;
  }
}

//get all user quotations
export async function getUserQuotations(data) {
  try {
    const ret = await axios.post(apiAddr+'/getUserQuotations', data);
    return ret;
  }
  catch(error) {
    console.log('error', error);
    return false;
  }
}

//get constants
export async function getConstants(data) {
  try {
    const ret = await axios.post(apiAddr+'/getConstants', data);
    return ret;
  }
  catch(error) {
    console.log('error', error);
    return false;
  }
}
