function onClick(id, buttonClicked){

  buttonClicked("register");
}

function RegisterLoadingButton(props) {
  return (
    <div className="registerButtonWrapper">
      <button onClick={() => onClick("registerLoadingButton", props.onClick)} id="registerLoadingButton" className="btn btn-green btnRegister">
        {props.submitButtonText}
        <span id="registerloader"></span>
      </button>
    </div>
  );
}

export default RegisterLoadingButton;
