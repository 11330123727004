import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const steps = [
  'Objectif',
  'Ciblage',
  'Campagne',
  'Vidéo',
  'Proposition'
];

export default function NewCampaignStepper(props) {
  return (
    <Box sx={{ width: '100%' }} style={{marginTop: "4em"}}>
      <Stepper activeStep={props.currentStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={label} onClick={() => props.displayStep(index)}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
