import React, { useEffect  } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import DateRangePicker from '../datePickers/newCampaignDatePicker';
import Slider from '../sliders/budgetSlider';
import OneLineCard from '../cards/newCampainCardsOneLine';

function returnFunction() {
  return;
}

async function validateAndGoNext(props) {
  await props.validateCmd(1);
  props.goNextStep();
}

function cardType(props) {
  return(
    <React.Fragment>
      <CardContent>
        <span className="cardText">
          Type de campagne
        </span>
        <Box component="form" noValidate autoComplete="off" style={{marginTop: "1em"}}>
          <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
            <span style={{width: "33%"}}>
              <OneLineCard {...props} id={1} item='campainType' onChange={props.budget < 5000 ? () => returnFunction() : props.handleOptionChange} value={props.campaignType === 1 ? true : false} cardText={props.campainTypeArray[1].name} tooltipText={props.campainTypeArray[1].description} tooltipText={props.campainTypeArray[0].description} backgroundColor={props.budget >= 5000 ? "white" : "gainsboro"}/>
            </span>
            <span style={{width: "33%"}}>
              <OneLineCard {...props} id={0} item='campainType' onChange={props.handleOptionChange} value={props.campaignType === 0 ? true : false} cardText={props.campainTypeArray[0].name} tooltipText={null}/>
            </span>
            <span style={{width: "33%"}}>
              <OneLineCard {...props} id={2} item='campainType' onChange={props.budget < 8000 ? () => returnFunction() : props.handleOptionChange} value={props.campaignType === 2 ? true : false} cardText={props.campainTypeArray[2].name} tooltipText={props.campainTypeArray[2].description} backgroundColor={props.budget >= 8000 ? "white" : "gainsboro"}/>
            </span>
          </div>
        </Box>
      </CardContent>
    </React.Fragment>
  );
}

function cardBudget(props) {
  return(
    <React.Fragment>
      <CardContent>
        <span className="cardText">
          Votre budget
        </span>
        <Box component="form" noValidate autoComplete="off">
          <div style={{width: "100%", display: "flex", flexDirection: "column", marginTop: "1.5em"}}>
            <div style={{width: "60%", padding: "20px", marginRight: "20px", marginBottom: "1em"}}>
              <Slider {...props} onChange={(e) => props.handleBudget(e.target.value)} value={props.budget}/>
            </div>
            <div className="" id={props.id} style={{justifyContent: "center", display: "flex", position: "relative", marginBottom: "1em", width: "20em"}}>
              <div style={{display: "flex", justifyContent: "center", flexDirection: "column"}}>
                <span className="" style={{marginBottom: "1em", fontSize: "0.9em", textAlign: "center"}}> Vous avez un budget de plus de 50 000€ ? </span>
                <Button className="pinkButton" onClick={() => alert('Not implemented yet')} style={{minWidth: "22em"}}><span style={{fontSize: "0.9em"}}>Nous contacter</span></Button>
              </div>
            </div>
          </div>
        </Box>
      </CardContent>
    </React.Fragment>
  );
}

function cardPeriod(props) {
  return(
    <React.Fragment>
      <CardContent>
        <span className="cardText">
          Période de communication
        </span>
        <Box component="form" noValidate autoComplete="off" style={{marginTop: "1em"}}>
          <p className='italic'>Votre campagne peut démarrer <span className="bold">au plus tôt sous 15 jours</span> et elle peut avoir une <span className="bold">durée maximale de 28 jours.</span></p>
          <DateRangePicker {...props} handleDateChange={props.handleDateChange}/>
        </Box>
      </CardContent>
    </React.Fragment>
  );
}

function Step2(props) {

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div>
      <Box sx={{ minWidth: 275 }} style={{marginBottom: "1em"}}>
        <Card {...props} variant="outlined">{cardBudget(props)}</Card>
      </Box>
      <Box sx={{ minWidth: 275 }} style={{marginBottom: "1em"}}>
        <Card id="step2CampaignType" {...props} variant="outlined">{cardType(props)}</Card>
      </Box>
      <Box sx={{ minWidth: 275 }} style={{marginBottom: "1em"}}>
        <Card id="step2CampaignDate" {...props} variant="outlined">{cardPeriod(props)}</Card>
      </Box>
      <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
        <Button variant="white" onClick={() => props.goPrevStep()}>Étape précédente</Button>
        <Button variant="white" onClick={() => validateAndGoNext(props)}>Étape suivante</Button>
      </div>
    </div>
  );
}

export default Step2;
