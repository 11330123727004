import React, { useEffect  } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const regions = [
  'Auvergne-Rhône-Alpes',
  'Bourgogne-Franche-Comté',
  'Bretagne',
  'Centre-Val de Loire',
  'Corse',
  'Grand Est',
  'Guadeloupe',
  'Guyane',
  'Hauts-de-France',
  'Ile-de-France',
  'La Réunion',
  'Martinique',
  'Mayotte',
  'Normandie',
  'Nouvelle-Aquitaine',
  'Occitanie',
  'Pays de la Loire',
  "Provence-Alpes-Cote d'Azur",

];

export default function MultipleSelectCheckmarks(props) {
  const [personName, setPersonName] = React.useState([]);

  useEffect(() => {
    initValues();
  }, []);

  const initValues = () => {
    setPersonName(
      typeof props.geoAreaOptionsArray === 'string' ? props.geoAreaOptionsArray.split(',') : props.geoAreaOptionsArray,
    );
  }

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    props.setGeoAreaOptionsArray(typeof value === 'string' ? value.split(',') : value);
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
      props.handleSelectRegionChange(value)
    );

  };

  const isItemSelected = (personName, name) => {
    return personName.indexOf(name) > -1;
  }

  const scrollToOptions = () => {
    setTimeout(function() {
      document.getElementById("step1Target").scrollIntoView({ behavior: 'smooth'});
    }, 100);
  }

  return (
    <div id="regionText" style={{marginTop: "1.5em", display: "flex", flexDirection: "column"}}>
    <span className="bold" style={{paddingLeft: "10px"}}>Selectionner votre / vos région(s)</span>
    <FormControl sx={{ m: 1}} style={{width: "50%"}}>
        <InputLabel id="demo-simple-select-label">Région</InputLabel>
        <Select
          style={{width: "100%"}}
          labelId="demo-multiple-checkbox-label"
          id="demo-simple-checkbox"
          multiple
          label='Région'
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput label="Région" />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
          onClose={() => scrollToOptions()}
        >
          {regions.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={isItemSelected(personName, name)} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
