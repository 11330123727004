import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

function Alerts(props) {
  return (
    <div style={{width: "100%", textAlign:"center", zIndex: 1}}>
      <Stack sx={{ width: '100%', height: "4em"}} spacing={2}>
        {
          props.isAlertVisible
          ?
          props.alertMultiple
          ?
          <Alert severity={props.alertSeverity} color={props.alertColor}
            action={
              <div>
                <Button color="inherit" size="small" onClick={props.closeAndQuit}>
                  <span style={{fontWeight: "bold"}}>X</span>
                </Button>
              </div>
            }
          >
          {props.alertText}
          </Alert>
          :
          <Alert severity={props.alertSeverity} color={props.alertColor}
            action={
              <Button color="inherit" size="small" onClick={props.onClose}>
                <span style={{fontWeight: "bold"}}>X</span>
              </Button>
            }
          >
            {props.alertText}
          </Alert>
          :
          null
        }
      </Stack>
    </div>
  );
}

export default Alerts;
