import React, {} from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Thumbnail from '../../assets/era.png';
import Video from '../../assets/videos/ERA_IMMOBILIER.mp4';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  height: "90vh",
  bgcolor: 'transparent',
  p: 4,
};

export default function TransitionsModal(props) {

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box sx={style}>
          {
            props.videoId === 1
            ?
            <iframe style={{width: "100%", height: "100%"}} allow='autoplay;fullscreen' title="iframe 1" src="https://www.youtube.com/embed/Zu2DUo10pm8?autoplay=1"></iframe>
            :
            props.videoId === 2
            ?
            <iframe style={{width: "100%", height: "100%"}} allow='autoplay;fullscreen' title="iframe 2" src="https://www.youtube.com/embed/R9OgJxiWcho?autoplay=1"></iframe>
            :
            props.videoId === 3
            ?
            <video id="video 3" controls autoplay poster={Thumbnail} style={{width: "100%", height: "100%"}}><source  src={Video} type="video/mp4"/></video>
            :
            props.videoId === 4
            ?
            <iframe style={{width: "100%", height: "100%"}} allow='autoplay;fullscreen' title="iframe 4" src="https://www.youtube.com/embed/i5g_00iSZGs?autoplay=1"></iframe>
            :
            props.videoId === 5
            ?
            <iframe style={{width: "100%", height: "100%"}} allow='autoplay;fullscreen' title="iframe 5" src="https://www.youtube.com/embed/kWf9MLbr5bM?autoplay=1"></iframe>
            :
            null
          }
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
