import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { styled } from "@mui/material/styles";

const distanceMarks = [
  {
    value: 5,
    label: '5 km',
  },
  {
    value: 100,
    label: '100 km',
  },
];

const timeMarks = [
  {
    value: 5,
    label: '5 min',
  },
  {
    value: 120,
    label: '2h',
  },
];


function valuetext(props) {
  if (props.customLocationType === 1) {
    if (props.value < 5) {
      return "5 km";
    }
    return props.value + ' km';
  }
  else {
    if (props.value < 5) {
      return '5 min';
    }
    let hours = Math.trunc(props.value / 60);
    let minutes = props.value % 60;
    if (hours < 1) {
      return props.value + ' min'
    }
    else if (hours === 1 && minutes === 0) {
      return '1h';
    }
    else if (hours === 2) {
      return '2h';
    }
    else {
      return hours + 'h' + minutes;
    }
  }
}

const CustomSlider = styled(Slider)(({ theme }) => ({
  color: '#00c2cb',
  "& .MuiSlider-thumb": {
    backgroundColor: '#00c2cb'
  },
  "& .MuiSlider-thumb:hover": {
    color: 'rgba(0, 194, 203, 1) !important',
    backgroundColor: 'rgba(0, 194, 203, 1) !important',
    boxShadow: '0px 0px 0px 14px rgba(0, 194, 203, 0.2) !important',
  },
  "& .MuiSlider-rail": {
    color: '#00c2cb'
  },
  "& .MuiSlider-track": {
    color: '#00c2cb !important',
    backgroundColor: '#00c2cb !important'
  },
  "& .Mui-active": {
    boxShadow: '#00c2cb !important'
  },
  "& .css-by71oc.Mui-active": {
    color: 'rgba(0, 194, 203, 1) !important',
    backgroundColor: 'rgba(0, 194, 203, 1) !important',
    boxShadow: '0px 0px 0px 14px rgba(0, 194, 203, 0.2) !important',
  },
  "& .css-by71oc.Mui-focusVisible": {
    display: 'none !important'
  }
}));

export default function DiscreteSliderLabel(props) {
  return (
    <Box style={{display: "flex", justifyContent: "center", marginTop: "2em"}}>
      <div style={{width: "95%"}}>
        <CustomSlider
          aria-label="Always visible"
          value={props.value}
          onChange={props.onChange}
          step={props.customLocationType === 1 ? 1 : 5}
          marks={props.customLocationType === 1 ? distanceMarks : timeMarks}
          valueLabelDisplay="on"
          min={5}
          max={props.customLocationType === 1 ? 100 : 120}
          valueLabelFormat={valuetext(props)}
        />
      </div>
    </Box>
  );
}
