function ForgotPasswordButton(props) {
  return (
    <div className="registerButtonWrapper">
    {
      props.isLoaderVisible
      ?
      null
      :
      <button onClick={() => props.onClick()} id="" className="btn btn-green" style={{marginTop: "2em", marginLeft: "auto", marginRight: "auto"}}>
        {props.submitButtonText}
      </button>
    }
    </div>
  );
}

export default ForgotPasswordButton;
