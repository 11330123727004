import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


export default function MultipleSelectCheckmarks(props) {

  return (
    <div style={{marginTop: "1.5em", display: "flex"}}>
      <Autocomplete
        className="updatePersoInfoTextfield"
        disablePortal
        id="combo-box-demo"
        noOptionsText="Pas de résultat"
        options={props.postalCode}
        onChange={(e) => props.handleItemSelectChange(e.target.innerText, 'postalCode')}
        style={{width: "50%", marginTop: "auto", marginBottom: "auto"}}
        renderInput={(params) => <TextField {...params} className="registerTextfield" label="Rechercher un code postal" variant="outlined" value={props.value} onChange={(e) => {props.updateState(props.stateName, e.target.value)}}/> }
      />
      <FormControl sx={{ m: 1}} style={{width: "50%"}}>
        <InputLabel id="demo-multiple-checkbox-label">{props.selectedPostalCode.length === 0 ? 'Code postaux sélétionés' : ''}</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={props.selectedPostalCode}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >

          {props.selectedPostalCode.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={true} onChange={() => props.removeSelectedItem(name, 'postalCode')}/>
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
