import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectCustomTarget(props) {
  return (
    <Box sx={{ minWidth: 120 }} style={{width: "100%", marginTop: "0.5em"}}>
      <FormControl fullWidth>
        <InputLabel id="">Distance par km ou par temps de trajet</InputLabel>
        <Select
          labelId=""
          id=""
          value={props.customLocationType}
          label="Distance par km ou par temps de trajet"
          defaultOpen={false}
          onChange={(event) => props.handleCustomTargetSelectChange(event.target.value)}
        >
          <MenuItem value={1}>Distance en km</MenuItem>
          <MenuItem value={2}>Distance en temps/trajet</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
