import TextField from '@mui/material/TextField';

function ForgotPasswordTextfield(props) {

  return (
    <div id={props.id} className="" style={{marginTop: "1em"}}>
      <TextField
        style={{width: "100%"}}
        type={props.textFieldType}
        label={props.placeholder}
        variant="outlined"
        value={props.textfieldValue}
        onChange={(e) => {props.updateState(props.stateName, e.target.value)}}
      />
    </div>
  );
}

export default ForgotPasswordTextfield;
