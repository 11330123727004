import RegisterTextfield from '../../components/registerTextfields.js';
import RegisterLoadingButton from '../../components/buttons/registerLoadingButton.js';
import LoginCheckbox from '../../components/checkboxs/loginCheckbox.js';
import RegisterCheckbox from '../../components/checkboxs/registerCheckbox.js';
import LoginLoadingButton from '../../components/buttons/loginLoadingButton.js';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import "../../css/responsive.css";

function displayPasswordStrongness(strongness) {
  switch (strongness) {
    case 0:
      return 0;
      break;
    case 1:
      return 20;
      break;
    case 2:
      return 40;
      break;
    case 3:
      return 60;
      break;
    case 4:
      return 80;
      break;
    case 5:
      return 100;
      break;
    default:
      return 0;
  }
}

function LoginForm(props) {
  return (
    <div className="loginRegisterFormWrapper">
      <form id="loginForm" className="LoginForm showFlex" onSubmit={e => e.preventDefault()}>
        <RegisterTextfield id="loginEmailTextfield" textFieldType="text" placeholder="Email" stateName="Email" textfieldValue={props.Email} updateState={props.updateState}/>
        <RegisterTextfield id="loginPasswordTextfield" textFieldType="password" placeholder="Mot de passe" stateName="password" textfieldValue={props.password} updateState={props.updateState}/>
        <div id="loginCheckboxForgotPassword">
          <LoginCheckbox hideAnimation={props.hideAnimation} checked={props.isStayConnectedActivated} updateCheckboxState={props.updateCheckboxState} checkboxStateName="isStayConnectedActivated" checkboxLabel="Rester connecté"/>
          <a id="forgotPasswordHref" href="" onClick={() => props.navigate('/recuperation-mot-de-passe')}>Mot de passe oublié ?</a>
        </div>
        <LoginLoadingButton onClick={props.buttonClicked} submitButtonText={props.submitButtonText}/>
      </form>
      <div style={{padding: "10px"}}>
        <form id="registerForm" className="registerForm hide" onSubmit={e => e.preventDefault()}>
          <div id="registerStatusDiv" className="gridDouble" style={{marginBottom: "1em"}}>
            <RegisterCheckbox id="isUserAnnonceurCheckbox" checked={props.isUserAnnonceur} updateCheckboxState={props.updateCheckboxState} checkboxStateName="isUserAnnonceur" checkboxLabel="Je suis un annonceur"/>
            <RegisterCheckbox id="isUserProCheckbox" checked={props.isUserPro} updateCheckboxState={props.updateCheckboxState} checkboxStateName="isUserPro" checkboxLabel="Je suis une agence"/>
          </div>
          <div id="registerCompanyDiv" className="gridSimple" style={{marginTop: "0.5em"}}>
            <RegisterTextfield id="registerCompanyTestfield" textFieldType="text" placeholder="Société" stateName="company" textfieldValue={props.company} updateState={props.updateState}/>
          </div>
          <div id="registerFirstLastnameDiv" className="gridDouble">
            <RegisterTextfield id="registerFirstNametextfield" textFieldType="text" placeholder="Nom" stateName="Nom" textfieldValue={props.Nom} updateState={props.updateState}/>
            <RegisterTextfield id="registerLastNametextfield" textFieldType="text" placeholder="Prénom" stateName="Prénom" textfieldValue={props.Prénom} updateState={props.updateState}/>
          </div>
          <div id="registerEmailPhoneDiv" className="gridDouble" style={{marginBottom: "1em"}}>
            <RegisterTextfield id="registerEmailtextfield" textFieldType="text" placeholder="Email" stateName="email" textfieldValue={props.email} updateState={props.updateState}/>
            <RegisterTextfield id="registerPhonetextfield" textFieldType="text" placeholder="Téléphone" stateName="phone" textfieldValue={props.phone} updateState={props.updateState}/>
          </div>
          <RegisterTextfield id="registerPasswordtextfield" textFieldType="password" placeholder="Mot de passe" stateName="password" textfieldValue={props.password} updateState={props.updateState}/>
          <RegisterTextfield id="registerConfirmationtextfield" textFieldType="password" placeholder="Confirmation du mot de passe" stateName="confirmation" textfieldValue={props.confirmation} updateState={props.updateState}/>
          <div id="strongnessPassword" style={{display: "flex", justifyContent: 'space-between', marginTop: "1em"}}>
            <div style={{width: "10%"}}>
              <span style={props.passwordStrongness === 0 ? {fontWeight: 'bold'} : {}}>Faible</span>
            </div>
            <Box sx={{ width: '70%', marginTop: "auto", marginBottom: "auto" }}>
              <LinearProgress variant="determinate" value={displayPasswordStrongness(props.passwordStrongness)} />
            </Box>
            <div style={{width: "10%"}}>
              <span style={props.passwordStrongness > 3 ? {fontWeight: 'bold'} : {}}>Fort</span>
            </div>
          </div>
          <RegisterLoadingButton onClick={props.buttonClicked} submitButtonText={props.submitButtonText}/>
        </form>
      </div>
    </div>
  );
}

export default LoginForm;
