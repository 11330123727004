//function used to set user personal information
export function setSessionField(field, value) {
  sessionStorage.setItem(field, value);
}

export function getSessionField(field) {
  const ret = sessionStorage.getItem(field);
  if (ret !== null) {
    return ret;
  }
  return -1;
}
