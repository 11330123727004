import axios from 'axios';
const apiAddrHost = "https://api-adresse.data.gouv.fr/search/?q=+";
//
export async function getAddressFromPublicApi(addr) {
  try {
    const ret = await axios.get(apiAddrHost+addr)
    return ret;
  }
  catch(error) {
    console.log('error', error);
    return false;
  }
}

export async function getCityFromPublicApi(addr) {
  try {
    const ret = await axios.get(apiAddrHost+addr+'&type=municipality')
    return ret;
  }
  catch(error) {
    console.log('error', error);
    return false;
  }
}
