import React, { useEffect  } from 'react';
import Step0	from './step0';
import Step1	from './step1';
import Step2	from './step2';
import Step3	from './step3';
import Step4	from './step4';

function StepperHandler(props) {

  useEffect(() => {
  }, []);

  return(
    <div>
    {
      props.currentStep === 0 ?
      <Step0 {...props}/>
      :
      props.currentStep === 1 ?
      <Step1 {...props}/>
      :
      props.currentStep === 2 ?
      <Step2 {...props}/>
      :
      props.currentStep === 3 ?
      <Step3 {...props}/>
      :
      props.currentStep === 4 ?
      <Step4 {...props}/>
      :
      null
    }
    </div>
  );
}

export default StepperHandler;
