import React from 'react';
import * as Api from '../../services/quotationApi.js';
import * as LocalStorage from '../../services/localStorage.js';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import Modal from '../modals/orderDetails';
import ValidateModal from '../modals/validateDeleteCmdModal';

const columns: Column[] = [
  {
    id: 'CmdId',
    label: 'Référence',
    align: 'left',
    width: "auto",
    isIcon: false,
  },
  {
    id: 'CompanyName',
    label: "Nom de la marque",
    align: 'center',
    width: "18%",
    isIcon: false,
  },
  {
    id: 'ProductName',
    label: 'Nom du produit',
    align: 'center',
    width: "18%",
    isIcon: false,
  },
  {
    id: 'Date',
    label: 'Période de communication',
    align: 'center',
    width: "18%",
    isIcon: false,
  },
  {
    id: 'Type',
    label: 'Type de diffusion',
    align: 'center',
    width: "18%",
    isIcon: false,
  },
  {
    id: 'Price',
    label: 'Budget',
    align: 'center',
    width: "18%",
    isIcon: false,
  },
  {
    id: 'icon',
    label: '',
    align: 'right',
    width: "auto",
    isIcon: true,
  },
];

//const orders = [];

export default function StickyHeadTable(props) {
  const [page, setPage] = React.useState(0);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [startDate, setStartDate] = React.useState('');
  const [stopDate, setStopDate] = React.useState('');
  const [budget, setBudget] = React.useState('');
  const [campaignType, setCampaignType] = React.useState('');
  const [communitationObjective, setCommunitationObjective] = React.useState('');
  const [spotDuration, setSpotDuration] = React.useState('');
  const [status, setStatus] = React.useState('');
  const [targetCSP, setTargetCSP] = React.useState('');
  const [targetConsoTV, setTargetConsoTV] = React.useState('');
  const [targetData, setTargetData] = React.useState('');
  const [targetGeolife, setTargetGeolife] = React.useState('');
  const [targetHouseholdComposition, setTargetHouseholdComposition] = React.useState('');
  const [targetHousingType, setTargetHousingType] = React.useState('');
  const [targetInterest, setTargetInterest] = React.useState('');
  const [targetMomentMarketing, setTargetMomentMarketing] = React.useState('');
  const [targetSex, setTargetSex] = React.useState('');
  const [targetSexAge, setTargetSexAge] = React.useState('');
  const [geoArea, setGeoArea] = React.useState('');
  const [geoAreaOptions, setGeoAreaOptions] = React.useState([]);
  const [commercialName, setCommercialName] = React.useState('');
  const [productName, setProductName] = React.useState('');
  const [spotTvAlreadyAvailable, setSpotTvAlreadyAvailable] = React.useState('');
  const [spotId, setSpotId] = React.useState('');
  const [cmdId, setCmdId] = React.useState('');
  const [isValidateModalVisible, setIsValidateModalVisible] = React.useState(false);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const openModal = (props, cmdId) => {
    setSelectedCmdFunction(props, cmdId);
    setIsModalVisible(true);
  }

  const closeModal = () => {
    setIsModalVisible(false);
  }

  const closeModalValidate = () => {
    setIsValidateModalVisible(false);
  }

  const openModalValidate = () => {
    setIsValidateModalVisible(true);
  }

  const closeAllModals = () => {
    setIsModalVisible(false);
    setIsValidateModalVisible(false);
  }

  async function deleteUserCmd(cmdId) {
    let id = cmdId.split("-");
    id = id[1];
    const token = LocalStorage.getToken();
    const userId = LocalStorage.getId();
    const data = {
      token: token,
      userId: userId,
      cmdId: id,
    };
    const ret = await Api.deleteUserCmd(data);
    if (ret === true) {
      closeModal();
      window.location.reload(false);
    }
  }

  function handleGeoArea(geoArea) {
    if (geoArea.length > 0) {
      let tmp = geoArea.split(":");
      setGeoArea(tmp[0]);
      if (tmp[1]) {
        tmp = tmp[1].split(',');
        let array = [];
        for (let i = 0; i < tmp.length; i++) {
          if (array[i] !== ':') {
            if (tmp[i].charAt(0) === ' ') {
              tmp[i] = tmp[i].substring(1);
            }
            array.push(tmp[i]);
          }
        }
        return array;
      }
    }
    return [];
  }

  const setSelectedCmdFunction = (props, cmdId) => {
    const cmd = getSelectedCmd(props.userQuotations, cmdId);
    setStartDate(cmd.startDate);
    setStopDate(cmd.stopDate);
    setBudget(cmd.budget);
    setCampaignType(cmd.fk_campainType);
    setCommunitationObjective(cmd.fk_communicationObjective);
    setSpotDuration(cmd.fk_spotDuration);
    setStatus(cmd.fk_status);
    setTargetCSP(cmd.fk_targetCSP);
    setTargetConsoTV(cmd.fk_targetConsoTV);
    setTargetData(cmd.fk_targetData);
    setTargetGeolife(cmd.fk_targetGeolife);
    setTargetHouseholdComposition(cmd.fk_targetHouseholdComposition);
    setTargetHousingType(cmd.fk_targetHousingType);
    setTargetInterest(cmd.fk_targetInterest);
    setTargetMomentMarketing(cmd.fk_targetMomentMarketing);
    setTargetSex(cmd.fk_targetSex);
    setTargetSexAge(cmd.fk_targetSexAge);
    setProductName(cmd.productName);
    setCommercialName(cmd.commercialName);
    setSpotTvAlreadyAvailable(cmd.spotTvAlreadyAvailable);
    setSpotId(cmd.spotId);
    setCmdId(cmdId);
    setGeoAreaOptions(handleGeoArea(cmd.geoArea));
  }

  function getSelectedCmd(userQuotations, id) {
    id = id.split('-');
    for (let i = 0; i < userQuotations.length; i++) {
      if (userQuotations[i].Id.toString() === id[1]) {
        return userQuotations[i];
      }
    }
    return null;
  }

  return (
    <div>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ height: "73vh" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ width: column.width, backgroundColor: "black" }}
                  >
                    <span className="bold" style={{color: "white"}}>{column.label}</span>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.orders
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.Date}>
                      {columns.map((column, index) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {
                              column.isIcon
                              ?
                              <ZoomOutMapIcon
                                className="orderDetailsIcon"
                                onClick={() => openModal(props, row.CmdId)}
                              />
                              :
                              <span style={index === 0 ? {fontWeight: "bold"} : {}}>{value}</span>
                            }
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[15, 30, 100]}
          component="div"
          count={props.orders.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Commandes par page"
        />
      </Paper>
      <Modal
        handleClose={closeModal}
        open={isModalVisible}
        startDate={startDate}
        stopDate={stopDate}
        budget={budget}
        campaignType={campaignType}
        communitationObjective={communitationObjective}
        spotDuration={spotDuration}
        status={status}
        targetCSP={targetCSP}
        targetConsoTV={targetConsoTV}
        targetData={targetData}
        targetGeolife={targetGeolife}
        targetHouseholdComposition={targetHouseholdComposition}
        targetHousingType={targetHousingType}
        targetInterest={targetInterest}
        targetMomentMarketing={targetMomentMarketing}
        targetSex={targetSex}
        targetSexAge={targetSexAge}
        geoArea={geoArea}
        geoAreaOptions={geoAreaOptions}
        productName={productName}
        commercialName={commercialName}
        spotTvAlreadyAvailable={spotTvAlreadyAvailable}
        spotId={spotId}
        cmdId={cmdId}
        openModalValidate={openModalValidate}
        handleFinishCmd={props.handleFinishCmd}
      />
      <ValidateModal
        handleClose={closeModalValidate}
        open={isValidateModalVisible}
        deleteUserCmd={deleteUserCmd}
        cmdId={cmdId}
        closeAllModals={closeAllModals}
      />
    </div>
  );
}
