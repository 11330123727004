import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Sport from '../../assets/files/sport.pdf';
import TvFamilly from '../../assets/files/TvFamilly.pdf';
import FirstTime from '../../assets/files/FirstTime.pdf';
import Geoloc from '../../assets/files/geoloc.pdf';
import Prime from '../../assets/files/prime.pdf';
import GenerationYZ from '../../assets/files/generationYZ.pdf';
import Info from '../../assets/files/info.pdf';
import Foot from '../../assets/files/foot.pdf';
import Rugby from '../../assets/files/rugby.pdf';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minwidth: "20em",
  maxWidth: "1100px",
  width: '100vw',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
  maxHeight: "90% !important",
  height: 'auto'
};

function getFileRelatedToId(id) {
  switch (id) {
    case 0:
      return FirstTime;
      break;
    case 1:
      return Geoloc;
      break;
    case 2:
      return Prime;
      break;
    case 3:
      return Sport;
      break;
    case 4:
      return TvFamilly;
      break;
    case 5:
      return GenerationYZ;
      break;
    case 6:
      return Info;
      break;
    default:
      return null;
  }
}

function getFileNameRelatedToId(id) {
  switch (id) {
    case 0:
      return "Pack First Time TV - VIDEOCLUB";
      break;
    case 1:
      return "Pack Géoloc TV - VIDEOCLUB";
      break;
    case 2:
      return "Pack 100% Prime - VIDEOCLUB";
      break;
    case 3:
      return 'Pack 100_ Sport - VIDEOCLUB';
      break;
    case 4:
      return "Pack Family TV - VIDEOCLUB";
      break;
    case 5:
      return "Pack Génération YZ - VIDEOCLUB";
      break;
    case 6:
      return "Pack Info & Actualités - VIDEOCLUB";
      break;
    default:
      return null;
  }
}

function getPopupContent3(props) {
  return(
    <React.Fragment>
      <Typography id="transition-modal-title" variant="h6" component="h2" style={{marginTop: "2em"}}>
        <span className="bold homeModalTitle">{props.cardsInfo.cardTitle}</span>
      </Typography>
      <div style={{display: "flex", justifyContent: "center", textAlign: "center", flexDirection: "column"}}>
        <Typography sx={{ mt: 2 }}>
          <span className="">Vous souhaitez faire de la publicité TV à la mi-temps des matchs de votre club ? Ou tout simplement communiquer sur les plus grandes chaînes sportives ?</span>
        </Typography>
        <Typography sx={{ mt: 2 }}>
          <span className="">Positionnez-vous en contexte et accédez aux plus gros évènements et compétitions sportives diffusées en TV.</span>
        </Typography>
        <Typography sx={{ mt: 2 }}>
          <span className="">Toutes les compétitions, tous les matchs accessibles depuis notre offre sport &amp; 100% club.</span>
        </Typography>
        <div style={{marginTop: "1em", marginBottom: "2em"}}>
          <Typography sx={{ mt: 2 }}>
            <span className="bold">2 offres accessibles à petit prix :</span>
          </Typography>
          <Typography sx={{ mt: 2 }} style={{marginBottom: "-0.5em"}}>
            <span className="" style={{fontStyle: "italic"}}> ○ Une offre globale pour être diffusé sur les plus grandes chaînes de sport</span>
          </Typography>
          <Typography sx={{ mt: 2 }}>
            <span className="" style={{fontStyle: "italic"}}> ○ Une offre 100% club pour être au plus près de votre club</span>
          </Typography>
        </div>
        <div style={{marginTop: "1em", marginBottom: "1em", display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
          <div id="leftHomeModalCard">
            <Typography sx={{ mt: 2 }} style={{marginBottom: "1em"}}>
              <span className="bold" style={{fontSize: "0.9em"}}>Offre Sport</span>
            </Typography>
            <Typography sx={{ mt: 2 }} style={{marginBottom: "1em"}}>
              <span className="" style={{fontSize: "0.9em"}}>Diffusez votre pub TV sur les plus grandes chaînes sportives avec la possibilité de choisir vos compétitions (L’équipe, Bein Sports, Canal +, Amazon Prime (mettre les logos des chaînes en dessous en petit)</span>
            </Typography>
            <Typography sx={{ mt: 2 }}>
              <span className="" style={{fontSize: "0.9em"}}>A partir de 10 000 euros</span>
            </Typography>
            <a href={getFileRelatedToId(props.selectedId)} download={getFileNameRelatedToId(props.selectedId)} target='_blank' rel="noreferrer" style={{width: '80%', textDecoration: "none", marginLeft: "auto", marginRight: "auto", marginTop: "2em"}}>
              <Button className="pinkButton" style={{marginTop: "1em", border: "1px solid lightgray", color: '#ff66c4', width: '100%'}}><span className="bold">Découvrez notre offre</span></Button>
            </a>
          </div>
          <div id="rightHomeModalCard">
            <Typography sx={{ mt: 2 }} style={{marginBottom: "1em"}}>
              <span className="bold" style={{fontSize: "0.9em"}}>Offre 100% Club</span>
            </Typography>
            <Typography sx={{ mt: 2 }}>
              <span className="" style={{fontSize: "0.9em"}}>Positionnez-vous uniquement sur les matchs de votre club.</span>
            </Typography>
            <div style={{}}>
              <Typography sx={{ mt: 2 }} style={{marginBottom: "-0.5em"}}>
                <span className="" style={{fontSize: "0.9em"}}>1) Choisissez votre sport</span>
              </Typography>
              <Typography sx={{ mt: 2 }} style={{marginBottom: "-0.5em"}}>
                <span className="" style={{fontSize: "0.9em"}}>2) Indiquez-nous votre club</span>
              </Typography>
              <Typography sx={{ mt: 2 }}>
                <span className="" style={{fontSize: "0.9em"}}>3) Et diffusez votre pub pendant les matchs de votre club</span>
              </Typography>
            </div>
            <div style={{display: "flex", justifyContent: "space-between", flexDirection: "row", marginTop: "1em"}}>
              <a href={Foot} download='Pack 100% Football - VIDEOCLUB' target='_blank' rel="noreferrer" style={{width: '19%', textDecoration: "none"}}>
                <Button className="pinkButton" style={{marginTop: "1.5em", border: "1px solid lightgray", color: '#ff66c4', width: '100%', textTransform: "none"}}><span className="bold">Foot</span></Button>
              </a>
              <a href={Rugby} download='Pack 100% Rugby - VIDEOCLUB' target='_blank' rel="noreferrer" style={{width: '19%', textDecoration: "none"}}>
                <Button className="pinkButton" style={{marginTop: "1.5em", border: "1px solid lightgray", color: '#ff66c4', width: '100%', textTransform: "none"}}><span className="bold">Rugby</span></Button>
              </a>
              <a href={null} download='Offre 100% Club Hand' target='_blank' rel="noreferrer" style={{width: '19%', textDecoration: "none"}}>
                <Button disabled className="" style={{marginTop: "1.5em", border: "1px solid lightgray", color: '#ff66c4', width: '100%', textTransform: "none"}}><span className="bold">Hand</span></Button>
              </a>
              <a href={null} download='Offre 100% Club Basket' target='_blank' rel="noreferrer" style={{width: '19%', textDecoration: "none"}}>
                <Button disabled className="" style={{marginTop: "1.5em", border: "1px solid lightgray", color: '#ff66c4', width: '100%', textTransform: "none"}}><span className="bold">Basket</span></Button>
              </a>
              <a href={null} download='Offre 100% Club Autres Sports' target='_blank' rel="noreferrer" style={{width: '19%', textDecoration: "none"}}>
                <Button disabled className="" style={{marginTop: "1.5em", border: "1px solid lightgray", color: '#ff66c4', width: '100%', textTransform: "none"}}><span className="bold">Autres sports</span></Button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default function TransitionsModal(props) {
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box sx={style}>
          {
            props.selectedId === -1 ? null
            :
            <div>
              <img src={props.cardsInfo.cardImg} alt="" style={{width: "50%", maxWidth: "335px", display: "flex", justifyContent: "center", margin: "auto"}}/>

              <div style={{marginTop: "1.5em", marginBottom: "1.5em"}}>
                {
                  props.selectedId === 0
                  ?
                  null
                  :
                  props.selectedId === 1
                  ?
                  null
                  :
                  props.selectedId === 2
                  ?
                  null
                  :
                  props.selectedId === 3
                  ?
                  getPopupContent3(props)
                  :
                  props.selectedId === 4
                  ?
                  null
                  :
                  props.selectedId === 5
                  ?
                  null
                  :
                  props.selectedId === 6
                  ?
                  null
                  :
                  null
                }
              </div>
              <div style={{display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "center", alignItems: 'center'}}>
                <Typography sx={{ mt: 2 }}>
                  <span className="">Vous souhaitez être diffusé au sein  d’un évènement sportif en particulier ?</span>
                </Typography>
                <Typography sx={{ mt: 2 }} stle={{marginTop: "-0.5em", marginBottom: "10em"}}>
                  <span className="" style={{fontStyle: 'italic'}}>Coupe du monde, Coupe d’Europe, Matchs Equipe de France … </span>
                </Typography>
                <Button onClick={() => window.open('https://www.videoclub-pub.fr/contact')} style={{marginTop: "1.5em", height: "3.2em", border: "1px solid lightgray", color: '#00c2cb', width: '49%'}}><span className="bold">Discutons-en !</span></Button>
              </div>
            </div>
          }
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
