import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Sport from '../../assets/files/sport.pdf';
import TvFamilly from '../../assets/files/TvFamilly.pdf';
import FirstTime from '../../assets/files/FirstTime.pdf';
import Geoloc from '../../assets/files/geoloc.pdf';
import Prime from '../../assets/files/prime.pdf';
import GenerationYZ from '../../assets/files/generationYZ.pdf';
import Info from '../../assets/files/info.pdf';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minwidth: "20em",
  maxWidth: "40em",
  width: '50vw',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
  maxHeight: "85vh !important",
  height: 'auto'
};

function getFileRelatedToId(id) {
  switch (id) {
    case 0:
      return FirstTime;
      break;
    case 1:
      return Geoloc;
      break;
    case 2:
      return Prime;
      break;
    case 3:
      return Sport;
      break;
    case 4:
      return TvFamilly;
      break;
    case 5:
      return GenerationYZ;
      break;
    case 6:
      return Info;
      break;
    default:
      return null;
  }
}

function getFileNameRelatedToId(id) {
  switch (id) {
    case 0:
      return "Pack First Time TV - VIDEOCLUB";
      break;
    case 1:
      return "Pack Géoloc TV - VIDEOCLUB";
      break;
    case 2:
      return "Pack 100% Prime - VIDEOCLUB";
      break;
    case 3:
      return 'Pack 100_ Sport - VIDEOCLUB';
      break;
    case 4:
      return "Pack Family TV - VIDEOCLUB";
      break;
    case 5:
      return "Pack Génération YZ - VIDEOCLUB";
      break;
    case 6:
      return "Pack Info & Actualités - VIDEOCLUB";
      break;
    default:
      return null;
  }
}

function getPopupContent0(props) {
  return(
    <React.Fragment>
      <Typography id="transition-modal-title" variant="h6" component="h2">
        <span className="bold homeModalTitle">{props.cardsInfo.cardTitle}</span>
      </Typography>
      <div style={{display: "flex", justifyContent: "center", textAlign: "center", flexDirection: "column"}}>
        <Typography sx={{ mt: 2 }}>
          <span className="">Vous souhaitez communiquer à la TV pour la première fois ? </span>
        </Typography>
        <div style={{marginBottom: "1em", marginTop: "1em"}}>
          <Typography sx={{ mt: 2 }}>
            <span className="">Notre offre est réservée aux primo-accédants en TV qui souhaitent : </span>
          </Typography>
          <Typography sx={{ mt: 2 }} style={{marginBottom: "-0.5em"}}>
            <span className="" style={{fontStyle: "italic"}}> ○ Booster leur visibilité</span>
          </Typography>
          <Typography sx={{ mt: 2 }} style={{marginBottom: "-0.5em"}}>
            <span className="" style={{fontStyle: "italic"}}> ○ Gagner en notoriété</span>
          </Typography>
          <Typography sx={{ mt: 2 }} style={{marginBottom: "-0.5em"}}>
            <span className="" style={{fontStyle: "italic"}}> ○ Augmenter leur trafic en digital et/ou en physique</span>
          </Typography>
          <Typography sx={{ mt: 2 }}>
            <span className="" style={{fontStyle: "italic"}}> ○ Ou encore recruter de nouveaux clients</span>
          </Typography>
        </div>
        <Typography sx={{ mt: 2 }}>
          <span className="">Vos premiers pas en TV démarrent ici !</span>
        </Typography>
      </div>
    </React.Fragment>
  );
}

function getPopupContent1(props) {
  return(
    <React.Fragment>
      <Typography id="transition-modal-title" variant="h6" component="h2">
        <span className="bold homeModalTitle">{props.cardsInfo.cardTitle}</span>
      </Typography>
      <div style={{display: "flex", justifyContent: "center", textAlign: "center", flexDirection: "column"}}>
        <Typography sx={{ mt: 2 }}>
          <span className="">Vous souhaitez faire de la publicité en TV en ciblant uniquement une zone géographique bien précise ?</span>
        </Typography>
        <Typography sx={{ mt: 2 }}>
          <span className="">Notre offre Géoloc TV vous permet de diffuser votre publicité TV uniquement sur la zone géographique de votre choix</span>
        </Typography>
        <div style={{marginTop: "1em"}}>
          <Typography sx={{ mt: 2 }}>
            <span className="bold">Comment ça Marche ? </span>
          </Typography>
          <Typography sx={{ mt: 2 }}>
            <span>1) Choisissez et sélectionnez votre ciblage géographique :</span>
          </Typography>
          <Typography sx={{ mt: 2 }} style={{marginBottom: "-0.5em"}}>
            <span className="" style={{fontStyle: "italic"}}> ○ Région(s)</span>
          </Typography>
          <Typography sx={{ mt: 2 }} style={{marginBottom: "-0.5em"}}>
            <span className="" style={{fontStyle: "italic"}}> ○ Département(s)</span>
          </Typography>
          <Typography sx={{ mt: 2 }} style={{marginBottom: "-0.5em"}}>
            <span className="" style={{fontStyle: "italic"}}> ○ Ville(s)</span>
          </Typography>
          <Typography sx={{ mt: 2 }}>
            <span className=""> ○ Code Postal</span>
          </Typography>
          <Typography sx={{ mt: 2 }}>
            <span className="">2) Et diffusez votre publicité sur TF1, M6 ou encore France Télévisions uniquement auprès des téléspectateurs présents sur votre zone.</span>
          </Typography>
        </div>
      </div>
    </React.Fragment>
  );
}

function getPopupContent2(props) {
  return(
    <React.Fragment>
      <Typography id="transition-modal-title" variant="h6" component="h2">
        <span className="bold homeModalTitle">{props.cardsInfo.cardTitle}</span>
      </Typography>
      <div style={{display: "flex", justifyContent: "center", textAlign: "center", flexDirection: "column"}}>
        <Typography sx={{ mt: 2 }}>
          <span className="">Vous souhaitez faire de la publicité sur les plus gros contextes et programmes TV ?</span>
        </Typography>
        <Typography sx={{ mt: 2 }}>
          <span className="">Accédez aux plus gros primes TV tels que Koh lanta, ou encore Top Chef, avec votre présence en replay et/ou en direct à la télé !</span>
        </Typography>
        <div style={{marginBottom: "1em", marginTop: "1em"}}>
          <Typography sx={{ mt: 2 }}>
            <span className="bold">Comment ça Marche ? </span>
          </Typography>
          <Typography sx={{ mt: 2 }}>
            <span className="">1) Choisissez votre cadre de diffusion</span>
          </Typography>
          <Typography sx={{ mt: 2 }} style={{marginBottom: "-0.5em"}}>
            <span className="">TV ou Replay</span>
          </Typography>
          <Typography sx={{ mt: 2 }} style={{marginBottom: "-0.5em"}}>
            <span className="" style={{fontStyle: "italic"}}>Pour le replay, vous pouvez sélectionner précisément le programme recherché</span>
          </Typography>
        </div>
        <Typography sx={{ mt: 2 }}>
          <span className="">2) Et diffusez votre publicité uniquement sur les plus grands primes TV</span>
        </Typography>
      </div>
    </React.Fragment>
  );
}

function getPopupContent3(props) {
  return(
    <React.Fragment>
      <Typography id="transition-modal-title" variant="h6" component="h2">
        <span className="bold homeModalTitle">{props.cardsInfo.cardTitle}</span>
      </Typography>
    </React.Fragment>
  );
}

function getPopupContent4(props) {
  return(
    <React.Fragment>
      <Typography id="transition-modal-title" variant="h6" component="h2">
        <span className="bold homeModalTitle">{props.cardsInfo.cardTitle}</span>
      </Typography>
      <div style={{display: "flex", justifyContent: "center", textAlign: "center", flexDirection: "column"}}>
        <Typography sx={{ mt: 2 }}>
          <span className="">Vous souhaitez cibler une audience familiale ?</span>
        </Typography>
        <Typography sx={{ mt: 2 }}>
          <span className="">Accédez aux plus gros prime TV qui se regardent en famille et ciblez uniquement des familles avec ou sans enfants</span>
        </Typography>
        <div style={{marginBottom: "1em", marginTop: "1em"}}>
          <Typography sx={{ mt: 2 }}>
            <span className="bold">Comment ça Marche ? </span>
          </Typography>
          <Typography sx={{ mt: 2 }}>
            <span className="">1) Choisissez votre cadre de diffusion</span>
          </Typography>
          <Typography sx={{ mt: 2 }} style={{marginBottom: "-0.5em"}}>
            <span className="">TV ou Replay</span>
          </Typography>
          <Typography sx={{ mt: 2 }} style={{marginBottom: "-0.5em"}}>
            <span className="" style={{fontStyle: "italic"}}>Pour le replay, vous pouvez sélectionner précisément le programme recherché</span>
          </Typography>
        </div>
        <Typography sx={{ mt: 2 }}>
          <span className="">2) Et diffusez votre publicité TV uniquement sur une audience familiale</span>
        </Typography>
      </div>
    </React.Fragment>
  );
}

function getPopupContent5(props) {
  return(
    <React.Fragment>
      <Typography id="transition-modal-title" variant="h6" component="h2">
        <span className="bold homeModalTitle">{props.cardsInfo.cardTitle}</span>
      </Typography>
      <div style={{display: "flex", justifyContent: "center", textAlign: "center", flexDirection: "column"}}>
        <Typography sx={{ mt: 2 }}>
          <span className="">Vous souhaitez cibler une audience jeune ?</span>
        </Typography>
        <Typography sx={{ mt: 2 }}>
          <span className="">L’offre « Génération YZ  » vous permet de toucher  les jeunes sur l’ensemble des 4 écrans : TV – Ordinateur – Mobile – Tablette.</span>
        </Typography>
        <Typography sx={{ mt: 2 }}>
          <span className="">Ciblez uniquement une audience de moins de 30 ans et accédez aux programmes TV consommés par les jeunes</span>
        </Typography>
      </div>
    </React.Fragment>
  );
}

function getPopupContent6(props) {
  return(
    <React.Fragment>
      <Typography id="transition-modal-title" variant="h6" component="h2">
        <span className="bold homeModalTitle">{props.cardsInfo.cardTitle}</span>
      </Typography>
      <div style={{display: "flex", justifyContent: "center", textAlign: "center", flexDirection: "column"}}>
        <Typography sx={{ mt: 2 }}>
          <span className="">Vous souhaitez cibler une audience pro B2B, CSP+ et décideurs d’entreprises (chef d’entreprise) ?</span>
        </Typography>
        <div style={{marginBottom: "1em", marginTop: "1em"}}>
          <Typography sx={{ mt: 2 }}>
            <span className="">Positionnez-vous et communiquez en TV sur les plus grandes chaînes d’infos, avec au choix une diffusion :</span>
          </Typography>
          <Typography sx={{ mt: 2 }} style={{marginBottom: "-0.5em"}}>
            <span className="" style={{fontStyle: "italic"}}>○ généraliste sur BFMTV et les plus gros magazines d’infos</span>
          </Typography>
          <Typography sx={{ mt: 2 }} style={{marginBottom: "-0.5em"}}>
            <span className="" style={{fontStyle: "italic"}}>○ Business sur BFM Business</span>
          </Typography>
          <Typography sx={{ mt: 2 }}>
            <span className="" style={{fontStyle: "italic"}}>○ Locale sur BFM Région</span>
          </Typography>
        </div>
        <div>
          <Typography sx={{ mt: 2 }}>
            <span className="bold">Comment ça Marche ? </span>
          </Typography>
          <Typography sx={{ mt: 2 }} style={{marginBottom: "-0.5em"}}>
            <span className="">1) Sélectionner votre cadre de diffusion : National / Business / Local</span>
          </Typography>
          <Typography sx={{ mt: 2 }} style={{marginBottom: "-0.5em"}}>
            <span className="">2) Choisissez votre chaînes</span>
          </Typography>
          <Typography sx={{ mt: 2 }} style={{marginBottom: "-0.5em"}}>
            <span className="">3) Et diffusez votre publicité TV en contexte info.</span>
          </Typography>
        </div>
      </div>
    </React.Fragment>
  );
}

export default function TransitionsModal(props) {
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box sx={style}>
          {
            props.selectedId === -1 ? null
            :
            <div>
              <img src={props.cardsInfo.cardImg} alt="" style={{width: "60%", display: "flex", marginLeft: "auto", marginRight: "auto"}}/>

              <div style={{marginTop: "1.5em", marginBottom: "1.5em"}}>
                {
                  props.selectedId === 0
                  ?
                  getPopupContent0(props)
                  :
                  props.selectedId === 1
                  ?
                  getPopupContent1(props)
                  :
                  props.selectedId === 2
                  ?
                  getPopupContent2(props)
                  :
                  props.selectedId === 3
                  ?
                  getPopupContent3(props)
                  :
                  props.selectedId === 4
                  ?
                  getPopupContent4(props)
                  :
                  props.selectedId === 5
                  ?
                  getPopupContent5(props)
                  :
                  props.selectedId === 6
                  ?
                  getPopupContent6(props)
                  :
                  null
                }
              </div>
              <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                <a href={getFileRelatedToId(props.selectedId)} download={getFileNameRelatedToId(props.selectedId)} target='_blank' rel="noreferrer" style={{width: '49%', textDecoration: "none"}}>
                  <Button className="pinkButton" style={{marginTop: "1.5em", border: "1px solid lightgray", color: '#ff66c4', width: '100%'}}><span className="bold">Découvrez notre offre</span></Button>
                </a>
                <Button onClick={() => window.open('https://www.videoclub-pub.fr/contact')} style={{marginTop: "1.5em", border: "1px solid lightgray", color: '#00c2cb', width: '49%'}}><span className="bold">Discutons-en !</span></Button>
              </div>
            </div>
          }
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
