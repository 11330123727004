import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import Button from '@mui/material/Button';
import WidgetsIcon from '@mui/icons-material/Widgets';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import TableOrder from '../tables/tableOrders';
import logo from '../../assets/videoclub.png';

const drawerWidth = 240;

export default function CampaignContent(props) {

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
        <div>
          <img src={logo} alt="logo" className="appBarLogo"/>
          <Button onClick={() => window.open('https://www.videoclub-pub.fr/contact')} style={{position: "absolute", right: "350px", marginTop: "30px"}} color="inherit">Besoin d'aide ?</Button>
          <Button onClick={() => window.open('https://www.videoclub-pub.fr/')} style={{position: "absolute", right: "180px", marginTop: "30px"}} color="inherit">Démarrer en TV</Button>
          <Button onClick={() => props.disconnectUser()} style={{position: "absolute", right: "30px", marginTop: "30px"}} color="inherit">Déconnexion</Button>
        </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }} style={{marginTop: "2em"}}>
          <List>
            {['Accueil', 'Mes campagnes', 'Nouvelle campagne', 'Mon Profil'].map((text, index) => (
              <div button key={text} className="homeMenuItemWrapper" onClick={() => props.displayPage(index)}>
                <ListItemIcon style={{justifyContent: "center"}}>
                  {
                    index  === 0 ? <WidgetsIcon className="homeMenuIcon" /> :
                    index  === 1 ? <DashboardIcon className="homeMenuIcon" /> :
                    index  === 2 ? <DashboardCustomizeIcon className="homeMenuIcon" /> :
                    <CoPresentIcon className="homeMenuIconSmall"/>
                  }
                </ListItemIcon>
                <span className="menuIconText">{text}</span>
              </div>
            ))}
          </List>
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <div style={{marginTop: "5em"}}>
          <h1 style={{marginBottom: "1em"}}>Mes campagnes</h1>
          <div>
            <TableOrder {...props}/>
          </div>
        </div>
      </Box>
    </Box>
  );
}
