import React, { useEffect  } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


export default function MultipleSelectCheckmarks(props) {
  const [value, setValue] = React.useState('');

  useEffect(() => {
    setValue(props.value);
  });

  return (
    <div style={{marginTop: "1.5em", display: "flex", flexDirection: "column"}}>
      <span className="bold" style={{paddingLeft: "3px", marginBottom: "0.5em"}}>Sélectionnez votre / vos ville(s)</span>
      <div style={{}}>
        <Autocomplete
          className="updatePersoInfoTextfield"
          disablePortal
          id="combo-box-demo"
          clearIcon={null}
          openText=''
          closeText=''
          noOptionsText="Pas de résultat"
          options={props.cities}
          inputValue={value}
          clearOnBlur={true}
          onClose={() => setValue('')}
          onChange={(e) => props.handleItemSelectChange(e.target.innerText, 'city')}
          style={{marginTop: "auto", marginBottom: "auto"}}
          renderInput={(params) => <TextField {...params} className="registerTextfield" label="Rechercher une ville" variant="outlined" value={props.value} onChange={(e) => {props.updateState(props.stateName, e.target.value)}}/> }
        />
        <FormControl sx={{ m: 1}} style={{marginLeft: "0px", width: "100%"}}>
          <InputLabel id="demo-multiple-checkbox-label">{props.selectedCities.length === 0 ? 'Villes sélectionnées' : ''}</InputLabel>
          <Select
            labelId=""
            id=""
            multiple
            label='Villes sélectionnées'
            value={props.selectedCities}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
          >
            {props.selectedCities.map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox checked={true} onChange={() => props.removeSelectedItem(name, 'city')}/>
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
}
