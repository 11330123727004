import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

const marks = [
  {
    value: 3000,
    label: '3 000€',
  },
  {
    value: 50000,
    label: '50 000€',
  },
];

function valuetext(value: number) {
  return " " + value + "€";
}

export default function DiscreteSliderLabel(props) {
  return (
    <Box>
      <Slider
        aria-label="Always visible"
        value={props.budget}
        onChange={props.onChange}
        step={1000}
        marks={marks}
        valueLabelDisplay="on"
        min={3000}
        max={50000}
        valueLabelFormat={valuetext}
      />
    </Box>
  );
}
