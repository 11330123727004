import React from 'react';
import Logo from "../assets/videoclub.png";
import TypeWriter from '../components/typewriter.js';
import NewPasswordAlerts from '../components/alerts/newPasswordAlters.js';
import Textfield from '../components/forgotPasswordTextfield.js';
import ForgotPasswordButton from '../components/buttons/forgotPasswordButton.js';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';

import * as Api from '../services/userApi.js';
import {withRouter} from '../services/withRouter.js';
import "../css/app.css";

class NewPassword extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      email: "",
      token: "",
      password: "",
      confirmation: "",
      greetingMsg: "NOUVEAU MOT DE PASSE",
      isAlertVisible: false,
      alertSeverity: "",
      alertText: "",
      alertColor: "",
      alertMultiple: false,
      isLoading: true,
      isTokenValid: false,
      passwordStrongness: 0,
      passwordStrongnessCalculated: 0
    };
  }

  async componentDidMount() {
    this.setState({email: this.props.email});
    this.setState({token: this.props.token});
    const data = {
      userId: this.props.email,
      token: this.props.token,
      browserUsed: "",
      osUsed: "",
    };
    const ret = await Api.handleSessionToken(data);
    if (ret !== false) {
      this.setState({isTokenValid: true});
    }
    this.setState({isLoading: false});
  }

  //general function used to update state
  updateState = (stateName, value) => {
    this.setState({[stateName]: value});
    this.checkPasswordStrongness();
  }

  displayPasswordStrongness(strongness) {
    switch (strongness) {
      case 0:
        return 0;
        break;
      case 1:
        return 20;
        break;
      case 2:
        return 40;
        break;
      case 3:
        return 60;
        break;
      case 4:
        return 80;
        break;
      case 5:
        return 100;
        break;
      default:
        return 0;
    }
  }

  checkPasswordStrongness() {
    let strongness = 0;
    if (this.state.password.length > 8) {
      strongness += 1;
    }
    if (this.state.password.length > 12) {
      strongness += 1;
    }
    let add = 0;
    let specialChars = "<>@!#$%^&*()_+[]{}?:;|'\"\\,./~`-=";
    for (let i = 0; i < specialChars.length; i++) {
      if (this.state.password.indexOf(specialChars[i]) > -1) {
        add = 1;
      }
    }
    strongness += add;
    add = 0;
    specialChars = "0123456789";
    for (let i = 0; i < specialChars.length; i++) {
      if (this.state.password.indexOf(specialChars[i]) > -1) {
        add = 1;
      }
    }
    strongness += add;
    add = 0;
    for (let i = 0; i < this.state.password.length; i++) {
      let tmp = this.state.password[i];
      if (tmp.toUpperCase() === tmp && tmp !== tmp.toLowerCase()) {
        add = 1;
      }
    }
    strongness += add;
    this.setState({passwordStrongnessCalculated: this.displayPasswordStrongness(strongness)});
  }

  testPassword() {
    let strongness = 0;

    if (this.state.password.length > 8) {
      strongness += 1;
    }

    if (this.state.password.length > 12) {
      strongness += 1;
    }

    if (this.state.password === "" || this.state.confirmation === "") {
      this.setState({alertText: "Merci de renseigner l'ensemble des champs du formulaire"});
      this.setState({alertColor: "error"});
      this.setState({alertSeverity: "error"});
      this.setState({alertMultiple: false});
      this.updateAlertState();
      return false;
    }
    if (this.state.password !== this.state.confirmation) {
      this.setState({alertText: "Les mots de passes ne correspondent pas"});
      this.setState({alertColor: "error"});
      this.setState({alertSeverity: "error"});
      this.setState({alertMultiple: false});
      this.updateAlertState();
      return false;
    }
    if (this.state.password.length < 8) {
      this.setState({alertText: "Votre mot de passe doit faire au minimum 8 caractères"});
      this.setState({alertColor: "error"});
      this.setState({alertSeverity: "error"});
      this.setState({alertMultiple: false});
      this.updateAlertState();
      return false;
    }
    let add = 0;
    let specialChars = "<>@!#$%^&*()_+[]{}?:;|'\"\\,./~`-=";
    for (let i = 0; i < specialChars.length; i++) {
      if (this.state.password.indexOf(specialChars[i]) > -1) {
        add = 1;
      }
    }
    if (add === 0) {
      this.setState({alertText: "Votre mot de passe doit comptenir au moins un caractère spécial"});
      this.setState({alertColor: "error"});
      this.setState({alertSeverity: "error"});
      this.setState({alertMultiple: false});
      this.updateAlertState();
      return false;
    }
    strongness += add;
    add = 0;
    specialChars = "0123456789";
    for (let i = 0; i < specialChars.length; i++) {
      if (this.state.password.indexOf(specialChars[i]) > -1) {
        add = 1;
      }
    }
    if (add === 0) {
      this.setState({alertText: "Votre mot de passe doit comptenir au moins un chiffre"});
      this.setState({alertColor: "error"});
      this.setState({alertSeverity: "error"});
      this.setState({alertMultiple: false});
      this.updateAlertState();
      return false;
    }
    strongness += add;
    add = 0;
    for (let i = 0; i < this.state.password.length; i++) {
      let tmp = this.state.password[i];
      if (tmp.toUpperCase() === tmp && tmp !== tmp.toLowerCase()) {
        add = 1;
      }
    }
    strongness += add;
    if (add === 0) {
      this.setState({alertText: "Votre mot de passe doit comptenir au moins un caractère en majuscule"});
      this.setState({alertColor: "error"});
      this.setState({alertSeverity: "error"});
      this.setState({alertMultiple: false});
      this.updateAlertState();
      return false;
    }
    this.setState({passwordStrongness: strongness});
    return true;
  }

  //function called when login / register button are clicked
  buttonClicked = async () => {
    if (this.testPassword() === false) {
      return;
    }
    const data = {
      userId: this.state.email,
      token: this.state.token,
      password: this.state.password,
      confirmation: this.state.confirmation
    }
    const ret = await Api.newPassword(data);
    if (ret === true) {
      this.setState({alertText: "Votre mot de passe a bien été mis à jour"});
      this.setState({alertColor: "info"});
      this.setState({alertSeverity: "success"});
      this.setState({alertMultiple: true});
      this.updateAlertState();
    }
    else {
      this.setState({alertText: "Une erreur est survenue. Merci de réésayer plus tard"});
      this.setState({alertColor: "error"});
      this.setState({alertSeverity: "error"});
      this.setState({alertMultiple: false});
      this.updateAlertState();
    }
  }

  goBack = () => {
    this.props.navigate("/connexion");
  }

  updateWelcomeText = () => {

  }

  returnFunction = () => {

  }

  //function used to display / hide alerts
  updateAlertState = () => {
    this.setState({isAlertVisible: !this.state.isAlertVisible});
  }

  render() {
    return(
      <div className="contentWrapper animatedBackground">
        <div className="registerContentWrapper">
          <div className="registerCard">
            <div className="registerCardLeft">
              <div className="registerLogoCenterDiv">
                <div className="registerCenterWrapper">
                  <img src={Logo} alt="" className="registerLogo"/>
                </div>
              </div>
            </div>
            <div className="registerCardRight">
            <NewPasswordAlerts {...this.state} onClose={this.updateAlertState} goBack={this.goBack}/>
              <div className="typewriterDiv" style={{textAlign: "center"}}>
                <TypeWriter
                  ref={this.child}
                  updateWelcomeText={this.updateWelcomeText}
                  content={this.state.greetingMsg}
                  returnFunction={this.returnFunction}
                />
              </div>
              {
                this.state.isLoading ? null :
                this.state.isTokenValid === true
                ?
                <div style={{textAlign: "center", width: "80%", marginLeft: "auto", marginRight: "auto"}}>
                  <p style={{width: "100%"}}>
                    {
                      "Merci de saisir le nouveau mot de passe du compte " + this.state.email
                    }
                  </p>
                  <div style={{width: "100%", display: "flex", flexDirection: "row", marginTop: "2em", cursor: "pointer"}} onClick={this.goBack}>
                    <i style={{marginTop: "auto", marginBottom: "auto", marginLeft: "0px", marginRight: "8px"}} id="returnToLoginArrow" className="fa-solid fa-arrow-left-long"></i>
                    <span style={{marginTop: "auto", marginBottom: "auto", fontWeight: "bold"}}>Retour</span>
                  </div>
                  <Textfield id="" textFieldType="password" placeholder="Mot de passe" stateName="password" textfieldValue={this.state.password} updateState={this.updateState}/>
                  <Textfield id="" textFieldType="password" placeholder="Confirmation mot de passe" stateName="confirmation" textfieldValue={this.state.confirmation} updateState={this.updateState}/>
                  <div style={{display: "flex", justifyContent: 'space-between', marginTop: "1em"}}>
                    <div style={{width: "10%"}}>
                      <span style={this.state.passwordStrongness === 0 ? {fontWeight: 'bold'} : {}}>Faible</span>
                    </div>
                    <Box sx={{ width: '70%', marginTop: "auto", marginBottom: "auto" }}>
                      <LinearProgress variant="determinate" value={this.state.passwordStrongnessCalculated} />
                    </Box>
                    <div style={{width: "10%"}}>
                      <span style={this.state.passwordStrongness > 3 ? {fontWeight: 'bold'} : {}}>Fort</span>
                    </div>
                  </div>
                  <ForgotPasswordButton onClick={this.buttonClicked} submitButtonText="Envoyer"/>
                </div>
                :
                <div style={{textAlign: "center", width: "80%", marginLeft: "auto", marginRight: "auto"}}>
                  <p style={{width: "100%"}}>
                    {
                      "Ce lien est de changement de mot de passe est expiré."
                    }
                  </p>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(NewPassword);
