import React from 'react';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import RoutesManager from './services/routesManager';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import './css/index.css';

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const theme = createTheme({
  palette: {
    primary: {
      main: '#00c2cb',
    },
    secondary: {
      main: '#ff66c4',
    },
  },
  components: {
   MuiButton: {
     variants: [
       {
         props: { variant: 'white' },
         style: {
           color: 'white',
           backgroundColor: '#00c2cb !important'
         },
       },
     ],
   },
 },
});


root.render(
  <StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <RoutesManager />
      </ThemeProvider>
    </BrowserRouter>
  </StrictMode>
);

reportWebVitals();
