import React from 'react';
import {withRouter} from '../services/withRouter.js';
import * as LocalStorage from '../services/localStorage';
import Content from '../components/pageContent/campaignContent';
import * as SessionSorage from '../services/sessionStorage';
import * as QuotationApi from '../services/quotationApi';

import "../css/app.css";

function createData(
  CmdId: string,
  CompanyName: string,
  ProductName: string,
  Date: string,
  Type: string,
  Price: string,
  Icon: string,
): Data {
  return { CmdId, CompanyName, ProductName, Date, Type, Price, Icon };
}

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPageLoading: true,
      userQuotations: [],
      orders: [],
    };
  }

  async componentDidMount() {
    const token = LocalStorage.getToken();
    const email = LocalStorage.getId();
    const data = {
      email: email,
      token: token
    };
    const ret = await QuotationApi.getUserQuotations(data);
    const tmp = this.createDataFromResult(ret.data.results.results);
    this.setState({userQuotations: ret.data.results.results});
    this.setState({orders: tmp});
    this.setState({isPageLoading: false});
  }

  disconnectUser = () => {
    this.props.disconnectUser();
  }

  handleDateForId = (cmd) => {
    for (let i = 0; i < cmd.length; i++) {
      if (cmd[i].cmdDate) {

      }
      return cmd;
    }
  }

  handleFinishCmd = (cmd) => {

    /*Step 0*/
    SessionSorage.setSessionField('commercialName', cmd.commercialName);
    SessionSorage.setSessionField('productName', cmd.productName);
    SessionSorage.setSessionField('communitationObjective', cmd.communitationObjective);
    /*Step 1*/
    //remove last char if its empty
    SessionSorage.setSessionField('geoArea', cmd.geoArea.charAt(cmd.geoArea.length -1) === " " ? cmd.geoArea.substring(0, cmd.geoArea.length -1) : cmd.geoArea);
    //console.log('test 2')
    //console.log(cmd.geoArea.charAt(cmd.geoArea.length -1) === " ")
    SessionSorage.setSessionField('geoAreaOptions', cmd.geoAreaOptions);
    //target interest
    if (cmd.targetInterest !== 0) {
      SessionSorage.setSessionField('targetInterest', cmd.targetInterest);
    }
    //target household composition
    if (cmd.targetHouseholdComposition !== 0) {
      SessionSorage.setSessionField('targetHouseholdComposition', cmd.targetHouseholdComposition);
    }
    //target CSP
    if (cmd.targetCSP !== 0) {
      SessionSorage.setSessionField('targetCSP', cmd.targetCSP);
    }
    //target housing type
    if (cmd.targetHousingType !== 0) {
      SessionSorage.setSessionField('targetHousingType', cmd.targetHousingType);
    }
    //target data
    if (cmd.targetData !== 0) {
      SessionSorage.setSessionField('targetData', cmd.targetData);
    }
    if (cmd.targetGeolife !== 0) {
      SessionSorage.setSessionField('targetGeolife', cmd.targetGeolife);
    }
    if (cmd.targetConsoTV !== 0) {
      SessionSorage.setSessionField('targetConsoTV', cmd.targetConsoTV);
    }
    if (cmd.targetMomentMarketing !== 0) {
      SessionSorage.setSessionField('targetMomentMarketing', cmd.targetMomentMarketing);
    }
    if (cmd.targetSex !== 0) {
      SessionSorage.setSessionField('targetSex', cmd.targetSex);
    }
    if (cmd.targetSexAge !== 0) {
      SessionSorage.setSessionField('targetSexAge', cmd.targetSexAge);
    }
    if (cmd.budget !== 0 && cmd.budget !== '0') {
      SessionSorage.setSessionField('budget', cmd.budget);
    }
    if (cmd.campaignType !== 0 && cmd.campaignType !== '0') {
      SessionSorage.setSessionField('campaignType', cmd.campaignType);
    }
    if (cmd.startDate !== 0 && cmd.startDate !== '0') {
      SessionSorage.setSessionField('startDate', cmd.startDate);
    }
    if (cmd.stopDate !== 0 && cmd.stopDate !== '0') {
      SessionSorage.setSessionField('stopDate', cmd.stopDate);
    }
    if (cmd.cmdId !== 0 && cmd.cmdId !== '0') {
      SessionSorage.setSessionField('cmdId', cmd.cmdId);
    }
    if (cmd.spotTvAlreadyAvailable !== 0 && cmd.spotTvAlreadyAvailable !== '0' !== 0 && cmd.spotTvAlreadyAvailable !== '') {
      SessionSorage.setSessionField('spotTvAlreadyAvailable', cmd.spotTvAlreadyAvailable);
    }
    if (cmd.spotDuration !== 0) {
      SessionSorage.setSessionField('spotDuration', cmd.spotDuration);
    }
    if (cmd.spotId !== '0' && cmd.spotId !== null && cmd.spotId !== "") {
      SessionSorage.setSessionField('spotId', cmd.spotId);
    }
    this.props.navigate('/nouvelle-campagne');
  }

  displayPage = (index) => {
    switch(index) {
      case 0:
        this.props.navigate('/accueil');
        break;
      case 1:
        this.props.navigate('/mes-campages');
        break;
      case 2:
        this.props.navigate('/nouvelle-campagne');
        break;
      case 3:
        this.props.navigate('/profil');
        break;
      default:
        return;
    }
  }

   createDataFromResult = (results) => {
    let tmp = [];
    let date = '';
    //console.log(results)
    for (let i = 0; i < results.length; i++) {
      const year = results[i].cmdDate.split('-');
      let yearId = '';
      if (typeof year[0] !== 'undefined') {
        yearId = yearId.concat(year[0], '-', results[i].Id);
      }
      if (results[i].startDate === '0') {
        date = 'Non défini';
      }
      else {
        date = results[i].startDate + ' au ' + results[i].stopDate;
      }
      if (results[i].budget === '0') {
        results[i].budget = 'Non défini';
      }
      if (results[i].productName === '') {
        results[i].productName = 'Non défini';
      }
      if (results[i].fk_campainType === '0' || results[i].fk_campainType === 0) {
        results[i].fk_campainType = 'Non défini';
      }
      tmp[i] = createData(yearId, results[i].commercialName, results[i].productName, date, results[i].fk_campainType, results[i].budget, '')
    }
    return tmp;
  }

  render() {
    return(
        this.state.isPageLoading ? null :
        <div className="">
        {
          this.state.isPageLoading === true ? null : <Content {...this.state} displayPage={this.displayPage} disconnectUser={this.disconnectUser} handleFinishCmd={this.handleFinishCmd}/>
        }
        </div>
    );
  }
}

export default withRouter(Home);
