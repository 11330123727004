import Checkbox from '@mui/material/Checkbox';

function LoginCheckbox(props) {
  return (
    <div id="loginCheckbox" className="loginCheckboxDiv">
      <Checkbox
        style={{marginLeft: "-9px"}}
        color="primary"
        checked={props.checked}
        onChange={e => {
          props.updateCheckboxState(props.checkboxStateName, e.target.checked);
        }}
      />
      <p>{props.checkboxLabel}</p>
    </div>
  );
}

export default LoginCheckbox;
