import React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';

export default function NewCampaignSpotTvDurationSelect(props) {

  return (
    <Tooltip title={props.spotTvAlreadyAvailable === 0 ? '' : 'Plus votre spot est court, meilleure sera la portée de votre diffusion'} placement="bottom">
      <Box sx={{ minWidth: 120 }} style={{marginTop: "1em"}}>
        <FormControl fullWidth>
          <InputLabel>Durée du spot TV</InputLabel>
            <Select
              color='primary'
              labelId="selectSpotTv-label"
              id="selectSpotTv"
              value={props.spotDuration >= 0 ? props.spotDuration : ''}
              label="Durée du spot TV"
              onChange={(event) => props.onChange('spotDuration', event.target.value)}
            >
              <MenuItem value={0}>15 secondes</MenuItem>
              <MenuItem value={1}>20 secondes</MenuItem>
              <MenuItem value={2}>30 secondes</MenuItem>
            </Select>
        </FormControl>
      </Box>
    </Tooltip>
  );
}
