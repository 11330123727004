import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Button from '@mui/material/Button';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "50%",
  maxWidth: "30em",
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function TransitionsModal(props) {
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
            {
              <span className="bold">Erreur lors de la validation de l'adresse email</span>
            }
            </Typography>
            <div style={{marginTop: "1.5em", marginBottom: "1.5em"}}>
              <span style={{display: "flex", flexDirection: "column"}}>
                <Typography sx={{ mt: 2 }}>
                  <span style={{display: "flex"}}>Un email contenant un lien de validation vous a été envoyé lors de la création de votre compte.</span>
                </Typography>
                <Typography sx={{ mt: 2 }}>
                  <span style={{display: "flex"}}>Vous pouvez générer un nouveau lien de validation en cliquant sur le bouton ci-dessous.</span>
                </Typography>
                <div style={{marginTop: "1em"}}>
                  {
                    props.isModalLoading === true
                    ?
                    <Typography sx={{ mt: 2 }}>
                      <span style={{display: "flex", fontWeight: "bold"}}>En cours de traitement ...</span>
                    </Typography>
                    :
                    <Button color="inherit" size="small" onClick={() => props.generateNewEmailValidationLink()}>
                      <span style={{fontWeight: "bold"}}>Nouveau lien</span>
                    </Button>
                  }
                </div>
              </span>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
