import * as React from 'react';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ConnectedTvIcon from '@mui/icons-material/ConnectedTv';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import HomeIcon from '@mui/icons-material/Home';
import FavoriteIcon from '@mui/icons-material/Favorite';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import WcIcon from '@mui/icons-material/Wc';

function getIcon(id, item) {
  switch (id) {
    case 0:
      return <WcIcon />
      break;
    case 1:
      return <FamilyRestroomIcon />
      break;
    case 2:
      return <BusinessCenterIcon />
      break;
    case 3:
      return <HomeIcon />
      break;
    case 4:
      return <FavoriteIcon />
      break;
    case 5:
      return <ManageAccountsIcon />
      break;
    case 6:
      return <MapsHomeWorkIcon />
      break;
    case 7:
      return <ConnectedTvIcon />
      break;
    case 8:
      return <WbSunnyIcon />
      break;
    default:
      return null;
  }
}

function getCardStyle(props) {
  if (props.value === true) {
    return {
      border: "1px solid #00c2cb",
    };
  }
  else {
    return {
      border: "1px solid grey",
    };
  }
}


export default function card(props) {
  return (
    <div style={{width: "100%"}}>
      <div className="cardOption" style={getCardStyle(props)}>
        <div style={{display: "flex", flexDirection: "column", position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
          <span style={{marginBottom: "0.1em", marginLeft: "auto", marginRight: "auto"}}>{getIcon(props.id, props.currentStep, props.item)}</span>
          <span className="bold" style={{textAlign: "center"}}>{props.cardTitle}</span>
        </div>
      </div>
    </div>
  );
}
