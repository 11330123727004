//const security = require('./cypher');

//function used to get session token
export function getToken() {
  const token = localStorage.getItem('token');
  if (token) {
    return token;
  }
  return -1;
}

//function used to set session token
export function setToken(token) {
  localStorage.setItem('token', token);
}

//function used to set session token
export function resetToken(token) {
  localStorage.setItem('token', null);
}

export function setId(id) {
  localStorage.setItem('id', id);
}

export function getId() {
  return localStorage.getItem('id');
}

export function setStayConnected(value) {
  localStorage.setItem('stayConnected', value);
}

export function getStayConnected() {
  return localStorage.getItem('stayConnected');
}
