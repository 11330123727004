function ReturnButton(props) {
  return (
    <div className="registerButtonWrapper">
    {
      props.isLoaderVisible
      ?
      null
      :
      <button onClick={() => props.onClick()} id="" className="btn btn-green btnRegister">
        {props.submitButtonText}
      </button>
    }
    </div>
  );
}

export default ReturnButton;
