import React, { useEffect  } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import SelectAge from '../selects/targetAge.js';
import DepartmentSelect from '../selects/departmentSelect.js';
import RegionSelect from '../selects/regionSelect.js';
import GenericCheckbox from '../checkboxs/genericCheckbox';
import AutoCompleteCities from '../selects/newCampaignSelectCities.js';
import AutoCompletePostalCode from '../selects/newCampaignSelectPostalCode.js';
import AutoCompleteCustom from '../textfields/autocompleteCustom.js';
import SelectCustomTarget from '../selects/selectCustomTarget.js';
import GenericCard from '../cards/newCampainCards';
import CardOption from '../cards/newCampainOptions';
import OneLineCard from '../cards/newCampainCardsOneLine';
import CustomSlider from '../sliders/customSlider';

function optionMustBeDisplayed(props) {
  if (props.targetValue0 === true && props.targetIsOpen === 0) {
    return 0;
  }
  if (props.targetValue1 === true && props.targetIsOpen === 1) {
    return 1;
  }
  if (props.targetValue2 === true && props.targetIsOpen === 2) {
    return 2;
  }
  if (props.targetValue3 === true && props.targetIsOpen === 3) {
    return 3;
  }
  if (props.targetValue4 === true && props.targetIsOpen === 4) {
    return 4;
  }
  if (props.targetValue5 === true && props.targetIsOpen === 5) {
    return 5;
  }
  if (props.targetValue6 === true && props.targetIsOpen === 6) {
    return 6;
  }
  if (props.targetValue7 === true && props.targetIsOpen === 7) {
    return 7;
  }
  if (props.targetValue8 === true && props.targetIsOpen === 8) {
    return 8;
  }
  return -1;
}

function targetMustBeDisplayed(props) {
  if (props.frenchAreaValue === true) {
    return true;
  }
  if (props.regionAreaValue === true) {
    if (props.nbOfRegionSelected >= 1) {
      return true;
    }
    return false;
  }
  if (props.departmentAreaValue === true) {
    if (props.nbOfDepartmentSelected >= 3) {
      return true;
    }
    return false;
  }
  if (props.cityAreaValue === true) {
    return false;
  }
  if (props.customAreaValue === true) {
    return false;
  }
  return false;
}

function frenchAreaScrolling(props) {
  if (props.frenchAreaValue === true) {
    if (props.targetValue0 || props.targetValue1 || props.targetValue2 || props.targetValue3 || props.targetValue4 || props.targetValue5 || props.targetValue6 || props.targetValue7 || props.targetValue8) {
      return false;
    }
    return true;
  }
  return false;
}

function getCardStyle(id, item, props) {
  if (item === 'option') {
    switch (id) {
      case 0:
        if (optionMustBeDisplayed(props) === 0) {
          return {display: "flex", flexDirection: "row", border: "1px solid lightgray", padding: "20px"};
        }
        else {
          return {display: "none"};
        }
        break;
      case 1:
        if (optionMustBeDisplayed(props) === 1) {
          return {display: "flex", flexDirection: "row", border: "1px solid lightgray", padding: "20px"};
        }
        else {
          return {display: "none"};
        }
      case 2:
        if (optionMustBeDisplayed(props) === 2) {
          return {display: "flex", flexDirection: "row", border: "1px solid lightgray", padding: "20px"};
        }
        else {
          return {display: "none"};
        }
        break;
      case 3:
        if (optionMustBeDisplayed(props) === 3) {
          return {display: "flex", flexDirection: "row", border: "1px solid lightgray", padding: "20px"};
        }
        else {
          return {display: "none"};
        }
        break;
      case 4:
        if (optionMustBeDisplayed(props) === 4) {
          return {display: "flex", flexDirection: "row", border: "1px solid lightgray", padding: "20px"};
        }
        else {
          return {display: "none"};
        }
        break;
      case 5:
        if (optionMustBeDisplayed(props) === 5) {
          return {display: "flex", flexDirection: "row", border: "1px solid lightgray", padding: "20px"};
        }
        else {
          return {display: "none"};
        }
        break;
      case 6:
        if (optionMustBeDisplayed(props) === 6) {
          return {display: "flex", flexDirection: "row", border: "1px solid lightgray", padding: "20px"};
        }
        else {
          return {display: "none"};
        }
        break;
      case 7:
        if (optionMustBeDisplayed(props) === 7) {
          return {display: "flex", flexDirection: "row", border: "1px solid lightgray", padding: "20px"};
        }
        else {
          return {display: "none"};
        }
        break;
      case 8:
        if (optionMustBeDisplayed(props) === 8) {
          return {display: "flex", flexDirection: "row", border: "1px solid lightgray", padding: "20px"};
        }
        else {
          return {display: "none"};
        }
        break;
      default:
      return;
    }
  }
}

function cardTarget(props) {
  return(
    targetMustBeDisplayed(props) === true
    ?
    <React.Fragment>
      <CardContent>
        <span id='targetContent' className="cardText">
          Cible
        </span>
        <div style={{width: "94%"}}>
        {
          props.frenchAreaValue
          ?
          <p>Pour une campagne <span className="bold">nationale</span>, sélectionner au minimum <span className="bold">2 cibles obligatoires</span>.</p>
          :
          props.regionAreaValue && props.nbOfRegionSelected === 1
          ?
          <p>Pour une campagne <span className="bold">régionale</span>, vous pouvez choisir au maximum <span className="bold">1 cible</span> dans la liste ci-dessous</p>
          :
          props.regionAreaValue && props.nbOfRegionSelected >= 2
          ?
          <p>Pour une campagne <span className="bold">multi régionale</span>, vous devez obligatoirement choisir <span className="bold">1 cible</span> dans la liste ci-dessous</p>
          :
          props.departmentAreaValue && props.nbOfDepartmentSelected >= 3
          ?
          <p>Pour une campagne <span className="bold">multi départements,</span>, vous pouvez choisir au maximum <span className="bold">1 cible</span> dans la liste ci-dessous</p>
          :
          null
        }
        {
          props.frenchAreaValue
          ?
          <p>Pour améliorer la précision de votre campagne, vous pouvez choisir jusqu’à <span className="bold">1 cible supplémentaire.</span></p>
          :
          props.regionAreaValue && props.nbOfRegionSelected === 1
          ?
          <p>Cette option est facultative, mais elle est <span className="bold">fortement recommandée</span> pour améliorer les performances de votre campagne.</p>
          :
          props.regionAreaValue && props.nbOfRegionSelected === 2
          ?
          <p>Pour améliorer la précision de votre campagne, vous pouvez choisir jusqu’à <span className="bold">1 cible supplémentaire.</span></p>
          :
          props.regionAreaValue && props.nbOfRegionSelected >= 3
          ?
          <p>Pour améliorer la précision de votre campagne, vous pouvez choisir en option <span className="bold">1 cible supplémentaire</span>. Cette option est facultative, mais elle est <span className="bold">fortement recommandée</span> pour améliorer les performances de votre campagne.</p>
          :
          props.departmentAreaValue && props.nbOfDepartmentSelected >= 3 && props.nbOfDepartmentSelected < 6
          ?
          <p>Cette option est facultative, mais elle est <span className="bold">fortement recommandée</span> pour améliorer les performances de votre campagne.</p>
          :
          props.departmentAreaValue && props.nbOfDepartmentSelected >= 6
          ?
          <p>Pour améliorer la précision de votre campagne, vous pouvez choisir en option <span className="bold">1 cible supplémentaire</span>.</p>
          :
          null
        }
        </div>
        <Box component="form" noValidate autoComplete="off" style={{marginTop: "1em"}}>
          <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
            <span style={{width: "33%"}}>
              <GenericCard {...props} id={0} item="target" isCardPink={true} onChange={props.handleTargetCheckboxChange} value={props.targetValue0} cardTitle='Socio Démo' cardText='Par âge et/ou par sexe' examples=""/>
            </span>
            <span style={{width: "33%"}}>
              <GenericCard {...props} id={1} item="target" isCardPink={true} onChange={props.handleTargetCheckboxChange} value={props.targetValue1} cardTitle='Composition du foyer' cardText='Nombre de personnes composant un foyer' examples=""/>
            </span>
            <span style={{width: "33%"}}>
              <GenericCard {...props} id={2} item="target" isCardPink={true} onChange={props.handleTargetCheckboxChange} value={props.targetValue2} cardTitle='CSP & Revenus' cardText='Classification des métiers et des revenus par foyer' examples=""/>
            </span>
          </div>
          <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
            <span style={{width: "33%"}}>
              <GenericCard {...props} id={3} item="target" isCardPink={true} onChange={props.handleTargetCheckboxChange} value={props.targetValue3} cardTitle="Type d'habitat" cardText="Caractérisation de l'habitat et statut d'occupation du logement" examples=""/>
            </span>
            <span style={{width: "33%"}}>
              <GenericCard {...props} id={4} item="target" isCardPink={true} onChange={props.handleTargetCheckboxChange} value={props.targetValue4} cardTitle='Intérêts' cardText='Temps de consommation de programme par thématique' examples=""/>
            </span>
            <span style={{width: "33%"}}>
              <GenericCard {...props} id={5} item="target" isCardPink={true} onChange={props.handleTargetCheckboxChange} value={props.targetValue5} cardTitle='Data' cardText="Meilleure adressabilité de votre cible. Perfomances et précisions au rendez-vous" examples=""/>
            </span>
          </div>
          <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
            <span style={{width: "33%"}}>
              <GenericCard {...props} id={6} item="target" isCardPink={true} onChange={props.handleTargetCheckboxChange} value={props.targetValue6} cardTitle="Géolife" cardText="Catégorisation des foyers selon des critères socio-économiques et géographiques" examples=""/>
            </span>
            <span style={{width: "33%"}}>
              <GenericCard {...props} id={7} item="target" isCardPink={true} onChange={props.handleTargetCheckboxChange} value={props.targetValue7} cardTitle='Conso TV' cardText='Temps de consommation de la télévision' examples=""/>
            </span>
            <span style={{width: "33%"}}>
              <GenericCard {...props} id={8} item="target" isCardPink={true} onChange={props.handleTargetCheckboxChange} value={props.targetValue8} cardTitle='Moment marketing' cardText="Diffusion de votre campagne TV basée sur des critères spécifiques" examples=""/>
            </span>
          </div>
          <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
            <span style={{width: "33%"}} />
            <span style={{width: "33%", textAlign: "center"}}>
              <div id="lol" style={{width: "85%"}}>
                <span id="options" className="bold" style={optionMustBeDisplayed(props) !== -1 ? {fontSize: "1.1em", paddingLeft: "5px", textDecoration: "underline", marginTop: "3em", display: "flex", justifyContent: "center"} : {display: "none"}}>Choix du ciblage</span>
              </div>
            </span>
            <span style={{width: "33%"}} />
          </div>
          <div style={optionMustBeDisplayed(props) !== -1 ? {display: "flex", flexDirection: "column", marginTop: "1.5em"} : {display: "none"}}>
          {
            <div id="step1TargetSexDiv" style={getCardStyle(0, 'option', props)}>
              <div style={{display: "flex", flexDirection: "row", width: "20%", marginTop: "auto", marginBottom: "auto"}}>
                <CardOption {...props} id={0} cardTitle='Socio Démo'/>
              </div>
              <div style={{width: "80%"}}>
                <div id="step1TargetSex" {...props} variant="outlined">{cardTargetSexOption(props)}</div>
              </div>
            </div>
          }
          {
            <div style={getCardStyle(1, 'option', props)}>
              <div style={{display: "flex", flexDirection: "row", width: "20%", marginTop: "auto", marginBottom: "auto"}}>
                <CardOption {...props} id={1} cardTitle='Composition du foyer'/>
              </div>
              <div style={{width: "80%"}}>
                <div id="step1HouseholdCompo" {...props} variant="outlined">{cardHouseholdCompositionOption(props)}</div>
              </div>
            </div>
          }
          {
            <div style={getCardStyle(2, 'option', props)}>
              <div style={{display: "flex", flexDirection: "row", width: "20%", marginTop: "auto", marginBottom: "auto", flexWrap: "wrap"}}>
                <CardOption {...props} id={2} cardTitle='CSP & Revenus'/>
              </div>
              <div style={{width: "80%"}}>
                <div id="step1CSP" {...props} variant="outlined">{cardCSPOption(props)}</div>
              </div>
            </div>
          }
          {
            <div style={getCardStyle(3, 'option', props)}>
              <div style={{display: "flex", flexDirection: "row", width: "20%", marginTop: "auto", marginBottom: "auto"}}>
                <CardOption {...props} id={3} cardTitle="Type d'habitat"/>
              </div>
              <div style={{width: "80%"}}>
                <div id="step1HousingType" {...props} variant="outlined">{cardHousingTypeOption(props)}</div>
              </div>
            </div>
          }
          {
            <div style={getCardStyle(4, 'option', props)}>
              <div style={{display: "flex", flexDirection: "row", width: "20%", marginTop: "auto", marginBottom: "auto"}}>
                <CardOption {...props} id={4} cardTitle="Intérêts"/>
              </div>
              <div style={{width: "80%"}}>
                <div id="step1Interest" {...props} variant="outlined">{cardInterestOption(props)}</div>
              </div>
            </div>
          }
          {
            <div style={getCardStyle(5, 'option', props)}>
              <div style={{display: "flex", flexDirection: "row", width: "20%", marginTop: "auto", marginBottom: "auto"}}>
                <CardOption {...props} id={5} cardTitle="Data"/>
              </div>
              <div style={{width: "80%"}}>
                <div id="step1Data" {...props} variant="outlined">{cardDataOption(props)}</div>
              </div>
            </div>
          }
          {
            <div style={getCardStyle(6, 'option', props)}>
              <div style={{display: "flex", flexDirection: "row", width: "20%", marginTop: "auto", marginBottom: "auto"}}>
                <CardOption {...props} id={6} cardTitle="Géolife"/>
              </div>
              <div style={{width: "80%"}}>
                <div id="step1geolife" {...props} variant="outlined">{cardGeolifeOption(props)}</div>
              </div>
            </div>
          }
          {
            <div style={getCardStyle(7, 'option', props)}>
              <div style={{display: "flex", flexDirection: "row", width: "20%", marginTop: "auto", marginBottom: "auto"}}>
                <CardOption {...props} id={7} cardTitle="Conso TV"/>
              </div>
              <div style={{width: "80%"}}>
                <div id="step1ConsoTV" {...props} variant="outlined">{cardConsoTVOption(props)}</div>
              </div>
            </div>
          }
          {
            <div id="totolol" style={getCardStyle(8, 'option', props)}>
              <div style={{display: "flex", flexDirection: "row", width: "20%", marginTop: "auto", marginBottom: "auto"}}>
                <CardOption {...props} id={8} cardTitle="Moment marketing"/>
              </div>
              <div style={{width: "80%"}}>
                <div id="step1MomentMarketing" {...props} variant="outlined">{cardMomentMarketingOption(props)}</div>
              </div>
            </div>
          }
          </div>
        </Box>
      </CardContent>
    </React.Fragment>
    :
    null
  );
}

function cardArea(props) {
  return(
    <React.Fragment>
      <CardContent>
        <div style={{display: "flex", flexDirection: "column"}}>
          <span className="cardText">
            Zone géographique
          </span>
          <span style={{marginTop: '1em'}}>
            Sélectionnez votre zone de diffusion
          </span>
        </div>
        <Box component="form" noValidate autoComplete="off" style={{marginTop: "1em"}}>
          <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", flexWrap: "wrap"}}>
            <span style={{width: "33%"}}>
              <GenericCard {...props} id={1} item="geoArea" onChange={props.handleGeoArea} value={props.frenchAreaValue} cardTitle='National' cardText='' examples=""/>
            </span>
            <span style={{width: "33%"}}>
              <GenericCard {...props} id={2} item="geoArea" onChange={props.handleGeoArea} value={props.regionAreaValue} cardTitle='Région(s)' cardText='Possibilité de séléctionner plusieurs régions' examples=""/>
            </span>
            <span style={{width: "33%"}}>
              <GenericCard {...props} id={3} item="geoArea" onChange={props.handleGeoArea} value={props.departmentAreaValue} cardTitle='Département(s)' cardText='Possibilité de sélectionner plusieurs départements' examples=""/>
            </span>
          </div>
          <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start"}}>
            <span style={{width: "33%"}}>
              <GenericCard {...props} id={4} item="geoArea" onChange={props.handleGeoArea} value={props.cityAreaValue} cardTitle='Ville(s)' cardText='Possibilité de sélectionner plusieurs villes' examples=""/>
            </span>
            <span style={{width: "33%"}}>
              {1 === 2 ? <GenericCheckbox checkboxId={5} checked={props.postalCodeAreaValue} checkboxLabel="Code postal(aux) au choix" handleChange={props.handleGeoArea }/> : <GenericCard {...props} id={6} item="geoArea" onChange={props.handleGeoArea} value={props.customAreaValue} cardTitle='Sur mesure' cardText='Distance par km ou par temps de trajet' examples=""/>}
            </span>
            <span style={{width: "33%"}} id=''>
            </span>
          </div>
          <div>
            {
              props.departmentAreaValue ?
              <DepartmentSelect {...props} handleSelectDeptChange={props.handleSelectDeptChange}/>
              :
              props.regionAreaValue ?
              <RegionSelect {...props} handleSelectRegionChange={props.handleSelectRegionChange}/>
              :
              props.cityAreaValue ?
              <AutoCompleteCities {...props} placeholder='Rechercher une ville' stateName="searchCityTextfieldValue" value={props.searchCityTextfieldValue} />
              :
              props.postalCodeAreaValue ?
              <AutoCompletePostalCode {...props} placeholder='Rechercher un code postal' stateName="searchPostalCodeTextfieldValue" value={props.searchCityTextfieldValue} />
              :
              props.customAreaValue ?
              <div style={{marginTop: "1.5em", paddingLeft: "10px"}}>
                <div style={{display: "flex", flexDirection: "column"}}>
                  <span className="bold" style={{marginBottom: "10px", paddingLeft: "3px"}}>Zone géographique sur mesure</span>
                  <AutoCompleteCustom {...props} placeholder='Votre adresse de départ' stateName="customLocationSearchText" value={props.customLocationSearchText} onChange={props.customLocationChange}/>
                  <span style={{marginTop: "0.5em", marginBottom: "0.5em"}}>
                    <SelectCustomTarget open={props.selectedCustomLocation !== '' ? true : false} {...props}/>
                  </span>
                  <CustomSlider {...props} onChange={(e) => props.updateState("customDistanceText", e.target.value)} customLocationType={props.customLocationType} value={props.customDistanceText}/>
                </div>
              </div>
              :
              null
            }
          </div>
        </Box>
      </CardContent>
    </React.Fragment>
  );
}

function cardTargetSexOption(props) {
  return(
    <React.Fragment>
      <CardContent>
        <Box component="form" noValidate autoComplete="off" style={{marginTop: "1em"}}>
          <div className="cardOptionWrapper">
            <span className="onelineCardOption">
              <OneLineCard {...props} id={2} item="targetSex" isCardPink={true} onChange={props.handleOptionChange} value={props.targetSex2} cardText="Mixte" tooltipText=""/>
            </span>
            <span className="onelineCardOption">
              <OneLineCard {...props} id={0} item="targetSex" isCardPink={true} onChange={props.handleOptionChange} value={props.targetSex0} cardText="Hommes" tooltipText=""/>
            </span>
            <span className="onelineCardOption">
              <OneLineCard {...props} id={1} item="targetSex" isCardPink={true} onChange={props.handleOptionChange} value={props.targetSex1} cardText="Femmes" tooltipText=""/>
            </span>
          </div>
          <div>
          {
            props.targetSex0 === true || props.targetSex1 === true || props.targetSex2 === true
            ?
            <div>
              <SelectAge {...props} handleSelectTargetAgeChange={props.handleSelectTargetAgeChange}/>
              <div style={{display: "flex", justifyContent: "flex-end", width: "94%"}}>
                <Button onClick={() => props.closeTargetOption(false, 0)} style={{width: "10em", marginTop: "1.5em", border: "1px solid lightgray", color: '#ff66c4', marginRight: "10px"}}>Retour</Button>
                <Button onClick={() => props.closeTargetOption(true, 0)} style={{width: "10em", marginTop: "1.5em", border: "1px solid lightgray", color: '#00c2cb'}}>Valider</Button>
              </div>
            </div>
            :
            null
          }
          </div>
        </Box>
      </CardContent>
    </React.Fragment>
  );
}

function cardHouseholdCompositionOption(props) {
  return(
    <React.Fragment>
      <CardContent>
        <Box component="form" noValidate autoComplete="off" style={{marginTop: "1em"}}>
          <div className="cardOptionWrapper">
            <span className="onelineCardOption">
              <OneLineCard {...props} id={0} isCardPink={true} item='householdCompo' onChange={props.handleOptionChange} value={props.householdCompo0} cardText="Célibataire" tooltipText="Foyers composés d'une personne vivant seule"/>
            </span>
            <span className="onelineCardOption">
              <OneLineCard {...props} id={1} isCardPink={true} item='householdCompo' onChange={props.handleOptionChange} value={props.householdCompo1} cardText="Famille avec enfant(s)" tooltipText="Foyers composés d'un couple avec présence d'enfants de moins de 25 ans"/>
            </span>
            <span className="onelineCardOption">
              <OneLineCard {...props} id={2} isCardPink={true} item='householdCompo' onChange={props.handleOptionChange} value={props.householdCompo2} cardText="Famille sans enfant(s)" tooltipText="Foyers composés d'un couple sans enfant"/>
            </span>
          </div>
          <div style={{display: "flex", justifyContent: "flex-end", width: "94%"}}>
            <Button onClick={() => props.closeTargetOption(false, 1)} style={{width: "10em", marginTop: "1.5em", border: "1px solid lightgray", color: '#ff66c4', marginRight: "10px"}}>Retour</Button>
            <Button onClick={() => props.closeTargetOption(true, 1)} style={{width: "10em", marginTop: "1.5em", border: "1px solid lightgray", color: '#00c2cb'}}>Valider</Button>
          </div>
        </Box>
      </CardContent>
    </React.Fragment>
  );
}

function cardMomentMarketingOption(props) {
  return(
    <React.Fragment>
      <CardContent>
        <Box component="form" noValidate autoComplete="off" style={{marginTop: "1em"}}>
          <div className="cardOptionWrapper">
            <span className="onelineCardOption">
              <OneLineCard {...props} id={0} isCardPink={true} item='momentMarketing' onChange={props.handleOptionChange} value={props.momentMarketing === 0 ? true : false} cardText={props.momentMarketingArray[0].name} tooltipText={props.momentMarketingArray[0].description}/>
            </span>
            <span className="onelineCardOption">
              <OneLineCard {...props} id={1} isCardPink={true} item='momentMarketing' onChange={props.handleOptionChange} value={props.momentMarketing === 1 ? true : false} cardText={props.momentMarketingArray[1].name} tooltipText={props.momentMarketingArray[1].description}/>
            </span>
            <span className="onelineCardOption">
              <OneLineCard {...props} id={2} isCardPink={true} item='momentMarketing' onChange={props.handleOptionChange} value={props.momentMarketing === 2 ? true : false} cardText={props.momentMarketingArray[2].name} tooltipText={props.momentMarketingArray[2].description}/>
            </span>
          </div>
          <div style={{display: "flex", justifyContent: "flex-end", width: "94%"}}>
            <Button onClick={() => props.closeTargetOption(false, 8)} style={{width: "10em", marginTop: "1.5em", border: "1px solid lightgray", color: '#ff66c4', marginRight: "10px"}}>Retour</Button>
            <Button onClick={() => props.closeTargetOption(true, 8)} style={{width: "10em", marginTop: "1.5em", border: "1px solid lightgray", color: '#00c2cb'}}>Valider</Button>
          </div>
        </Box>
      </CardContent>
    </React.Fragment>
  );
}

function cardGeolifeOption(props) {
  return(
    <React.Fragment>
      <CardContent>
        <Box component="form" noValidate autoComplete="off" style={{marginTop: "1em"}}>
          <div className="cardOptionWrapper">
            <span className="onelineCardOption">
              <OneLineCard {...props} id={0} isCardPink={true} item='geolife' onChange={props.handleOptionChange} value={props.geolife === 0 ? true : false} cardText={props.geolifeArray[0].name} tooltipText={props.geolifeArray[0].description}/>
            </span>
            <span className="onelineCardOption">
              <OneLineCard {...props} id={1} isCardPink={true} item='geolife' onChange={props.handleOptionChange} value={props.geolife === 1 ? true : false} cardText={props.geolifeArray[1].name} tooltipText={props.geolifeArray[1].description}/>
            </span>
            <span className="onelineCardOption">
              <OneLineCard {...props} id={2} isCardPink={true} item='geolife' onChange={props.handleOptionChange} value={props.geolife === 2 ? true : false} cardText={props.geolifeArray[2].name} tooltipText={props.geolifeArray[2].description}/>
            </span>
            <span className="onelineCardOption">
              <OneLineCard {...props} id={3} isCardPink={true} item='geolife' onChange={props.handleOptionChange} value={props.geolife === 3 ? true : false} cardText={props.geolifeArray[3].name} tooltipText={props.geolifeArray[3].description}/>
            </span>
            <span className="onelineCardOption">
              <OneLineCard {...props} id={4} isCardPink={true} item='geolife' onChange={props.handleOptionChange} value={props.geolife === 4 ? true : false} cardText={props.geolifeArray[4].name} tooltipText={props.geolifeArray[4].description}/>
            </span>
            <span className="onelineCardOption">
              <OneLineCard {...props} id={5} isCardPink={true} item='geolife' onChange={props.handleOptionChange} value={props.geolife === 5 ? true : false} cardText={props.geolifeArray[5].name} tooltipText={props.geolifeArray[5].description}/>
            </span>
            <span className="onelineCardOption">
              <OneLineCard {...props} id={6} isCardPink={true} item='geolife' onChange={props.handleOptionChange} value={props.geolife === 6 ? true : false} cardText={props.geolifeArray[6].name} tooltipText={props.geolifeArray[6].description}/>
            </span>
            <span className="onelineCardOption">
              <OneLineCard {...props} id={7} isCardPink={true} item='geolife' onChange={props.handleOptionChange} value={props.geolife === 7 ? true : false} cardText={props.geolifeArray[7].name} tooltipText={props.geolifeArray[7].description}/>
            </span>
            <span className="onelineCardOption">
              <OneLineCard {...props} id={8} isCardPink={true} item='geolife' onChange={props.handleOptionChange} value={props.geolife === 8 ? true : false} cardText={props.geolifeArray[8].name} tooltipText={props.geolifeArray[8].description}/>
            </span>
            <span className="onelineCardOption">
              <OneLineCard {...props} id={9} isCardPink={true} item='geolife' onChange={props.handleOptionChange} value={props.geolife === 9 ? true : false} cardText={props.geolifeArray[9].name} tooltipText={props.geolifeArray[9].description}/>
            </span>
            <span className="onelineCardOption"/>
            <span className="onelineCardOption"/>
          </div>
          <div style={{display: "flex", justifyContent: "flex-end", width: "94%", flexDirection: "row"}}>
            <Button onClick={() => props.closeTargetOption(false, 6)} style={{width: "10em", marginTop: "1.5em", border: "1px solid lightgray", color: '#ff66c4', marginRight: "10px"}}>Retour</Button>
            <Button onClick={() => props.closeTargetOption(true, 6)} style={{width: "10em", marginTop: "1.5em", border: "1px solid lightgray", color: '#00c2cb'}}>Valider</Button>
          </div>
        </Box>
      </CardContent>
    </React.Fragment>
  );
}

function cardConsoTVOption(props) {
  return(
    <React.Fragment>
      <CardContent>
        <Box component="form" noValidate autoComplete="off" style={{marginTop: "1em"}}>
          <div className="cardOptionWrapper">
            <span className="onelineCardOption">
              <OneLineCard {...props} id={0} isCardPink={true} item='consoTV' onChange={props.handleOptionChange} value={props.consoTV === 0 ? true : false} cardText={props.consoTvArray[0].name} tooltipText={props.consoTvArray[0].description}/>
            </span>
            <span className="onelineCardOption">
              <OneLineCard {...props} id={1} isCardPink={true} item='consoTV' onChange={props.handleOptionChange} value={props.consoTV === 1 ? true : false} cardText={props.consoTvArray[1].name} tooltipText={props.consoTvArray[1].description}/>
            </span>
            <span className="onelineCardOption">
              <OneLineCard {...props} id={2} isCardPink={true} item='consoTV' onChange={props.handleOptionChange} value={props.consoTV === 2 ? true : false} cardText={props.consoTvArray[2].name} tooltipText={props.consoTvArray[2].description}/>
            </span>
            <span className="onelineCardOption">
              <OneLineCard {...props} id={3} isCardPink={true} item='consoTV' onChange={props.handleOptionChange} value={props.consoTV === 3 ? true : false} cardText={props.consoTvArray[3].name} tooltipText={props.consoTvArray[3].description}/>
            </span>
            <span className="onelineCardOption" />
            <span className="onelineCardOption" />
          </div>
          <div style={{display: "flex", justifyContent: "flex-end", width: "94%"}}>
            <Button onClick={() => props.closeTargetOption(false, 7)} style={{width: "10em", marginTop: "1.5em", border: "1px solid lightgray", color: '#ff66c4', marginRight: "10px"}}>Retour</Button>
            <Button onClick={() => props.closeTargetOption(true, 7)} style={{width: "10em", marginTop: "1.5em", border: "1px solid lightgray", color: '#00c2cb'}}>Valider</Button>
            </div>
        </Box>
      </CardContent>
    </React.Fragment>
  );
}

function cardDataOption(props) {
  return(
    <React.Fragment>
      <CardContent>
        <Box component="form" noValidate autoComplete="off" style={{marginTop: "1em"}}>
          <div className="cardOptionWrapper">
            <span className="onelineCardOption">
              <OneLineCard {...props} id={0} isCardPink={true} item='data' onChange={props.handleOptionChange} value={props.data === 0 ? true : false} cardText={props.dataArray[0].name} tooltipText={props.dataArray[0].description}/>
            </span>
            <span className="onelineCardOption">
              <OneLineCard {...props} id={1} isCardPink={true} item='data' onChange={props.handleOptionChange} value={props.data === 1 ? true : false} cardText={props.dataArray[1].name} tooltipText={props.dataArray[1].description}/>
            </span>
            <span className="onelineCardOption">
              <OneLineCard {...props} id={2} isCardPink={true} item='data' onChange={props.handleOptionChange} value={props.data === 2 ? true : false} cardText={props.dataArray[2].name} tooltipText={props.dataArray[2].description}/>
            </span>
            <span className="onelineCardOption">
              <OneLineCard {...props} id={3} isCardPink={true} item='data' onChange={props.handleOptionChange} value={props.data === 3 ? true : false} cardText={props.dataArray[3].name} tooltipText={props.dataArray[3].description}/>
            </span>
            <span className="onelineCardOption" />
            <span className="onelineCardOption" />
          </div>
          <div style={{display: "flex", justifyContent: "flex-end", width: "94%"}}>
            <Button onClick={() => props.closeTargetOption(false, 5)} style={{width: "10em", marginTop: "1.5em", border: "1px solid lightgray", color: '#ff66c4', marginRight: "10px"}}>Retour</Button>
            <Button onClick={() => props.closeTargetOption(true, 5)} style={{width: "10em", marginTop: "1.5em", border: "1px solid lightgray", color: '#00c2cb'}}>Valider</Button>
          </div>
        </Box>
      </CardContent>
    </React.Fragment>
  );
}

function cardInterestOption(props) {
  return(
    <React.Fragment>
      <CardContent>
        <Box component="form" noValidate autoComplete="off" style={{marginTop: "1em"}}>
          <div className="cardOptionWrapper">
            <span className="onelineCardOption">
              <OneLineCard {...props} id={0} isCardPink={true} item='interest' onChange={props.handleOptionChange} value={props.interest === 0 ? true : false} cardText={props.interestArray[0].name} tooltipText={props.interestArray[0].description}/>
            </span>
            <span className="onelineCardOption">
              <OneLineCard {...props} id={1} isCardPink={true} item='interest' onChange={props.handleOptionChange} value={props.interest === 1 ? true : false} cardText={props.interestArray[1].name} tooltipText={props.interestArray[1].description}/>
            </span>
            <span className="onelineCardOption">
              <OneLineCard {...props} id={2} isCardPink={true} item='interest' onChange={props.handleOptionChange} value={props.interest === 2 ? true : false} cardText={props.interestArray[2].name} tooltipText={props.interestArray[2].description}/>
            </span>
            <span className="onelineCardOption">
              <OneLineCard {...props} id={3} isCardPink={true} item='interest' onChange={props.handleOptionChange} value={props.interest === 3 ? true : false} cardText={props.interestArray[3].name} tooltipText={props.interestArray[3].description}/>
            </span>
            <span className="onelineCardOption">
              <OneLineCard {...props} id={4} isCardPink={true} item='interest' onChange={props.handleOptionChange} value={props.interest === 4 ? true : false} cardText={props.interestArray[4].name} tooltipText={props.interestArray[4].description}/>
            </span>
            <span className="onelineCardOption">
              <OneLineCard {...props} id={5} isCardPink={true} item='interest' onChange={props.handleOptionChange} value={props.interest === 5 ? true : false} cardText={props.interestArray[5].name} tooltipText={props.interestArray[5].description}/>
            </span>
            <span className="onelineCardOption">
              <OneLineCard {...props} id={6} isCardPink={true} item='interest' onChange={props.handleOptionChange} value={props.interest === 6 ? true : false} cardText={props.interestArray[6].name} tooltipText={props.interestArray[6].description}/>
            </span>
            <span className="onelineCardOption">
              <OneLineCard {...props} id={7} isCardPink={true} item='interest' onChange={props.handleOptionChange} value={props.interest === 7 ? true : false} cardText={props.interestArray[7].name} tooltipText={props.interestArray[7].description}/>
            </span>
            <span className="onelineCardOption">
              <OneLineCard {...props} id={8} isCardPink={true} item='interest' onChange={props.handleOptionChange} value={props.interest === 8 ? true : false} cardText={props.interestArray[8].name} tooltipText={props.interestArray[8].description}/>
            </span>
            <span className="onelineCardOption">
              <OneLineCard {...props} id={9} isCardPink={true} item='interest' onChange={props.handleOptionChange} value={props.interest === 9 ? true : false} cardText={props.interestArray[9].name} tooltipText={props.interestArray[9].description}/>
            </span>
            <span className="onelineCardOption">
              <OneLineCard {...props} id={10} isCardPink={true} item='interest' onChange={props.handleOptionChange} value={props.interest === 10 ? true : false} cardText={props.interestArray[10].name} tooltipText={props.interestArray[10].description}/>
            </span>
            <span className="onelineCardOption">
              <OneLineCard {...props} id={11} isCardPink={true} item='interest' onChange={props.handleOptionChange} value={props.interest === 11 ? true : false} cardText={props.interestArray[11].name} tooltipText={props.interestArray[11].description}/>
            </span>
          </div>
          <div style={{display: "flex", justifyContent: "flex-end", width: "94%"}}>
            <Button onClick={() => props.closeTargetOption(false, 4)} style={{width: "10em", marginTop: "1.5em", border: "1px solid lightgray", color: '#ff66c4', marginRight: "10px"}}>Retour</Button>
            <Button onClick={() => props.closeTargetOption(true, 4)} style={{width: "10em", marginTop: "1.5em", border: "1px solid lightgray", color: '#00c2cb'}}>Valider</Button>
          </div>
        </Box>
      </CardContent>
    </React.Fragment>
  );
}

function cardHousingTypeOption(props) {
  return(
    <React.Fragment>
      <CardContent>
        <Box component="form" noValidate autoComplete="off" style={{marginTop: "1em"}}>
          <div className="cardOptionWrapper">
            <span className="onelineCardOption">
              <OneLineCard {...props} id={0} isCardPink={true} item='housingType' onChange={props.handleOptionChange} value={props.housingType === 0 ? true : false} cardText={props.housingTypeArray[0].name} tooltipText={props.housingTypeArray[0].description}/>
            </span>
            <span className="onelineCardOption">
              <OneLineCard {...props} id={1} isCardPink={true} item='housingType' onChange={props.handleOptionChange} value={props.housingType === 1 ? true : false} cardText={props.housingTypeArray[1].name} tooltipText={props.housingTypeArray[1].description}/>
            </span>
            <span className="onelineCardOption">
              <OneLineCard {...props} id={2} isCardPink={true} item='housingType' onChange={props.handleOptionChange} value={props.housingType === 2 ? true : false} cardText={props.housingTypeArray[2].name} tooltipText={props.housingTypeArray[2].description}/>
            </span>
            <span className="onelineCardOption">
              <OneLineCard {...props} id={3} isCardPink={true} item='housingType' onChange={props.handleOptionChange} value={props.housingType === 3 ? true : false} cardText={props.housingTypeArray[3].name} tooltipText={props.housingTypeArray[3].description}/>
            </span>
            <span className="onelineCardOption">
              <OneLineCard {...props} id={4} isCardPink={true} item='housingType' onChange={props.handleOptionChange} value={props.housingType === 4 ? true : false} cardText={props.housingTypeArray[4].name} tooltipText={props.housingTypeArray[4].description}/>
            </span>
            <span className="onelineCardOption">
              <OneLineCard {...props} id={5} isCardPink={true} item='housingType' onChange={props.handleOptionChange} value={props.housingType === 5 ? true : false} cardText={props.housingTypeArray[5].name} tooltipText={props.housingTypeArray[5].description}/>
            </span>
            <span className="onelineCardOption">
              <OneLineCard {...props} id={6} isCardPink={true} item='housingType' onChange={props.handleOptionChange} value={props.housingType === 6 ? true : false} cardText={props.housingTypeArray[6].name} tooltipText={props.housingTypeArray[6].description}/>
            </span>
            <span className="onelineCardOption">
              <OneLineCard {...props} id={7} isCardPink={true} item='housingType' onChange={props.handleOptionChange} value={props.housingType === 7 ? true : false} cardText={props.housingTypeArray[7].name} tooltipText={props.housingTypeArray[7].description}/>
            </span>
            <span className="onelineCardOption">
            </span>
          </div>
          <div style={{display: "flex", justifyContent: "flex-end", width: "94%"}}>
            <Button onClick={() => props.closeTargetOption(false, 3)} style={{width: "10em", marginTop: "1.5em", border: "1px solid lightgray", color: '#ff66c4', marginRight: "10px"}}>Retour</Button>
            <Button onClick={() => props.closeTargetOption(true, 3)} style={{width: "10em", marginTop: "1.5em", border: "1px solid lightgray", color: '#00c2cb'}}>Valider</Button>
          </div>
        </Box>
      </CardContent>
    </React.Fragment>
  );
}

function cardCSPOption(props) {
  return(
    <React.Fragment>
      <CardContent>
        <Box component="form" noValidate autoComplete="off" style={{marginTop: "1em"}}>
          <div className="cardOptionWrapper">
            <span className="onelineCardOption">
              <OneLineCard {...props} id={0} isCardPink={true} item='csp' onChange={props.handleOptionChange} value={props.csp0} cardText="CSP++" tooltipText="Foyer dont la personne référente est Artisan, commercant, chef d'entreprise, cadre ou de profession intellectuelle supérieure"/>
            </span>
            <span className="onelineCardOption">
              <OneLineCard {...props} id={1} isCardPink={true} item='csp' onChange={props.handleOptionChange} value={props.csp1} cardText="CSP+" tooltipText="Foyer dont la personne référente est Artisan, commercant, chef d'entreprise, cadre ou de profession intermédiaire"/>
            </span>
            <span className="onelineCardOption">
              <OneLineCard {...props} id={2} isCardPink={true} item='csp' onChange={props.handleOptionChange} value={props.csp2} cardText="CSP-" tooltipText="Foyer dont la personne référente est Agriculteur exploitant, employé ou ouvrier"/>
            </span>
            <span className="onelineCardOption">
              <OneLineCard {...props} id={3} isCardPink={true} item='csp' onChange={props.handleOptionChange} value={props.csp3} cardText="Haut Revenus" tooltipText="Individus dont le foyer fiscal est considéré comme ayant des gros revenus"/>
            </span>
          </div>
          <div style={{display: "flex", justifyContent: "flex-end", width: "94%"}}>
            <Button onClick={() => props.closeTargetOption(false, 2)} style={{width: "10em", marginTop: "1.5em", border: "1px solid lightgray", color: '#ff66c4', marginRight: "10px"}}>Retour</Button>
            <Button onClick={() => props.closeTargetOption(true, 2)} style={{width: "10em", marginTop: "1.5em", border: "1px solid lightgray", color: '#00c2cb'}}>Valider</Button>
          </div>
        </Box>
      </CardContent>
    </React.Fragment>
  );
}


function Step1(props) {
  useEffect(() => {
    if (props.targetValue0 || props.targetValue1 || props.targetValue2 || props.targetValue3 || props.targetValue4 || props.targetValue5 || props.targetValue6 || props.targetValue7 || props.targetValue8) {
      document.getElementById("options").scrollIntoView({ behavior: 'smooth'});
    }
    if (frenchAreaScrolling(props)) {
      document.getElementById("targetContent").scrollIntoView({ behavior: 'smooth'});
    }
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div>
      <Box sx={{ minWidth: 275 }} style={{marginBottom: "1em"}}>
        <Card id="step1Area" {...props} variant="outlined">{cardArea(props)}</Card>
      </Box>
      <Box sx={{ minWidth: 275 }} style={{marginBottom: "1em"}}>
        <Card id="step1Target" {...props} variant="outlined">{cardTarget(props)}</Card>
      </Box>
      <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
        <Button variant='white' onClick={() => props.goPrevStep()}>Étape précédente</Button>
        <Button variant='white' onClick={() => props.goNextStep()}>Étape suivante</Button>
      </div>
    </div>
  );
}

export default Step1;
