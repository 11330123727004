import React from 'react';
import Logo from "../assets/videoclub.png";
import TypeWriter from '../components/typewriter.js';
import LoginRegisterAlerts from '../components/alerts/loginRegisterAlerts.js';
import Textfield from '../components/forgotPasswordTextfield.js';
import ForgotPasswordButton from '../components/buttons/forgotPasswordButton.js';
import * as Api from '../services/userApi.js';
import {withRouter} from '../services/withRouter.js';
import "../css/app.css";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      email: "",
      greetingMsg: "MOT DE PASSE OUBLIÉ ?",
      isAlertVisible: false,
      alertSeverity: "",
      alertText: "",
      alertColor: ""
    };
  }

  componentDidMount() {
  }

  //general function used to update state
  updateState = (stateName, value) => {
    this.setState({[stateName]: value});
  }

  //function called when login / register button are clicked
  buttonClicked = async () => {
    if (this.state.email === "") {
      this.setState({alertText: "Merci de renseigner votre adresse email"});
      this.setState({alertColor: "error"});
      this.setState({alertSeverity: "error"});
      this.updateAlertState();
    }
    else {
      const data = {
        userId: this.state.email
      }
      const ret = await Api.resetPassword(data);
      if (ret === true) {
        this.setState({alertText: "Un email vous a été envoyé pour réinitialiser votre compte"});
        this.setState({alertColor: "error"});
        this.setState({alertSeverity: "error"});
        this.setState({alertColor: "info"});
        this.setState({alertSeverity: "success"});
        this.setState({alertMultiple: true});
        this.updateAlertState();
      }
      else {
        this.setState({alertText: "Une erreur s'est produite."});
        this.setState({alertColor: "error"});
        this.setState({alertSeverity: "error"});
        this.setState({alertMultiple: false});
        this.updateAlertState();
      }
    }
  }

  closeAndQuit = () => {
    this.props.navigate("/connexion");
  }

  goBack = () => {
    this.props.navigate("/connexion");
  }

  updateWelcomeText = () => {

  }

  returnFunction = () => {

  }

  //function used to display / hide alerts
  updateAlertState = () => {
    this.setState({isAlertVisible: !this.state.isAlertVisible});
  }

  render() {
    return(
      <div className="contentWrapper animatedBackground">
        <div className="registerContentWrapper">
          <div className="registerCard">
            <div className="registerCardLeft">
              <div className="registerLogoCenterDiv">
                <div className="registerCenterWrapper">
                  <img src={Logo} alt="" className="registerLogo"/>
                </div>
              </div>
            </div>
            <div className="registerCardRight">
            <LoginRegisterAlerts {...this.state} onClose={this.updateAlertState} closeAndQuit={this.closeAndQuit}/>
              <div className="typewriterDiv" style={{textAlign: "center"}}>
                <TypeWriter
                  ref={this.child}
                  updateWelcomeText={this.updateWelcomeText}
                  content={this.state.greetingMsg}
                  returnFunction={this.returnFunction}
                />
              </div>
              <div style={{textAlign: "center", width: "80%", marginLeft: "auto", marginRight: "auto"}}>
                <p style={{width: "100%"}}>
                  Veuillez saisir l'adresse email que vous avez utilisée pour vous inscrire. Vous recevrez un lien temporaire pour réinitialiser votre mot de passe.
                </p>
                <div style={{width: "100%", display: "flex", flexDirection: "row", marginTop: "2em", cursor: "pointer"}} onClick={this.goBack}>
                  <i style={{marginTop: "auto", marginBottom: "auto", marginLeft: "0px", marginRight: "8px"}} id="returnToLoginArrow" className="fa-solid fa-arrow-left-long"></i>
                  <span style={{marginTop: "auto", marginBottom: "auto", fontWeight: "bold"}}>Retour</span>
                </div>
                <Textfield id="" textFieldType="text" placeholder="Email" stateName="email" textfieldValue={this.state.email} updateState={this.updateState}/>
                <ForgotPasswordButton onClick={this.buttonClicked} submitButtonText="Envoyer"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ForgotPassword);
