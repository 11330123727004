import TextField from '@mui/material/TextField';

function UpdateProfileTextfield(props) {

  return (
    <div id={props.id} className="updateProfileTextfieldDiv" style={{marginLeft: "1px", marginRight: "1px", position: "relative"}}>
      <TextField
        className="registerTextfield"
        type={props.textFieldType}
        label={props.placeholder}
        variant="outlined"
        value={props.textfieldValue}
        onChange={(e) => {props.updateState(props.stateName, e.target.value)}}
      />
    </div>
  );
}

export default UpdateProfileTextfield;
