import React, { useEffect  } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

function propositionCard(props) {
  return(
    <React.Fragment>
      <CardContent>
        <span className="cardText">
          Votre proposition de publicité TV
        </span>
        <Box component="form" noValidate autoComplete="off">
          <div style={{marginTop: "2em"}}>
            <p>Notre recommandation se porte vers une campagne <span className="bold">100% ciblée</span>.</p>
            <p>Ainsi vous ne touchez que l&#39;audience qui vous intéresse et <span className="bold">maximisez l'efficacité </span> de chaque contact.</p>
            <Button onClick={() => props.openModal(1)} style={{marginTop: '1em', width: "10em", border: "1px solid lightgray", color: '#00c2cb'}}>En savoir plus </Button>
          </div>
          <div style={{marginLeft: "auto", marginRight: "auto", marginTop: "1em", display: "flex", justifyContent: "center"}}>
            {
              props.validateCmdButtonDisabled === true ? <Button disabled id="disabledValidateButton" onClick={() => props.openModal(2)} style={{marginTop: '1em', minWidth: "25em", height: "4em", border: "1px solid lightgray"}}><span style={{fontSize: "0.9em"}}>A un click de votre plan média !</span></Button> : <Button className="pinkButton" id="validateButton" onClick={() => props.openModal(2)} style={{marginTop: '1em', minWidth: "25em", height: "4em"}}><span style={{fontSize: "0.9em"}}>A un click de votre plan média !</span></Button>
            }
          </div>
        </Box>
      </CardContent>
    </React.Fragment>
  );
}

function Step4(props) {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
  });

  return (
    <div>
      <Box sx={{ minWidth: 275 }} style={{marginBottom: "1em"}}>
        <Card {...props} variant="outlined">{propositionCard(props)}</Card>
      </Box>
      <div style={props.isCmdValidated === 1 ? {display: "none"} : {display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
        <Button variant="white" onClick={() => props.goPrevStep()}>Étape précédente</Button>
      </div>
    </div>
  );
}

export default Step4;
